import { ReactNode, useCallback, useRef } from 'react';

import styled from '@emotion/styled';
import { AppBar, Box, Button, Drawer, IconButton, Toolbar, Typography } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { FormikHelpers, FormikProps } from 'formik';

import { COMPONENT_DSL_NAMES, FORM_COMPONENT_DSL_NAMES } from '@builder/schemas';
import { isFunction } from '@builder/utils';

import { argTypes } from 'src/dialogs/GlobalDialogs/CodeEditorDialog/CodeEditorDialog';
import { FunctionDefinitionContainer } from 'src/features/functions-manager/components/sections/FunctionDefinitionContainer';
import { useValidateJsCode } from 'src/features/functions-manager/util';
import { Form, FormRenderProps, FormProps } from 'src/shared/components';
import {
  TOOLTIP_CALLBACK_ONCOMPLETED,
  TOOLTIP_CALLBACK_ONERROR,
  TOOLTIP_TRANSFORMER,
} from 'src/shared/constants/toolTipsDescription';
import { useSelectedNode } from 'src/shared/hooks';

export type EditorFormDialogProps<
  FormValues extends Record<string, unknown>,
  InitialFormValues = Partial<FormValues>
> = {
  isDialogOpened: boolean;
  onDialogClose: () => void;
  title: string;
  formProps: FormProps<FormValues, InitialFormValues>;
  children: ((props: FormRenderProps<FormValues>) => ReactNode) | ReactNode;
  args?: argTypes;
  argFromLooper?: string;
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledAppBar = styled(AppBar)`
  position: relative;
  z-index: 0;
`;

const Title = styled.div`
  flex: 1;
`;

const Wrapper = styled.div`
  padding-top: 10px;
  padding-left: 20px;
  height: calc(100% - 70px);
  background: #323c47;
`;

const EditorFormDialogContent = styled.div`
  height: calc(100% - 0px);
  width: 100%;
  max-width: 100%;
  padding: ${({ theme }) => theme.spacing(4, 2)};
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const TooltipContainer = styled.div`
  position: relative;
  width: fit-content;
`;

export const YellowContainer = styled.div`
  color: yellow;
`;
export const PurpleContainer = styled.div`
  color: purple;
`;

const editorRunPrefix = ['Custom Code', 'Action (Code)'];
export const EditorFormDialog = <
  FormValues extends Record<string, unknown>,
  InitialFormValues = Partial<FormValues>
>({
  formProps,
  isDialogOpened,
  onDialogClose,
  title,
  children,
  args,
  argFromLooper,
}: EditorFormDialogProps<FormValues, InitialFormValues>): JSX.Element => {
  const { validate } = useValidateJsCode();
  const selectedNodeDSL = useSelectedNode();
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const parameter = args?.context || [];
  const action = args?.action || '';
  const isAutocompleteNode =
    selectedNodeDSL?.name === COMPONENT_DSL_NAMES.BuilderComponentsAutocomplete;

  const isFormAutocompleteNode =
    selectedNodeDSL?.name === FORM_COMPONENT_DSL_NAMES.BuilderComponentsFormAutocomplete;

  const isRichtextEditorNode =
    selectedNodeDSL?.name === COMPONENT_DSL_NAMES.BuilderComponentsRichtextEditor;

  let toolTipDescription = `Action for ${action} event`;
  if (action === 'transformer') {
    toolTipDescription = TOOLTIP_TRANSFORMER;
  }

  if (action === 'callback' && parameter[0] === 'data') {
    toolTipDescription = TOOLTIP_CALLBACK_ONCOMPLETED;
  }

  if (action === 'callback' && parameter[0] === 'error') {
    toolTipDescription = TOOLTIP_CALLBACK_ONERROR;
  }

  const handleSubmit = (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
    if (title !== 'Custom Code') formProps?.onSubmit(values, formikHelpers);
    else if (
      validate(values?.code as string, [...(args?.context || []), argFromLooper] as [string])
    )
      formProps?.onSubmit(values, formikHelpers);
  };

  const onDrawerClose = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, []);

  const parseArg = useCallback(() => {
    if (isAutocompleteNode || isFormAutocompleteNode)
      return ['event', 'value', 'reason', 'details'];

    if (isRichtextEditorNode) return ['event', 'editor', 'error'];

    return [...parameter];
  }, [isAutocompleteNode, isFormAutocompleteNode, isRichtextEditorNode, parameter]);

  const parseParameter = useCallback(() => {
    if (isAutocompleteNode || isFormAutocompleteNode) return ' event, value, reason, details ';

    if (isRichtextEditorNode) return ' event, editor, error ';

    return parameter[0];
  }, [isAutocompleteNode, isFormAutocompleteNode, isRichtextEditorNode, parameter]);

  return (
    <Drawer
      onClick={e => e.stopPropagation()}
      anchor="top"
      open={isDialogOpened}
      onClose={onDrawerClose}
      PaperProps={{
        sx: {
          width: '85%',
          height: 'calc(100% - 1px)',
          margin: '0 auto',
        },
      }}
    >
      <Form {...formProps} innerRef={formRef} onSubmit={handleSubmit}>
        {(formRenderProps: FormRenderProps<FormValues>) => (
          <StyledForm onSubmit={formRenderProps.handleSubmit}>
            <StyledAppBar color="default">
              <Toolbar>
                <Title>
                  <Typography variant="h6">{title}</Typography>
                </Title>
                <Button
                  autoFocus
                  type="submit"
                  variant="contained"
                  color="success"
                  disabled={!formRenderProps.isValid}
                  data-test="editFormDialog.saveBtn"
                >
                  Done
                </Button>
                <Box marginLeft={1}>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={onDialogClose}
                    aria-label="close"
                    size="large"
                  >
                    <GridCloseIcon />
                  </IconButton>
                </Box>
              </Toolbar>
            </StyledAppBar>
            <Wrapper style={{ width: '100%' }}>
              <FunctionDefinitionContainer
                functionName=""
                showContext={editorRunPrefix.includes(title)}
                isAsyncFunction={false}
                args={parseArg()}
                isContext
                context={{
                  parameter: parseParameter(),
                  action,
                  tooltipInfo: toolTipDescription,
                }}
              >
                <EditorFormDialogContent>
                  {isFunction(children) ? children(formRenderProps) : children}
                </EditorFormDialogContent>
              </FunctionDefinitionContainer>
            </Wrapper>
          </StyledForm>
        )}
      </Form>
    </Drawer>
  );
};
