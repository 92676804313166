import { DashboardState, BaseDashboardState } from './types';

export const getBaseDashboardState = (dashboardState: DashboardState): BaseDashboardState => ({
  loadingConfiguration: dashboardState.loadingConfiguration,
  appConfiguration: dashboardState.appConfiguration,
  components: dashboardState.components,
  copyBuffer: dashboardState.copyBuffer,
  copyStyle: dashboardState.copyStyle,
  operations: dashboardState.operations,
  router: dashboardState.router,
  themeManager: dashboardState.themeManager,
  navigator: dashboardState.navigator,
  layout: dashboardState.layout,
  iFrameRefreshed: dashboardState.iFrameRefreshed,
  openAsRootNode: dashboardState.openAsRootNode,
});
