import * as MaterialIcons from '@mui/icons-material';
import { ROOT_CONTENT } from '../providers-generators';
function removeDuplicates(arr) {
    return [...new Set(arr)];
}
function verifyIconsUsage(appDSL) {
    var _a, _b;
    // TODO: Remove this after the Floating Action Button component is fixed
    // TODO: It must include the icon as a children not as a prop
    const iconsInFloatingActionButtons = (_a = Object === null || Object === void 0 ? void 0 : Object.values(appDSL === null || appDSL === void 0 ? void 0 : appDSL.nodes)) === null || _a === void 0 ? void 0 : _a.filter(node => (node === null || node === void 0 ? void 0 : node.name) === 'BuilderComponentsFloatingActionButton').map(node => { var _a; return (_a = node === null || node === void 0 ? void 0 : node.props) === null || _a === void 0 ? void 0 : _a.icon; });
    let iconsInDSL = (_b = Object.values(appDSL.nodes)
        .filter(node => node.name === 'BuilderComponentsIcon')
        .map(node => node.props.name)) === null || _b === void 0 ? void 0 : _b.concat(iconsInFloatingActionButtons || []);
    iconsInDSL = removeDuplicates(iconsInDSL);
    const dslHasIcons = iconsInDSL.length > 0;
    const includeWholePackage = iconsInDSL.some(icon => (icon.includes('{{') && icon.includes('}}')) || !Object.keys(MaterialIcons).includes(icon));
    return { dslHasIcons, includeWholePackage, iconsInDSL };
}
export const isRootContent = (fileName) => {
    return fileName === ROOT_CONTENT;
};
export const generateRichTextCustomComponent = () => `
import { forwardRef, Ref } from 'react';

import { SerializedStyles } from '@emotion/react';

import { ComponentBasicProps, ComponentDataProps } from '../types';

type RichTextProps = ComponentDataProps &
  ComponentBasicProps & {
    style?: Record<string, string>;
    children?: React.ReactNode;
    css?: SerializedStyles;
  };

export const RichText = forwardRef(
  (
    { style = {}, id, title, className, lang, translate, children, ...rest }: RichTextProps,
    ref: Ref<HTMLDivElement>,
  ) => {

    return (
      <div
        ref={ref}
        {...rest}
        style={{ ...style, overflow: 'auto' }}
        id={id}
        title={title}
        className={className}
        lang={lang}
        translate={translate as 'yes' | 'no' | undefined}
      >
        {children}
      </div>
    );
  },
);
`;
export const generateSliderCustomComponent = () => `
import React, {
  CSSProperties,
  ChangeEvent,
  forwardRef,
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';

import { SerializedStyles } from '@emotion/react';
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  SliderProps as MaterialSliderProps,
  FormControlLabel,
  FormControlLabelProps,
  Slider as MaterialSlider,
} from '@mui/material';
import debounce from 'lodash.debounce';
import { isNil } from 'ramda';

import { fieldValidation } from '../common';
import { ComponentDataProps } from '../types';

export type SliderProps = Omit<MaterialSliderProps, 'ref' | 'error'> &
  ComponentDataProps &
  Pick<FormControlLabelProps, 'label' | 'labelPlacement'> &
  Pick<FormControlProps, 'variant' | 'size'> & {
    error?: boolean;
    helperText?: React.ReactNode;
    style?: CSSProperties;
    css?: SerializedStyles;
  } & {
    validation?: string;
    regularExpression?: RegExp;
    debounce?: number;
    arialabel: string;
    arialabelledby: string;
    ariavaluetext: string;
    classes: Record<string, unknown>;
    track: string;
    valueLabelDisplay: string;
    defaultValue: string;
    marks: [];
    step: string;
    orientation: string;
    sx: string;
    disableSwap: boolean;
    getAriaLabel: string;
    getAriaValueText: string;
    scale: number;
    tabIndex: string;
    valueLabelFormat: string;
  };

// why should we use such a weird styles there?
// by default Material-UI has negative marginLeft value and this component falls out its parent
// on the left side if it has the prop "size" equals to "small"
// so to avoid such weird behaviour we set marginLeft equals to "-6px"
const SMALL_SIZE_STYLES = {
  marginLeft: '-6px',
};

function createObjectFromCssString(stringValue: string) {
  const separator = stringValue?.includes(';') ? ';' : ',';
  let finalObject = {};
  if (stringValue)
    stringValue
      .slice(1, -1)
      .toString()
      .split(separator)
      // eslint-disable-next-line array-callback-return
      .map((stringElement: string) => {
        const key = stringElement.split(':')[0]?.replace(/\\s+/g, '');
        const sxValue = stringElement.split(':')[1]?.replace(/'|\\s+/g, '');
        finalObject = {
          ...finalObject,
          [key]: sxValue,
        };
      });

  return finalObject;
}

function preventHorizontalKeyboardNavigation(event: React.KeyboardEvent) {
  if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
    event.preventDefault();
  }
}

export const Slider = forwardRef<HTMLDivElement, SliderProps>(
  (
    {
      debounce: debounceTime,
      id,
      title,
      translate,
      disabled,
      className,
      style,
      sx,
      css,
      error,
      helperText,
      size,
      name,
      lang,
      color,
      value,
      defaultValue,
      min,
      max,
      arialabel,
      arialabelledby,
      ariavaluetext,
      classes,
      valueLabelDisplay,
      disableSwap,
      track,
      marks,
      step,
      orientation,
      getAriaLabel,
      getAriaValueText,
      tabIndex,
      onChange,
      valueLabelFormat,
      scale,
      validation,
      regularExpression,
      label: initLabel = '',
      labelPlacement = 'end',
      'data-test': dataTest,
      'data-node-id': dataNodeID,
      'data-node-render-path': dataRenderPath,
      ...otherSliderProps
    },
    ref,
  ) => {
    const label = Array.isArray(initLabel) ? initLabel[0] : initLabel;
    const [localValue, setLocalValue] = useState<number | number[] | undefined>(value);
    const [isFirstLoad, setIsFirstLoad] = useState(false);
    const [errorType, setErrorType] = useState(false);

    useEffect(() => {
      if (defaultValue && !isFirstLoad) {
        setIsFirstLoad(true);
        onChange?.(
          ({
            target: {
              value: Number(defaultValue),
            },
          } as unknown) as Event,
          defaultValue,
          0,
        );
      }
    }, [setIsFirstLoad, onChange, defaultValue, isFirstLoad]);

    useEffect(() => {
      setLocalValue(value);
    }, [value, setLocalValue]);

    const createDebounceCallback = useCallback(
      (debounceTimeArg?: number) => {
        return debounce(
          (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, nextValue: string) => {
            // eslint-disable-next-line no-param-reassign
            event.target.value = nextValue;
            onChange?.(
              (event as unknown) as Event,
              (event.target.value as unknown) as number,
              (event.target.value as unknown) as number,
            );
          },
          debounceTimeArg,
        );
      },
      [onChange],
    );

    const debouncedChangePropValue = useRef(createDebounceCallback(debounceTime));

    const setDebouncedValue = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const sliderValue = (event.target.value as unknown) as number;
      setLocalValue(sliderValue);

      if (isNil(debounceTime)) {
        onChange?.((event as unknown) as Event, sliderValue, sliderValue);
      } else {
        debouncedChangePropValue.current(event, event.target.value);
      }
    };

    const handleValidation = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const validate = fieldValidation(event.target.value, validation, regularExpression);
      setErrorType(!!validate);
    };

    const sxObject = createObjectFromCssString(sx);
    // eslint-disable-next-line no-new-func
    const formatLabel = new Function('value', valueLabelFormat);
    return (
      <FormControl
        size={size}
        sx={{ width: 200, marginLeft: '20px' }}
        disabled={disabled}
        className={className}
        style={style}
        css={css}
        ref={ref}
        data-test={dataTest}
        data-node-id={dataNodeID}
        data-node-render-path={dataRenderPath}
        title={title}
        id={id}
        lang={lang}
        translate={translate}
      >
        <FormControlLabel
          disabled={disabled}
          label={label}
          labelPlacement={labelPlacement}
          disableTypography
          sx={size === 'small' ? SMALL_SIZE_STYLES : undefined}
          control={
            <MaterialSlider
              {...otherSliderProps}
              size={size}
              value={localValue}
              min={min}
              max={max}
              onChange={event => {
                handleValidation(
                  (event as unknown) as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                );
                setDebouncedValue(
                  (event as unknown) as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                );
              }}
              scale={actualValue => actualValue * Number(scale || 1)}
              arial-label={arialabel}
              aria-labelledby={arialabelledby}
              aria-valuetext={ariavaluetext}
              color={color}
              marks={marks}
              classes={classes}
              valueLabelDisplay={valueLabelDisplay}
              track={track !== 'normal' && track !== 'inverted' ? false : track}
              step={parseInt(step, 10)}
              tabIndex={parseInt(tabIndex, 10) || 0}
              name={name}
              sx={{
                ...sxObject,
                '& input[type="range"]': {
                  WebkitAppearance: 'slider-vertical',
                },
              }}
              disableSwap={disableSwap}
              orientation={orientation}
              getAriaLabel={() => getAriaLabel}
                valueLabelFormat={
                valueLabelFormat
                  ? valueToFormat => formatLabel(valueToFormat)
                  : valueToFormat => \`\${valueToFormat}\`
              }
              getAriaValueText={labelvalue =>
                getAriaValueText ? \`\${labelvalue}\${getAriaValueText}\` : ariavaluetext || ''
              }
              onKeyDown={preventHorizontalKeyboardNavigation}
            />
          }
        />
        {helperText && <FormHelperText error={errorType || error}>{helperText}</FormHelperText>}
      </FormControl>
    );
  },
);
`;
export const generateCustomIconComponent = (appDSL) => {
    const { dslHasIcons, includeWholePackage, iconsInDSL } = verifyIconsUsage(appDSL);
    return `
  import React, { CSSProperties, forwardRef, Ref } from 'react';

  import { SerializedStyles } from '@emotion/react';

  ${
    // eslint-disable-next-line no-nested-ternary
    dslHasIcons && !includeWholePackage
        ? `
  import {${iconsInDSL.join(',')}} from '@mui/icons-material';
  `
        : includeWholePackage
            ? `
  import * as MaterialIcons from '@mui/icons-material';
      `
            : ``}

  ${
    // eslint-disable-next-line no-nested-ternary
    dslHasIcons && !includeWholePackage
        ? `
  const ICONS = {${iconsInDSL.join(',')}};`
        : includeWholePackage
            ? `const ICONS = MaterialIcons;`
            : `const ICONS = {};`}
  
  
  import { ComponentBasicProps, ComponentDataProps } from '../types';
  
  type MaterialIconNames = any;
  
  type IconProps = ComponentDataProps &
    ComponentBasicProps & {
      customIcon?: string;
      name?: MaterialIconNames | string;
      variant?: 'filled' | 'outlined' | 'rounded' | 'twoTone' | 'sharp';
      color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error';
      fontSize?: 'inherit' | 'small' | 'medium' | 'large';
      htmlColor?: string;
      viewBox?: string;
      style?: CSSProperties;
      css?: SerializedStyles;
      classes?: Record<string, unknown>;
      className?: string;
    };
  
  const DEFAULT_SIZE = 20;
  
  export const Icon = forwardRef(
    (
      {
        name,
        customIcon,
        variant = 'filled',
        style,
        color,
        fontSize,
        'data-test': dataTest,
        'data-node-id': dataNodeID,
        'data-node-render-path': dataRenderPath,
        ...rest
      }: IconProps,
      ref: Ref<HTMLSpanElement>,
    ) => {
      if (customIcon) {
        return (
          <span
            ref={ref}
            data-test={dataTest}
            data-node-id={dataNodeID}
            data-node-render-path={dataRenderPath}
            style={style}
            {...rest}
          >
            <svg width={style?.width ?? DEFAULT_SIZE} height={style?.height ?? DEFAULT_SIZE}>
              <image
                href={customIcon}
                width={style?.width ?? DEFAULT_SIZE}
                height={style?.height ?? DEFAULT_SIZE}
              />
            </svg>
          </span>
        );
      }
  
      const IconComponent = ICONS[name]
  
      return (
        <IconComponent
          // SvgIconComponent isn't aware of its forwardRef nature for some reason
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={ref}
          data-test={dataTest}
          data-node-id={dataNodeID}
          data-node-render-path={dataRenderPath}
          fontSize={fontSize}
          color={color}
          style={style}
          {...rest}
        />
      );
    },
  );
  

  `;
};
export const generateCustomRatingComponent = (appDSL) => {
    const { dslHasIcons, includeWholePackage, iconsInDSL } = verifyIconsUsage(appDSL);
    return `
  /* eslint-disable @typescript-eslint/no-explicit-any */
import React, { CSSProperties, forwardRef, useState, Ref, SyntheticEvent, useEffect } from 'react';

import { SerializedStyles } from '@emotion/react';

${
    // eslint-disable-next-line no-nested-ternary
    dslHasIcons && !includeWholePackage
        ? `
import {${iconsInDSL.join(',')}} from '@mui/icons-material';
`
        : includeWholePackage
            ? `
import * as MaterialIcons from '@mui/icons-material';
    `
            : ``}

${
    // eslint-disable-next-line no-nested-ternary
    dslHasIcons && !includeWholePackage
        ? `
const ICONS = {${iconsInDSL.join(',')}};`
        : includeWholePackage
            ? `const ICONS = MaterialIcons;`
            : `const ICONS = {};`}

import { Rating as MuiRating, RatingProps as MuiRatingProps } from '@mui/material';

import { ComponentBasicProps, ComponentDataProps } from '../types';

type MaterialIconNames = any;

export type RatingProps = Omit<MuiRatingProps, 'ref' | 'error'> &
  ComponentDataProps &
  ComponentBasicProps & {
    error?: boolean;
    helperText?: React.ReactNode;
    style?: CSSProperties;
    css?: SerializedStyles;
  };

const buildCustomIcon = (customIcon: any | null | undefined) => {
  if (customIcon) {
    const { name, variant, color, style } = customIcon.props;
    const MaterialIcon = ICONS[name];
    return (
      <MaterialIcon fontSize="inherit" name={name} color={color} style={{ fill: style.fill }} />
    );
  }
};

export const Rating = forwardRef(
  (
    {
      onChange,
      onChangeActive,
      value,
      defaultValue = 0,
      precision,
      readOnly,
      getLabelText,
      icon: initlIcon,
      emptyIcon: initEmptyIcon,
      disabled,
      emptyLabelText,
      highlightSelectedOnly,
      max,
      name,
      'data-test': dataTest,
      'data-node-id': dataNodeID,
      'data-node-render-path': dataRenderPath,
      size,
      style,
      css,
      id,
      title,
      translate,
      lang,
      ...otherRatingProps
    }: RatingProps,
    ref: Ref<HTMLSpanElement>,
  ) => {
    const [localValue, setLocalValue] = useState<number | null | undefined>(value || 0);
    const [isFirstLoad, setIsFirstLoad] = useState(false);
    const customIcon = buildCustomIcon(Array.isArray(initlIcon) ? initlIcon[0] : initlIcon);
    const emptyCustomIcon = buildCustomIcon(
      Array.isArray(initEmptyIcon) ? initEmptyIcon[0] : initEmptyIcon,
    );

    useEffect(() => {
      if (defaultValue !== undefined && !isFirstLoad) {
        setIsFirstLoad(true);
        onChange?.(
          ({
            target: {
              value: Number(defaultValue),
            },
          } as unknown) as SyntheticEvent<Element, Event>,
          defaultValue,
        );
      }
    }, [defaultValue, isFirstLoad, onChange]);

    useEffect(() => {
      setLocalValue(value || 0);
    }, [value]);

    return (
      <div
        data-test={dataTest}
        data-node-id={dataNodeID}
        data-node-render-path={dataRenderPath}
        style={style}
        css={css}
        id={id}
        title={title}
        lang={lang}
        translate={translate}
      >
        <MuiRating
          {...otherRatingProps}
          ref={ref}
          name={name}
          value={localValue}
          defaultValue={defaultValue}
          emptyLabelText={emptyLabelText}
          getLabelText={getLabelText}
          onChange={(event, currentValue) => {
            onChange?.(event, currentValue || 0);
          }}
          onChangeActive={onChangeActive}
          precision={precision}
          readOnly={readOnly}
          icon={customIcon?.props?.name ? customIcon : undefined}
          emptyIcon={emptyCustomIcon?.props?.name ? emptyCustomIcon : undefined}
          disabled={disabled}
          max={max}
          highlightSelectedOnly={highlightSelectedOnly}
          size={size}
        />
      </div>
    );
  },
);
  `;
};
export const generateCustomRichtextEditorComponent = (appDSL) => {
    const isDSLUsingRichTextEditor = Object.values(appDSL.nodes).some(node => node.name === 'BuilderComponentsRichtextEditor');
    return isDSLUsingRichTextEditor
        ? // eslint-disable-next-line import/no-webpack-loader-syntax, @typescript-eslint/no-var-requires, global-require
            require('!!raw-loader!@builder/components/src/RichtextEditor/RichtextEditor.tsx').default
        : `import React from 'react'

  export const RichtextEditor = () => {
    return (
      <div></div>
    )
  }
  `;
};
