import React from 'react';

import { reactMemo } from '@builder/utils';

import { useAllDialogsState } from 'src/providers/DialogProvider';

import { AssetDeleteDialog } from './AssetDeleteDialog';
import { BrokenLoadedDSLDialog } from './BrokenLoadedDSLDialog';
import { CheckDSLVersionDialog } from './CheckDSLVersionDialog';
import { CodeEditorDialog } from './CodeEditorDialog';
import { CSSEditorDialog } from './CSSEditorDialog';
import { DeployProgressDialog } from './DeployProgressDialog';
import { DASHBOARD_DIALOGS } from './dialogsMap';
import { LostConnectionDialog } from './LostConnectionDialog';
import { MigrationResultDialog } from './MigrationResultDialog';
import { NoResourcesWarningDialog } from './NoResourcesWarningDialog';
import { NotAuthorizedDialog } from './NotAuthorizedDialog';
import { PreventBreakingDSLDialog } from './PreventBreakingDSLDialog';
import { PreventDeleteResourceDialog } from './PreventDeleteResourceDialog';
import { PreventDeleteSymbolDialog } from './PreventDeleteSymbolDialog';
import { RefactorAsFunctionDialog } from './RefactorAsFunctionDialog';
import { UnsavedChangesDialog } from './UnsavedChangesDialog';

type DialogSetting = {
  component: React.FC;
  renderAlways?: boolean;
};

const DIALOGS_MAP: Record<string, DialogSetting> = {
  [DASHBOARD_DIALOGS.ASSET_DELETE_DIALOG_ID]: {
    component: AssetDeleteDialog,
  },
  [DASHBOARD_DIALOGS.CODE_EDITOR_DIALOG_ID]: {
    component: CodeEditorDialog,
    renderAlways: true,
  },
  [DASHBOARD_DIALOGS.CSS_EDITOR_DIALOG_ID]: {
    component: CSSEditorDialog,
    renderAlways: true,
  },
  [DASHBOARD_DIALOGS.CHECK_DSL_VERSION_DIALOG_ID]: {
    component: CheckDSLVersionDialog,
  },
  [DASHBOARD_DIALOGS.NO_RESOURCES_WARNING_DIALOG_ID]: {
    component: NoResourcesWarningDialog,
  },
  [DASHBOARD_DIALOGS.PREVENT_DELETE_SYMBOL_DIALOG_ID]: {
    component: PreventDeleteSymbolDialog,
  },
  [DASHBOARD_DIALOGS.PREVENT_DELETE_RESOURCE_DIALOG_ID]: {
    component: PreventDeleteResourceDialog,
  },
  [DASHBOARD_DIALOGS.NOT_AUTHTORIZED_DIALOG_ID]: {
    component: NotAuthorizedDialog,
  },
  [DASHBOARD_DIALOGS.DEPLOY_PROGRESS_DIALOG_ID]: {
    component: DeployProgressDialog,
  },
  [DASHBOARD_DIALOGS.UNSAVED_CHANGES_DIALOG_ID]: {
    component: UnsavedChangesDialog,
  },
  [DASHBOARD_DIALOGS.MIGRATION_RESULT_DIALOG_ID]: {
    component: MigrationResultDialog,
  },
  [DASHBOARD_DIALOGS.REFACTOR_FUNCTION_DIALOG_ID]: {
    component: RefactorAsFunctionDialog,
  },
  [DASHBOARD_DIALOGS.PREVENT_BREAKING_DSL_DIALOG_ID]: {
    component: PreventBreakingDSLDialog,
  },
  [DASHBOARD_DIALOGS.BROKEN_LOADED_DSL_DIALOG_ID]: {
    component: BrokenLoadedDSLDialog,
  },
  [DASHBOARD_DIALOGS.LOST_CONNECTION_DIALOG_ID]: {
    component: LostConnectionDialog,
  },
};

/**
 * GlobalDialogs, combines all dialogs at one place
 */
export const GlobalDialogs: React.FC = reactMemo('GlobalDialogs', () => {
  const allDialogsState = useAllDialogsState();

  return (
    <>
      {Object.entries(DIALOGS_MAP)
        .map(
          ([dialogID, { renderAlways, component: DialogComponent }]) =>
            (allDialogsState[dialogID]?.isOpen || renderAlways) && (
              <DialogComponent key={dialogID} />
            ),
        )
        .filter(Boolean)}
    </>
  );
});
