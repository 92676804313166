import { MATERIAL_COMPONENT_DSL_NAMES } from '@builder/schemas';
import { ValueOf } from '@builder/utils';

export const DASHBOARD_EVENTS = {
  appConfigurationInit: '@app-configuration/init',
  appConfigurationUpdate: '@app-configuration/update',
  appConfigurationUpdateDslNodes: '@app-configuration/update-dsl-nodes',
  appConfigurationUpdateVersion: '@app-configuration/update-version',
  appConfigurationDSLCleanUp: '@app-configuration/dsl-clean-up',

  // dashboard
  dashboardClear: '@dashboard/clear',
  dashboardClearSelection: '@dashboard/clear-selection',
  dashboardUndo: '@dashboard/undo',
  dashboardRedo: '@dashboard/redo',
  // we use dashboardIFrameRefresh event to set iFrameRefreshed to true in the store
  // to be confident that all application knows that iframe was refreshed
  dashboardIFrameRefresh: '@dashboard/iframe-refresh',
  dashboardSetLoadingConfiguration: '@dashboard/set-loading-configuration',

  // enable or disable save button
  isSaveButtonDisabledUpdate: '@dashboard/set-save-button-state',

  isSavedState: '@dashboard/set-saved-state',

  // history
  historyPush: '@history/push',
  historyFlush: '@history/flush',

  // navigator
  navigatorExpandNodeList: '@navigator/expand-node-list',
  navigatorExpandNode: '@navigator/expand-node',
  navigatorCollapseNode: '@navigator/collapse-node',
  navigatorCollapseNodeList: '@navigator/collapse-node-list',

  // layouts
  layoutModeUpdate: '@navigator/layout-mode-update',

  // nodes
  componentUpdateMany: '@component/update-many',
  componentResetSettings: '@component/condition-reset-settings', // unused
  componentConditionRuleUpdate: '@component/condition-rule-update',
  componentAllowedRolesUpdate: '@component/allowed-roles-update',
  componentSchemaOverrideUpdate: '@component/schema-override-update',
  componentPresentationVisibleToggle: '@component/presentation-visible-toggle',
  componentPresentationRemove: '@component/presentation-remove',
  componentPresentationAdd: '@component/presentation-add',
  componentIteratorDataUpdate: '@component/iterator-data-update',
  componentPropUpdate: '@component/prop-update',
  componentPropUpdateMany: '@component/prop-update-many',
  componentLocalStatesUpdate: '@component/local-states-update',
  componentStatesRemove: '@component/state-remove',
  componentChildrenNodePropsUpdate: '@component/children-node-props-update', // unused
  componentReactNodeTextUpdate: '@component/react-node-text-update',
  componentToggleSelect: '@component/toggle-select',
  componentToggleSelectByID: '@component/toggle-select-by-id',
  componentSelect: '@component/select',
  componentSelectMultiple: '@component/select-multiple',
  componentDeselect: '@component/deselect',
  componentHover: '@component/hover',
  componentHoverRemove: '@component/hover-remove',
  componentToggleFocus: '@component/toggle-focus',
  componentSelectedRemove: '@component/selected-remove',
  componentCreate: '@component/create',
  componentMove: '@component/move',
  componentRemove: '@component/remove',
  componentUpdateAlias: '@component/component-update-alias',
  componentUpdateLayoutAlias: '@component/component-update-layout-alias',
  componentCopy: '@component/copy',
  componentCopyStyle: '@component/copy-style',
  componentPasteStyle: '@component/paste-style',
  componentClone: '@component/clone',
  componentMoveDirection: '@component/move-direction',
  componentSaveAsGlobalCSS: '@component/save-as-global-css',

  // states
  stateCreate: '@states/create',
  stateFunctionCreate: '@states/function-create', // unused
  stateQueryCreate: '@states/query-create',
  stateMutationCreate: '@states/mutation-create',
  stateUpdate: '@states/update',
  stateUpdateType: '@states/update-type',
  stateUpdateTypeDialog: '@states/update-type-dialog',
  stateRemove: '@states/remove',
  stateConvert: '@states/convert',

  // routes
  routeCreate: '@route/create',
  routeCurrentUpdate: '@route/current-update',
  routeLayoutUpdate: '@route/route-layout-update',
  routeDelete: '@route/delete',
  routeClone: '@route/clone',
  layoutCreate: '@route/layout-create',
  layoutUpdate: '@route/layout-update',
  layoutChange: '@route/layout-change',

  // size
  userAppResize: '@user-app/resize',

  // openAsRoot
  openAsRoot: '@open-as/root',

  // settings
  settingsUpdate: '@settings/update',

  // authentication
  authenticationUpdate: '@authentication/update',
  authResourceIDUpdate: '@authentication/auth-resource-id-update',

  // theme manager
  setThemeManagerVisibility: '@theme-manager/set-visibility',

  // theme
  themeUpdate: '@theme/update', // unused
  themeCSSGlobalUpdate: '@theme/css-global-update',
  themeCSSGlobalSelectorsUpdate: '@theme/css-global-selectors-update',
  themeCSSCustomUpdate: '@theme/css-custom-update',
  themeFrameworkSettingsUpdate: '@theme/frameworkSettings-update',

  // theme media queries
  mediaQueryCreate: '@theme/media-query/create',
  mediaQueryUpdate: '@theme/media-query/update',
  mediaQueryDelete: '@theme/media-query/delete',

  // router
  routerPathUpdate: '@router/updateUrl',
  routerPathUpdateByUserApp: '@router/updateUrlByUserApp',

  // runtime
  stateReport: '@runtime/state/report',
  updateNodeMeasures: '@runtime/node/measures',

  // components
  userComponentCreate: '@components/user/create',
  userComponentUpdate: '@components/user/update',
  userComponentDelete: '@components/user/delete',

  // copy buffer
  copyBufferSet: '@copy-buffer/set',
  copyBufferClear: '@copy-buffer/clear',
  copyBufferApply: '@copy-buffer/apply',

  // assets
  createAsset: '@assets/create',
  updateAsset: '@assets/update',
  deleteAsset: '@assets/delete',

  // fonts
  createCustomFont: '@custom-fonts/create',
  deleteCustomFont: '@custom-fonts/delete',
  googleFontCreate: '@google-fonts/create',
  googleFontUpdate: '@google-fonts/update',
  googleFontDelete: '@google-fonts/delete',

  // libraries
  createLibrary: '@library/create',
  updateLibrary: '@library/update',
  removeLibrary: '@library/remove',

  // resources
  createResource: '@resource/create',
  updateResource: '@resource/update',
  deleteResource: '@resource/delete',

  // hooks
  updateHooks: '@hooks/update',

  // css
  cssMediaQueriesSet: '@css/media-queries/set',
  cssTypographySet: '@css/typography/set',
  cssComponentSet: '@css/components/set',
} as const;

export type DashboardEventTypes = ValueOf<typeof DASHBOARD_EVENTS>;

export const DASHBOARD_ANIM_EVENTS = {
  moveStart: '@dnd-preview/start-move',
  moveName: '@dnd-preview/name',
  move: '@dnd-preview/move',
  moveEnd: '@dnd-preview/end-move',
  navigationEnter: '@dnd-preview/navigation/enter',
  navigationLeave: '@dnd-preview/navigation/leave',
  clear: '@dnd-preview/clear',
} as const;

export type DashboardAnimEventTypes = ValueOf<typeof DASHBOARD_ANIM_EVENTS>;

export const UI_BUILDER_EVENTS = {
  isSaveButtonDisabledUpdate: '@ui-builder/is-save-button-disabled-update',
  toggleDevMode: '@builder/dev-mode/toggle',
  modeRequestPreview: '@builder/mode-req/preview',
  modeRequestConstruction: '@builder/mode-req/construction',
  notificationSend: '@builder/notification-send',
  errorAppCrashedNotify: '@builder/error/crashed/notify',
  errorAppNotify: '@builder/error/notify',
  appEngineAuditNotify: '@builder/audit',
  successAppNotify: '@builder/success/notify',
  removeNotification: '@builder/remove-notification',
  closeSnackbar: '@builder/close-snackbar',
  updateLeftPanel: '@builder/update-left-panel',
  updateRightPanel: '@builder/update-right-panel',
  toggleAssetViewMode: '@builder/asset-view/toggle',
  setEditModeToLayout: '@builder/set-edit-mode-to-layout',
  setEditModeToPage: '@builder/set-edit-mode-to-page',
  setSelectedRoute: '@builder/set-selected-route',
  setCurrentLayoutNodesIds: '@builder/set-current-layout-nodes-ids',
  designScopeInit: '@builder/design-scope/init',
  designScopeUpdateSession: '@builder/design-scope/update-session',
  saveProcessStarts: '@builder/save-process-starts',
  setVisiblePresentation: '@builder/set-visible-presentation',
  successDeploy: '@builder/success/deploy',
  failDeploy: '@builder/error/deploy',
  setIssueManagerVisibility: '@builder/set-issue-manager-visibility',
  saveState: '@builder/save-state',
} as const;

export const APP_RENDER_ERRORS = {
  pushError: '@builder/error/push',
  pushNotifications: '@builder/error/notifications',
} as const;

export type UIBuilderEventTypes = ValueOf<typeof UI_BUILDER_EVENTS>;

export const ALL_POSSIBLE_EVENTS = Object.values({
  ...DASHBOARD_EVENTS,
  ...DASHBOARD_ANIM_EVENTS,
  ...UI_BUILDER_EVENTS,
  ...APP_RENDER_ERRORS,
});

export type AllPossibleEventTypes = ValueOf<typeof ALL_POSSIBLE_EVENTS>;

export const DND_TARGET_TYPES = {
  relative: 'relative',
  into: 'into',
} as const;

export type DNDTargetTypes = ValueOf<typeof DND_TARGET_TYPES>;

export const UI_BUILDER_MODES = {
  construction: 'construction',
  preview: 'preview',
} as const;

export const LEFT_PANEL_TABS = {
  hooks: 'hooks',
  settings: 'settings',
  navigator: 'navigator',
  pages: 'pages',
  requests: 'requests',
  functions: 'functions',
  theme: 'theme',
  assets: 'assets',
  resources: 'resources',
  none: 'none',
} as const;

export type LeftPanelTabsType = keyof typeof LEFT_PANEL_TABS;

export type UIBuilderModeTypes = ValueOf<typeof UI_BUILDER_MODES>;

export const ASSET_VIEW_MODES = {
  list: 'list',
  grid: 'grid',
} as const;

export type AssetViewModeTypes = ValueOf<typeof ASSET_VIEW_MODES>;

export const RIGHT_PANEL_TABS = {
  componentList: 'component-list',
  groupsList: 'groups-list',
  dialogs: 'dialogs',
  codePreview: 'code-preview',
  layouts: 'layouts',
  audit: 'audit',
} as const;

export type RightPanelTabTypes = ValueOf<typeof RIGHT_PANEL_TABS>;

export const NODE_SETTINGS_TABS = {
  properties: 'properties',
  style: 'style',
  events: 'events',
} as const;

export type NodeSettingsTabTypes = ValueOf<typeof NODE_SETTINGS_TABS>;

export const COMPONENT_SELECT_ACTORS = {
  dashboard: 'dashboard',
  navigator: 'navigator',
  create: 'create',
} as const;

export const UI_BUILDER_EDIT_MODES = {
  page: 'page',
  layout: 'layout',
} as const;

export type ComponentSelectActorTypes = ValueOf<typeof COMPONENT_SELECT_ACTORS>;

export const DEFAULT_VALUE = 'defaultValue';

export const COMPONENTS_REQUIRE_UPDATE_STATE = [
  MATERIAL_COMPONENT_DSL_NAMES.BuilderComponentsTab,
] as string[];
