import { EventPayload } from '@8base-private/event-handler';
import { AnyAction as ReduxAction } from 'redux';

import { NodeDSL } from '@builder/schemas';

import { DASHBOARD_EVENTS } from 'src/store/common';

import { ActionScopeStrategy, SCOPE_GLOBAL } from './ActionScopeStrategy';

export class DefaultStrategy implements ActionScopeStrategy {
  execute(
    currentRouteNode: NodeDSL,
    eventPayload: EventPayload,
    action: ReduxAction,
  ): {
    eventPayload: EventPayload;
    newEventsToPush: EventPayload[];
  } {
    let scope = SCOPE_GLOBAL;

    switch (action.type) {
      case DASHBOARD_EVENTS.stateUpdate:
      case DASHBOARD_EVENTS.stateCreate:
      case DASHBOARD_EVENTS.stateQueryCreate:
      case DASHBOARD_EVENTS.stateMutationCreate:
        scope =
          action.stateDSL?.scope === SCOPE_GLOBAL ? SCOPE_GLOBAL : action.connectedNodeParentID;
        break;
      case DASHBOARD_EVENTS.stateUpdateType:
        scope = action.stateDSL?.scope === SCOPE_GLOBAL ? SCOPE_GLOBAL : action.connectedNodeID;
        break;
      default:
        scope = SCOPE_GLOBAL;
        break;
    }

    return { eventPayload: { ...eventPayload, scope }, newEventsToPush: [] };
  }
}
