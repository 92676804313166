import React, { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { isEmpty } from 'ramda';

import { hasPropJsCode } from '@builder/schemas';
import { isArray, useBoolState, useDebouncedState } from '@builder/utils';

import { customOnBlur } from '../../../../../shared/components/CodemirrorEditor/CodeMirror.UpdateListener';
import { CodeMirrorComponent } from '../../../../../shared/components/CodemirrorEditor/CodeMirrorComponent';
import { CodeEditorDialogArgs, DASHBOARD_DIALOGS } from 'src/dialogs';
import { useDialogState } from 'src/providers';
import { CodeEditorOpenButton, InputContainer, InputContainerProps } from 'src/shared/components';
import { DashboardState } from 'src/store';

const EditorContainer = styled.div<{ isFocused: boolean; error?: boolean }>`
  position: relative;
  flex: 1;
  width: 221px;
  min-height: ${({ theme }) => theme.layout.controls.height}px;
  border: 1px solid ${({ theme }) => theme.palette.border.input};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.background.input};
  ${({ error, isFocused, theme }) => {
    if (error || isFocused) {
      return css`
         {
          padding: ${parseFloat(theme.spacing(1)) - 1}px ${parseFloat(theme.spacing(1.75)) + 1}px
            ${parseFloat(theme.spacing(1)) + 1}px ${parseFloat(theme.spacing(1.75)) + 1}px;
        }
      `;
    }
  }}
  ${({ theme, isFocused }) =>
    isFocused &&
    css`
      border: 2px solid ${theme.palette.primary.main} !important;
    `}
  ${({ theme, error }) =>
    error &&
    css`
      border: 2px solid ${theme.palette.error.main} !important;
    `}
    :hover {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }
`;
export type CustomCodeViewEditorProps<T extends string> = InputContainerProps & {
  value: T;
  onChange: (value: T) => void;
  onBlur?: (value: T) => void;
  nodeID?: string;
  skipDebounce?: boolean;
  placeholder?: string;
};
export const CustomCodeViewEditor = <T extends string>({
  label,
  labelPlacement,
  variant,
  error,
  helperText,
  icon,
  isTextIcon,
  value,
  onChange,
  onBlur,
  nodeID,
  skipDebounce,
  enableFx,
  isFxEnabled,
  showFx = false,
  'data-test': dataTest,
  dashboardState,
}: CustomCodeViewEditorProps<T> & { dashboardState?: DashboardState }): JSX.Element => {
  const [debouncedValue, setDebouncedValue] = useDebouncedState(value, onChange, {
    skipDebounce,
  });
  const [stringValue, setStringValue] = useState(debouncedValue || '');
  const { openDialog } = useDialogState<CodeEditorDialogArgs>();
  const [isFocused, { setTrue: setIsFocusedTrue, setFalse: setIsFocusedFalse }] = useBoolState(
    false,
  );
  useEffect(() => {
    if (isArray(dashboardState?.operations.selectedID)) return;

    setStringValue(value || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const updateLocalValues = async (newValue: string) => {
    setStringValue(newValue);
  };

  const updateJsCode = async (newValue: string) => {
    await updateLocalValues(newValue);

    if (hasPropJsCode(newValue) || isEmpty(newValue)) {
      return setDebouncedValue(`${newValue}` as T);
    }
  };

  const openEditorDialog = () => {
    openDialog(DASHBOARD_DIALOGS.CODE_EDITOR_DIALOG_ID, {
      initialValues: stringValue,
      label: label || '',
      nodeID,
      language: 'typescript',
      onSave: (val = '') => updateJsCode(val),
    });
  };

  const handleInput = async (updatedValue: string) => {
    if (isArray(dashboardState?.operations.selectedID)) return;

    await updateJsCode(updatedValue);
  };

  return (
    <InputContainer
      label={label}
      labelPlacement={labelPlacement}
      variant={variant}
      error={error}
      icon={icon}
      isTextIcon={isTextIcon}
      helperText={helperText}
      enableFx={enableFx}
      isFxEnabled={isFxEnabled}
      data-test={dataTest}
      showFx={showFx}
    >
      <EditorContainer
        isFocused={isFocused}
        error={error}
        onFocus={setIsFocusedTrue}
        onBlur={setIsFocusedFalse}
        data-test={dataTest}
      >
        <CodeMirrorComponent defaultCode={stringValue} extensions={[customOnBlur(handleInput)]} />
        <CodeEditorOpenButton onClick={openEditorDialog} />
      </EditorContainer>
    </InputContainer>
  );
};
