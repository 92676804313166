import { hasUsedVarInJSCode, hasUsedVarInNodeListDSL } from '../../../utils';
import { PREDEFINED_IMPORTS } from './constants';
import { PredefinedStateGenerator } from './PredefinedStateGenerator';
/**
 * Provides an api to generate predefined state usage.
 */
export class PredefinedStateListUsageGenerator {
    constructor(componentListDSL) {
        this.predefinedStateGenerators = PREDEFINED_IMPORTS.map(predefinedStateData => new PredefinedStateGenerator(predefinedStateData.importData, predefinedStateData.options));
        this.componentListDSL = componentListDSL;
    }
    getPredefinedHookGenerators() {
        return this.predefinedStateGenerators.filter(predefinedStateGenerator => predefinedStateGenerator.isHookState());
    }
    /**
     * @example
     * () => 'const history = useHistory();'
     */
    generatePredefinedHooksCallsForHook(hookCode) {
        return this.getPredefinedHookGenerators()
            .filter(predefinedStateGenerator => {
            const stateName = predefinedStateGenerator.stateAccessor.getStateName();
            return hasUsedVarInJSCode(stateName, hookCode);
        })
            .reduce((accum, predefinedStateGenerator) => {
            const hookCall = predefinedStateGenerator.getHookCall();
            return `${accum}\n ${hookCall}`;
        }, '');
    }
    /**
     * @example
     * () => 'const history = useHistory();'
     */
    generatePredefinedHooksCallsForComponent(usedNodeListDSL) {
        return this.getPredefinedHookGenerators()
            .filter(predefinedStateGenerator => {
            if (!predefinedStateGenerator.mustBeOmitted()) {
                return true;
            }
            const stateName = predefinedStateGenerator.stateAccessor.getStateName();
            return hasUsedVarInNodeListDSL(stateName, usedNodeListDSL, this.componentListDSL);
        })
            .reduce((accum, predefinedStateGenerator) => {
            const hookCall = predefinedStateGenerator.getHookCall();
            return `${accum}\n ${hookCall}`;
        }, '');
    }
}
