var _a;
import { omit } from 'ramda';
import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_SCOPES, STATE_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps, commonFormFieldProps } from '../../common/commonProps';
import { BuilderComponentsSlider } from '../../material-components/BuilderComponentsSlider';
import { BuilderComponentsFormSliderSettings } from './BuilderComponentsFormSlider.settings';
export const BuilderComponentsFormSlider = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsFormSlider,
    displayName: 'Form Slider',
    icon: 'formSlider',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.form,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'FormSlider',
    },
    schema: {
        mainPropPath: {
            mainProp: 'Label',
        },
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.anyAncestor,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsForm,
                },
            },
        ],
        presentations: [visibleByCondition],
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.number,
                    name: 'FormSlider',
                    defaultValue: 30,
                },
            ],
            props: {
                fieldProps: {
                    name: '__FORM__.FormSlider',
                },
                sliderProps: Object.assign(Object.assign({}, omit(['onChange', 'value', 'min', 'max'], (_a = BuilderComponentsSlider.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props)), { value: '{{ __STATES__.FormSlider.value }}', onChange: {
                        actionType: 'customCode',
                        args: {
                            code: '__STATES__.FormSlider.setValue(event.target.value)',
                        },
                    } }),
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { formik: { type: COMPONENT_DSL_PROP_TYPES.object }, fieldProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: commonFormFieldProps,
            }, sliderProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: BuilderComponentsSlider.schema.props,
            }, autofocus: { type: COMPONENT_DSL_PROP_TYPES.boolean }, marks: { type: COMPONENT_DSL_PROP_TYPES.string }, value: { type: COMPONENT_DSL_PROP_TYPES.string }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            } }),
    },
    settings: BuilderComponentsFormSliderSettings,
};
