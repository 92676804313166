import React, { useCallback, useEffect, useState } from 'react';

import { $isLinkNode, TOGGLE_LINK_COMMAND, $createLinkNode } from '@lexical/link';
import { mergeRegister } from '@lexical/utils';
import { Box, Popper } from '@mui/material';
import {
  $createTextNode,
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';

import {
  PopupContent,
  PopupHeader,
  PopupTitle,
} from 'src/features/node-settings/common/components/SettingsListItem/SettingsListItem.styles';
import { SelectViewEditor } from 'src/features/node-settings/setting-views';
import { useTheme } from 'src/providers/ThemeProvider';
import { Button, CssGrid, Icon, TextField } from 'src/shared/components';

import { getSelectedNode } from './utils';

export default function FloatingLinkEditor({
  editor,
  anchorEl,
  hideEditor,
}: {
  editor: LexicalEditor;
  anchorEl: HTMLElement | null;
  hideEditor: () => void;
}): JSX.Element | null {
  const theme = useTheme();
  const isOpen = Boolean(anchorEl);
  const [linkUrl, setLinkUrl] = useState('');
  const [textToDisplay, setTextToDisplay] = useState<string>('');
  const [target, setTarget] = useState<string>('');

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
        setTarget(parent.getTarget() || '');
        setTextToDisplay(parent.getTextContent());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
        setTextToDisplay(node.getTextContent());
        setTarget(node.getTarget() || '');
      } else {
        if (node) {
          setTextToDisplay(selection.getTextContent());
        }

        setLinkUrl('');
      }
    }

    return true;
  }, []);

  const deleteLink = () => {
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    hideEditor();
  };

  // keep the editor state in sync
  useEffect(() => {
    const selectionChangeListener = mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        4,
      ),
    );

    return selectionChangeListener();
  }, [editor, updateLinkEditor]);

  // set Initial state for the editor input
  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  // save history
  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        1,
      ),
    );
  }, [editor, updateLinkEditor]);

  const handleLinkApply = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Only happens if both url and text are filled
    if (linkUrl !== '' && textToDisplay !== '') {
      editor.update(() => {
        const selection = $getSelection();
        // RangeSelection doesn't mean the user selected several characters
        if ($isRangeSelection(selection)) {
          const node = getSelectedNode(selection);
          const parent = node.getParent();
          // Create new link node and append the text
          const newLink = $createLinkNode(linkUrl, { target });
          const text = $createTextNode(textToDisplay);
          newLink.append(text);

          // If existing link, removes the previous link
          if ($isLinkNode(parent)) {
            parent.remove();
          } else if ($isLinkNode(node)) {
            node.remove();
          }

          // Inserts the new generated link
          selection.insertNodes([text]);
          text.select(0, textToDisplay.length);
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, { url: linkUrl, target });
        }
      });
    }

    hideEditor();
  };

  return isOpen ? (
    <Popper
      style={{ zIndex: 1201 }}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 277],
          },
        },
      ]}
      placement="left-start"
      id={anchorEl?.id}
      anchorEl={anchorEl}
      open={isOpen}
    >
      <PopupContent>
        <CssGrid gap={2}>
          <PopupHeader>
            <PopupTitle variant="body1">Edit Link</PopupTitle>
            <Icon
              element="button"
              width="16"
              height="16"
              name="cross"
              fill={theme.palette.grey[500]}
              onClick={hideEditor}
            />
          </PopupHeader>

          <form onSubmit={handleLinkApply}>
            <CssGrid gap={2}>
              <TextField
                value={textToDisplay}
                onChange={event => {
                  setTextToDisplay(event.target.value);
                }}
                label="Text to display"
                variant="outlined"
                fullWidth
                required
              />
              <TextField
                value={linkUrl}
                onChange={event => {
                  setLinkUrl(event.target.value);
                }}
                label="Link to"
                variant="outlined"
                fullWidth
                required
              />
              <SelectViewEditor
                label="Target"
                propValue={target}
                onChangePropValue={event => {
                  setTarget(event as string);
                }}
                options={[
                  { value: '_blank', label: '_blank' },
                  { value: '_self', label: '_self' },
                  { value: '_top', label: '_top' },
                ]}
                showFx={false}
              />
              <Box display="flex" justifyContent="space-between">
                <Button variant="text" color="error" type="button" onClick={deleteLink}>
                  Delete
                </Button>
                <Box display="flex" gap={1}>
                  <Button variant="contained" color="default" type="button" onClick={hideEditor}>
                    Cancel
                  </Button>
                  <Button variant="contained" color="success" type="submit">
                    Apply
                  </Button>
                </Box>
              </Box>
            </CssGrid>
          </form>
        </CssGrid>
      </PopupContent>
    </Popper>
  ) : null;
}
