var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-empty-function */
import { forwardRef, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { Checkbox } from '../Checkbox';
import { getIsInvalid, RESET_STATUS } from '../common';
export const FormCheckbox = forwardRef((_a, ref) => {
    var { checkboxProps, fieldProps, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, id, title, lang, className, translate, onChange = () => { }, checked } = _a, rest = __rest(_a, ["checkboxProps", "fieldProps", 'data-test', 'data-node-id', 'data-node-render-path', "id", "title", "lang", "className", "translate", "onChange", "checked"]);
    const { setFieldValue, initialValues, status, setStatus, values } = useFormikContext();
    const _b = checkboxProps !== null && checkboxProps !== void 0 ? checkboxProps : {}, { disabled } = _b, otherCheckboxProps = __rest(_b, ["disabled"]);
    const initialValue = typeof initialValues[fieldProps.name] === 'boolean' ? initialValues[fieldProps.name] : false;
    useEffect(() => {
        var _a;
        const updateStateEvent = {
            target: {
                checked: initialValue,
            },
        };
        setFieldValue(fieldProps.name, initialValue);
        onChange(updateStateEvent);
        (_a = checkboxProps === null || checkboxProps === void 0 ? void 0 : checkboxProps.onChange) === null || _a === void 0 ? void 0 : _a.call(checkboxProps, updateStateEvent, initialValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldProps.name, initialValue]);
    useEffect(() => {
        var _a;
        if (status === RESET_STATUS) {
            setStatus(undefined);
            const updateStateEvent = {
                target: {
                    checked: false,
                },
            };
            setFieldValue(fieldProps.name, initialValue);
            onChange(updateStateEvent);
            (_a = checkboxProps === null || checkboxProps === void 0 ? void 0 : checkboxProps.onChange) === null || _a === void 0 ? void 0 : _a.call(checkboxProps, updateStateEvent, initialValue);
        }
    }, [checkboxProps, status, setStatus, onChange, fieldProps.name, setFieldValue, initialValue]);
    useEffect(() => {
        if (checked !== values[fieldProps.name]) {
            setFieldValue(fieldProps.name, checked);
        }
    }, [checked, fieldProps.name, setFieldValue, values]);
    return (_jsx(Field, Object.assign({ name: fieldProps.name, validate: fieldProps.validate }, { children: ({ field, meta, form }) => {
            const isInvalid = getIsInvalid({ meta, form });
            const errorText = isInvalid ? meta.error : undefined;
            return (_jsx(Checkbox, Object.assign({}, rest, { id: id, title: title, lang: lang, className: className, translate: translate, ref: ref }, otherCheckboxProps, { "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, disabled: disabled, name: fieldProps.name, checked: Boolean(field.value), onChange: (event, isChecked) => {
                    form.setFieldValue(fieldProps.name, isChecked);
                    onChange(event);
                }, error: isInvalid, helperText: errorText })));
        } })));
});
