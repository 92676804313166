import React, { useCallback, useEffect, useState } from 'react';

import { difference } from 'lodash';

import { getNodeSchema } from '@builder/schemas/dist/mjs/selectors/app-nodes-schema-selectors';
import { ComponentDSL } from '@builder/schemas/dist/mjs/types/component';
import { NodeDSL } from '@builder/schemas/dist/mjs/types/node';
import { isArray, Z_INDEXES_APP } from '@builder/utils';

import {
  useUIBuilderEditMode,
  useSelectedNodeID,
  useDashboardState,
  useAppDispatch,
  useUIBuilderLeftPanel,
  useNodeContainerParentID,
  useNodeIndexInContainer,
  useTotalNodesInContainer,
  useNodeListDSL,
  useComponentListDSL,
} from 'src/providers/ReduxProvider';
import { Popper, PopperProps } from 'src/shared/components';
import { MESSAGES } from 'src/shared/constants';
import { useNodeActionsInfo } from 'src/shared/hooks';
import {
  LEFT_PANEL_TABS,
  NODE_SETTINGS_TABS,
  NodeSettingsTabTypes,
  RIGHT_PANEL_TABS,
  UI_BUILDER_EDIT_MODES,
  UI_BUILDER_EVENTS,
  DASHBOARD_EVENTS,
} from 'src/store';

import { NODE_ACTIONS_POPPER_MODIFIERS } from './NodeActionsPopper.constants';
import { MenuButton, MenuContent, MenuDivider } from './NodeActionsPopper.styles';

type NodeActionsPopperProps = PopperProps & {
  onCreateSymbol?: (event: React.SyntheticEvent<Element, Event>) => void;
  toggleMenu: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => void;
  onDelete: ((event: React.SyntheticEvent<Element, Event>) => void) | undefined;
  onCopy: (event: React.SyntheticEvent<Element, Event>) => void;
  onCopyStyle: (event: React.SyntheticEvent<Element, Event>) => void;
  onPasteStyle: (event: React.SyntheticEvent<Element, Event>) => void;
  onCopyToBuffer: (event: React.SyntheticEvent<Element, Event>) => void;
  onApplyBuffer: (event: React.SyntheticEvent<Element, Event>) => void;
  onOpenAsRoot: (event: React.SyntheticEvent<Element, Event>) => void;
  onBackToView: (event: React.SyntheticEvent<Element, Event>) => void;
  onSaveStylesAsGlobalCSS?: (event: React.SyntheticEvent<Element, Event>) => void;
  componentName?: string;
  nodeID?: string;
  isMultiSelected?: boolean;
};

type NodeSchema = {
  nodeDSL: NodeDSL;
  componentDSL: ComponentDSL;
  schema: {
    props: Record<string, { type: string }>;
  };
};

export const NodeActionsPopper: React.FC<NodeActionsPopperProps> = ({
  open: isOpen,
  anchorEl,
  toggleMenu,
  onClose,
  placement,
  modifiers,
  zIndex,
  onCreateSymbol,
  onDelete,
  onCopy,
  onCopyToBuffer,
  onCopyStyle,
  onPasteStyle,
  onApplyBuffer,
  onOpenAsRoot,
  onBackToView,
  onSaveStylesAsGlobalCSS,
  componentName,
  nodeID,
  disablePortal = false,
}) => {
  const {
    showCreateSymbolAction,
    showCopyAction,
    showCopyAndPasteStyleAction,
    showCopyToBufferAction,
    showApplyBufferAction,
    showDeleteAction,
    showOpenAsRootAction,
    showBackToViewAction,
    showMoveUpDownAction,
  } = useNodeActionsInfo({
    nodeID,
    nodeName: componentName,
    deleteNode: onDelete,
    openAsRoot: onOpenAsRoot,
    backToView: onBackToView,
    createSymbolToBuffer: onCreateSymbol,
    copyComponent: onCopy,
    copyStyle: onCopyStyle,
    copyComponentToBuffer: onCopyToBuffer,
    applyBufferToNode: onApplyBuffer,
    saveAsGlobalCSS: onSaveStylesAsGlobalCSS,
  });
  const send = useAppDispatch();
  const leftPanel = useUIBuilderLeftPanel();
  const currentSelectedIDs = useSelectedNodeID();
  const isMultiSelected = isArray(currentSelectedIDs);
  const editMode = useUIBuilderEditMode();
  const { copyStyle: dashboardCopyStyle } = useDashboardState();
  const containerParentID = useNodeContainerParentID(nodeID as string);
  const nodeIndexInContainer = useNodeIndexInContainer(nodeID as string);
  const totalNodesInContainer = useTotalNodesInContainer(nodeID as string);
  const isFirstNodeInContainer = nodeIndexInContainer === 0;
  const isLastNodeInContainer = nodeIndexInContainer === (totalNodesInContainer as number) - 1;

  const handleCreateSymbol = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      toggleMenu();
      onCreateSymbol?.(event);
    },
    [onCreateSymbol, toggleMenu],
  );

  const nodeListDSL = useNodeListDSL();
  const componentListDSL = useComponentListDSL();

  const nodeSchema: NodeSchema | undefined = nodeID
    ? (getNodeSchema({ nodeListDSL, componentListDSL }, { nodeID }) as NodeSchema)
    : undefined;

  const saveAsGlobalCSSOption = Object.keys(nodeSchema?.nodeDSL?.props.style || {}).length !== 0;

  const handleComponentCopy = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      toggleMenu();
      onCopy(event);
    },
    [onCopy, toggleMenu],
  );

  const handleComponentCopyToBuffer = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      toggleMenu();
      onCopyToBuffer(event);
    },
    [onCopyToBuffer, toggleMenu],
  );

  const handleApplyBufferToNode = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      toggleMenu();
      onApplyBuffer(event);
    },
    [onApplyBuffer, toggleMenu],
  );

  const handleDeleteNode = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      toggleMenu();
      onDelete?.(event);
    },
    [onDelete, toggleMenu],
  );

  const handleFindInPageStructure = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      event.preventDefault();
      toggleMenu();
      // open page structure sidebar menu
      send({
        type: UI_BUILDER_EVENTS.updateLeftPanel,
        leftPanel: {
          ...leftPanel,
          currentTab: LEFT_PANEL_TABS.navigator,
        },
      });
    },
    [leftPanel, send, toggleMenu],
  );

  const handleOpenSettingTab = useCallback(
    (tabName: NodeSettingsTabTypes, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      event.preventDefault();
      toggleMenu();
      send({
        type: UI_BUILDER_EVENTS.updateRightPanel,
        rightPanel: {
          currentTab: RIGHT_PANEL_TABS.componentList,
          nodeSettingsArgs: {
            currentTab: tabName,
          },
        },
      });
    },
    [send, toggleMenu],
  );

  const handleOpenAsRoot = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      toggleMenu();
      onOpenAsRoot?.(event);
    },
    [onOpenAsRoot, toggleMenu],
  );

  const handleBackToView = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      toggleMenu();
      onBackToView?.(event);
    },
    [onBackToView, toggleMenu],
  );

  const [isStylePasteEnabled, setIsStylePasteEnabled] = useState(false);
  const [isStyleCopyEnabled, setIsStyleCopyEnabled] = useState(true);
  useEffect(() => {
    if (isOpen) {
      const styleObject = dashboardCopyStyle;

      if (isMultiSelected) {
        setIsStyleCopyEnabled(false);
      }

      if (
        Object.keys(styleObject.styles)?.length &&
        difference(Object.keys(styleObject), ['componentName', 'styles'])
      ) {
        setIsStylePasteEnabled(true);
      }
    }
  }, [componentName, isOpen, isMultiSelected, dashboardCopyStyle]);

  const handleStyleCopy = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      toggleMenu();
      setIsStylePasteEnabled(true);
      onCopyStyle?.(event);
    },
    [onCopyStyle, toggleMenu],
  );

  const handleStylePaste = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      toggleMenu();
      setIsStylePasteEnabled(false);
      onPasteStyle?.(event);
    },
    [onPasteStyle, toggleMenu],
  );

  const handleSaveAsGlobalCSS = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      toggleMenu();
      onSaveStylesAsGlobalCSS?.(event);
    },
    [onSaveStylesAsGlobalCSS, toggleMenu],
  );

  const handleMoveUp = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      toggleMenu();

      const eventPayload = {
        type: DASHBOARD_EVENTS.componentMoveDirection,
        nodeID: String(nodeID),
        parentID: String(containerParentID),
        direction: -1,
      };

      send(eventPayload);
    },
    [send, toggleMenu, nodeID, containerParentID],
  );

  const handleMoveDown = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      toggleMenu();

      const eventPayload = {
        type: DASHBOARD_EVENTS.componentMoveDirection,
        nodeID: String(nodeID),
        parentID: String(containerParentID),
        direction: 1,
      };

      send(eventPayload);
    },
    [send, toggleMenu, nodeID, containerParentID],
  );

  return anchorEl ? (
    <Popper
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      placement={placement || 'bottom-start'}
      modifiers={modifiers || NODE_ACTIONS_POPPER_MODIFIERS}
      zIndex={zIndex || Z_INDEXES_APP.breadcrumbs}
      disablePortal={disablePortal}
    >
      <MenuContent>
        <MenuButton onClick={handleFindInPageStructure}>
          {MESSAGES.components.actionPopper.findInPageStructure}
        </MenuButton>

        <MenuDivider />

        {showMoveUpDownAction && !isMultiSelected && (
          <>
            {isFirstNodeInContainer && (
              <>
                <MenuButton onClick={handleMoveDown}>
                  {MESSAGES.components.actionPopper.moveDown}
                </MenuButton>
                <MenuDivider />
              </>
            )}

            {isLastNodeInContainer && (
              <>
                <MenuButton onClick={handleMoveUp}>
                  {MESSAGES.components.actionPopper.moveUp}
                </MenuButton>
                <MenuDivider />
              </>
            )}

            {!isFirstNodeInContainer && !isLastNodeInContainer && (
              <>
                <MenuButton onClick={handleMoveUp}>
                  {MESSAGES.components.actionPopper.moveUp}
                </MenuButton>
                <MenuButton onClick={handleMoveDown}>
                  {MESSAGES.components.actionPopper.moveDown}
                </MenuButton>
                <MenuDivider />
              </>
            )}
          </>
        )}

        <MenuButton onClick={e => handleOpenSettingTab(NODE_SETTINGS_TABS.properties, e)}>
          {MESSAGES.components.actionPopper.openProperties}
        </MenuButton>
        <MenuButton onClick={e => handleOpenSettingTab(NODE_SETTINGS_TABS.style, e)}>
          {MESSAGES.components.actionPopper.openStyles}
        </MenuButton>
        <MenuButton onClick={e => handleOpenSettingTab(NODE_SETTINGS_TABS.events, e)}>
          {MESSAGES.components.actionPopper.openEvents}
        </MenuButton>

        <MenuDivider />

        {showCreateSymbolAction && !isMultiSelected && (
          <MenuButton onClick={handleCreateSymbol} data-test="overlay.controls.createSymbolBtn">
            {MESSAGES.components.actionPopper.createGroup}
          </MenuButton>
        )}
        {showCopyAction && (
          <MenuButton onClick={handleComponentCopy} data-test="overlay.controls.copyComponentBtn">
            {MESSAGES.components.actionPopper.clone}
          </MenuButton>
        )}
        {showOpenAsRootAction && (
          <MenuButton onClick={handleOpenAsRoot} data-test="overlay.controls.openAsRootBtn">
            Open As Root
          </MenuButton>
        )}
        {showBackToViewAction && (
          <MenuButton onClick={handleBackToView} data-test="overlay.controls.backToViewBtn">
            Back
          </MenuButton>
        )}
        {showCopyToBufferAction && (
          <MenuButton
            onClick={handleComponentCopyToBuffer}
            data-test="overlay.controls.copyToBufferBtn"
          >
            {MESSAGES.components.actionPopper.copyToBuffer}
          </MenuButton>
        )}

        {showApplyBufferAction && (
          <MenuButton onClick={handleApplyBufferToNode} data-test="overlay.controls.applyBufferBtn">
            {MESSAGES.components.actionPopper.applyCopyBuffer}
          </MenuButton>
        )}
        {(editMode === UI_BUILDER_EDIT_MODES.layout || showDeleteAction) && (
          <MenuButton onClick={handleDeleteNode}>
            {MESSAGES.components.actionPopper.delete}
          </MenuButton>
        )}

        {showCopyAndPasteStyleAction && (
          <>
            <MenuDivider />
            <MenuButton onClick={handleStyleCopy} disabled={!isStyleCopyEnabled}>
              {MESSAGES.components.actionPopper.copyStyle}
            </MenuButton>
            <MenuButton disabled={!isStylePasteEnabled} onClick={handleStylePaste}>
              {MESSAGES.components.actionPopper.pasteStyle}
            </MenuButton>
          </>
        )}

        {saveAsGlobalCSSOption && !isMultiSelected && (
          <>
            <MenuButton onClick={handleSaveAsGlobalCSS}>Save as Global CSS</MenuButton>
          </>
        )}

        {showApplyBufferAction && (
          <MenuButton onClick={handleApplyBufferToNode} data-test="overlay.controls.applyBufferBtn">
            Apply Copy Buffer
          </MenuButton>
        )}
      </MenuContent>
    </Popper>
  ) : (
    <></>
  );
};
