import {
  AppDSL,
  NodeListDSL,
  nodeListSelectors,
  NodeChecker,
  COMPONENT_SCHEMAS,
  UserComponentListDSL,
} from '@builder/schemas';
import { isArray, isObject } from '@builder/utils';

function getAllNodes(obj: Record<string, unknown>): string[] {
  const nodes = [];

  for (const key in obj) {
    if (obj[key]) {
      const value = obj[key];

      if (isObject(value)) {
        // If the value is an object, recursively call the function to get its nodes
        const nestedNodes = getAllNodes(value);

        // Add the nested nodes to the main nodes array
        nodes.push(...nestedNodes);
      }

      if (key === 'nodes' && isArray(value)) {
        // Add the current nodes property to the nodes array
        nodes.push(...value);
      }
    }
  }

  return nodes as string[];
}

function getNonExistingNodes(nodes: string[], dslNodes: NodeListDSL) {
  return nodes.filter(nodeId => !dslNodes[nodeId]);
}

function getNonExistingParents(nodeListDSL: NodeListDSL) {
  const nonExistingParents = [];

  for (const [key, node] of Object.entries(nodeListDSL)) {
    if (node.parentID && !nodeListDSL[node.parentID]) {
      nonExistingParents.push({ key, node });
    }
  }

  return nonExistingParents;
}

function getOrphanStates(appDSL: AppDSL) {
  const nodes = Object.values(appDSL.nodes);
  const allStatesIds = nodes
    .map(node => (node.context ? Object.values(node.context) : null))
    .concat(Object.values(appDSL.states))
    .flat(2)
    .filter(Boolean)
    .map(state => state?.id);

  const orphanStates = nodes.reduce((acc: string[], current) => {
    if (current.states) {
      const nodeStatesIds = current.states.map(stateRef => stateRef?.stateID || '');

      nodeStatesIds.forEach(id => {
        if (!allStatesIds.includes(id)) {
          acc.push(id);
        }
      });
    }

    return acc;
  }, []);

  return Boolean(orphanStates.length);
}

function areAllNodeSchemasPresent(nodes: NodeListDSL, userComponentsDSL: UserComponentListDSL) {
  const nodeList = Object.values(nodes);
  const areAllSchemasPresent = nodeList.every(node =>
    Boolean(COMPONENT_SCHEMAS[node.name] || userComponentsDSL[node.name]),
  );

  return areAllSchemasPresent;
}

export const assertDSL = (appDSL: AppDSL, userComponents: UserComponentListDSL): boolean => {
  const routeNodes = nodeListSelectors.getAllRouteNodes(appDSL.nodes);
  const allNodes = getAllNodes(appDSL.nodes);
  const hasNonExistingNodes = Boolean(getNonExistingNodes(allNodes, appDSL.nodes).length);
  const hasNonExistingParents = Boolean(getNonExistingParents(appDSL.nodes).length);
  const hasOrphanStates = getOrphanStates(appDSL);
  const areLocalStatesValid = routeNodes.every(node => NodeChecker.Value.isStateValid(node));
  const areAllSchemasPresent = areAllNodeSchemasPresent(appDSL.nodes, userComponents);

  if (
    hasNonExistingNodes ||
    hasNonExistingParents ||
    hasOrphanStates ||
    !areLocalStatesValid ||
    !areAllSchemasPresent
  ) {
    return false;
  }

  return true;
};
