import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useEffect } from 'react';
import { equals } from 'ramda';
import { MemoryRouter, useHistory, useLocation, matchPath } from 'react-router-dom';
import { nodeListSelectors, NOT_FOUND_PATH } from '@builder/schemas';
import { EMITTER_EVENTS, usePrevious } from '@builder/utils';
import { UI_BUILDER_MODES, useDashboardHook } from '../DashboardProvider';
import { useUserAppEventEmitter } from '../UserAppEventEmitterProvider';
const getInitialPageFromURL = () => {
    try {
        return new URL(window.location.href).searchParams.get('initialPath');
    }
    catch (error) {
        return null;
    }
};
const getAvailableRoutes = (appDSL) => {
    const nodeList = nodeListSelectors.getNodeListDSL(appDSL.nodes);
    const routeNodes = nodeListSelectors.getAllRouteNodesIncludeSystem(nodeList);
    const RoutesPaths = routeNodes.map(route => route.props.path);
    return RoutesPaths;
};
const RouterEffect = ({ onRouterUrlChange, appDSL, currentRoute, appDSLRef, }) => {
    var _a;
    const location = useLocation();
    const history = useHistory();
    const emitter = useUserAppEventEmitter();
    const historyUrl = location.pathname;
    const queryParams = history.location.search;
    const previousPath = usePrevious(historyUrl);
    const initialPage = getInitialPageFromURL();
    const availableRoutes = getAvailableRoutes(appDSL);
    const isAppDSLAndAppDSLRefEquals = equals(appDSLRef.current, appDSL);
    const { mode } = useDashboardHook();
    const isPreviewMode = mode === UI_BUILDER_MODES.preview;
    const inDashboard = (_a = window.location.href) === null || _a === void 0 ? void 0 : _a.includes('in-dashboard');
    useEffect(() => {
        if (initialPage) {
            history.push(initialPage);
        }
    }, [history, initialPage]);
    useEffect(() => {
        if (previousPath !== historyUrl) {
            if (!previousPath) {
                history.push(currentRoute);
            }
            else {
                const needAddQueryParams = isPreviewMode && !inDashboard && !!queryParams;
                let fullHistoryPath = historyUrl;
                if (needAddQueryParams) {
                    fullHistoryPath += queryParams;
                }
                onRouterUrlChange(fullHistoryPath);
            }
        }
        const unsubscribe = emitter.subscribe(EMITTER_EVENTS.changeRoute, ({ route }) => {
            history.push(route);
        });
        return () => {
            unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        history,
        onRouterUrlChange,
        historyUrl,
        emitter,
        previousPath,
        currentRoute,
        isPreviewMode,
        inDashboard,
    ]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    const routeExists = matchPath(isAppDSLAndAppDSLRefEquals ? historyUrl : currentRoute, {
        path: availableRoutes,
        exact: true,
    });
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!routeExists && !window.parent.skiptRedirect) {
            history.push(NOT_FOUND_PATH);
        }
    }, [history, routeExists, isAppDSLAndAppDSLRefEquals]);
    return null;
};
export const RouterProvider = ({ children, onRouterUrlChange, appDSL, currentRoute, appDSLRef, }) => {
    return (_jsxs(MemoryRouter, { children: [_jsx(RouterEffect, { onRouterUrlChange: onRouterUrlChange, appDSL: appDSL, currentRoute: currentRoute, appDSLRef: appDSLRef }), children] }));
};
