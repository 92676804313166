import { useAuth } from '@8base-react/auth';
import { useHistory, useLocation } from 'react-router';
import { useTheme } from '../../providers/ThemeProvider';
import { APP_ENGINE_PREDEFINED_IMPORTS } from '../predefinedImports';
import { useAssets } from './useAssets';
import { useRequestRunner } from './useRequestRunner';
import { useResources } from './useResources';
import { useRoutes } from './useRoutes';
const predefinedImportsState = APP_ENGINE_PREDEFINED_IMPORTS.reduce((accum, { varName, importData }) => (Object.assign(Object.assign({}, accum), { [varName]: importData })), {});
/**
 * This is used in the App Engine
 * We can't generate hooks because of react doesn't support optional rendering for hooks.
 * If you add new hook -> don't forget to add types to PredefinedTypesGenerator.
 */
export const useAppEnginePredefinedHooks = ({ appDSL, componentListDSL, assetBackendList, }) => {
    const { nodes: nodeListDSL, assets: assetListDSL, resources: resourceListDSL, states: stateListDSL, } = appDSL;
    const history = useHistory();
    const location = useLocation();
    const theme = useTheme();
    const auth = useAuth();
    const router = useRoutes(nodeListDSL);
    const assets = useAssets(assetListDSL, assetBackendList);
    const requestRunner = useRequestRunner(stateListDSL);
    const resources = useResources(resourceListDSL);
    return Object.assign({ history,
        location,
        theme,
        auth,
        router,
        assets,
        requestRunner,
        resources }, predefinedImportsState);
};
