import { FC, HTMLProps } from 'react';

import styled from '@emotion/styled';

import Loader from 'src/assets/images/8base-loader.gif';
import LoaderBackground from 'src/assets/images/circuit-board.svg';

const StyledSpinner8BaseBackground = styled.div`
  background-image: url(${LoaderBackground}), linear-gradient(180deg, #f9fbfd, #d8deec);
  background-color: white;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;
export const StyledSpinner8Base: FC = styled.img`
  width: 20vh;
`;

export const SimpleSpinner: FC<HTMLProps<HTMLImageElement>> = props => {
  return <StyledSpinner8Base src={Loader} {...props} />;
};

export const Spinner = (): JSX.Element => {
  return (
    <StyledSpinner8BaseBackground>
      <SimpleSpinner />
    </StyledSpinner8BaseBackground>
  );
};
