import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from '@emotion/styled';
import { Monaco } from '@monaco-editor/react';
import { Typography } from '@mui/material';
import { FieldInputProps, FormikErrors } from 'formik';
import { editor as MonacoEditorType } from 'monaco-editor';

import {
  NodeDSL,
  nodeListSelectors,
  stateListSelectors,
  StateListDSL,
  libraryListSelectors,
  assetListSelectors,
  appDSLSelectors,
  COMPONENT_DSL_NAMES,
  FORM_COMPONENT_DSL_NAMES,
} from '@builder/schemas';
import { log } from '@builder/utils';

import { DASHBOARD_DIALOGS } from '../dialogsMap';
import { checkAncestor } from 'src/features/navigator/utils/checkAncestor';
import { useDialogState, useGqlSchema, useAppDSL } from 'src/providers';
import {
  useNodeListDSL,
  useAppDSLStates,
  useResourceListDSL,
  useLibraryListDSL,
  useAssetListDSL,
} from 'src/providers/ReduxProvider';
import {
  EditorFormDialog,
  Field,
  MonacoEditor,
  disposePreviousModels,
  addDefaultOptions,
  addPredefinedTypes,
  addGlobalTypes,
  addIterableTypes,
  addAppStatesTypes,
  addLibrariesTypes,
  addFunctionTypes,
  addEventTypes,
} from 'src/shared/components';
import { useAssetsBackendHooks } from 'src/shared/graphql/hooks';
import { useSelectedNode } from 'src/shared/hooks';

const TABLE_AND_FRAGMENT = ['BuilderComponentsDataGrid', 'Fragment'];
const ITEM = 'item';
export type argTypes = {
  context: string[];
  action: string;
};
export type CodeEditorDialogLanguage = 'typescript' | 'json' | 'scss' | 'css' | 'graphql';
export type CodeEditorDialogArgs = {
  initialValues?: string;
  label: string;
  onSave: (value: string | undefined) => void;
  validateBeforeSave?: (value: string | undefined) => string | undefined;
  language?: CodeEditorDialogLanguage;
  nodeID?: string;
  dataTest?: string;
  options?: MonacoEditorType.IStandaloneEditorConstructionOptions;
  hasRefactoredFunction?: boolean;
  arg?: argTypes;
};

const DIALOG_ID = DASHBOARD_DIALOGS.CODE_EDITOR_DIALOG_ID;

const getEditorInitialValue = (val = '') => {
  return { code: val };
};

const ErrorText = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const CodeEditorDialog: React.FC = () => {
  const { isOpen, closeDialog, args } = useDialogState<CodeEditorDialogArgs>(DIALOG_ID);
  const [language, setLanguage] = useState<CodeEditorDialogArgs['language']>('typescript');
  const [connectedStatesDSL, setConnectedAppStatesDSL] = useState<StateListDSL>({});
  const [iterableNodeListDSL, setIterableNodeListDSL] = useState<NodeDSL[]>([]);
  const userAppStates = useAppDSLStates();
  const resourceListDSL = useResourceListDSL();
  const nodeListDSL = useNodeListDSL();
  const assetListDSL = useAssetListDSL();
  const { assetBackendList } = useAssetsBackendHooks();
  const assetFileWithBackendDataListDSL = useMemo(
    () =>
      assetListSelectors.getAssetFileWithBackendDataList(assetListDSL, {
        assetBackendList,
      }),
    [assetBackendList, assetListDSL],
  );
  const { schema } = useGqlSchema();
  const libraryListDSL = useLibraryListDSL();
  const libraryListDSLArray = libraryListSelectors.getLibraryArrayDSL(libraryListDSL);
  const appDSL = useAppDSL();
  const authResourceDSL = appDSLSelectors.getAuthResourceDSL(appDSL);
  const selectedNodeDSL = useSelectedNode();
  const isAutocompleteNode =
    selectedNodeDSL?.name === COMPONENT_DSL_NAMES.BuilderComponentsAutocomplete;
  const isFormAutocompleteNode =
    selectedNodeDSL?.name === FORM_COMPONENT_DSL_NAMES.BuilderComponentsFormAutocomplete;
  const isRichtextEditorNode =
    selectedNodeDSL?.name === COMPONENT_DSL_NAMES.BuilderComponentsRichtextEditor;

  // Get the item name from the Looper Component and add it to the arguments of Its children.
  let isFragmentOrTableChild = false;
  let argFromLooper = ITEM;
  if (selectedNodeDSL && nodeListDSL) {
    const fragmentOrTableChild = checkAncestor(
      TABLE_AND_FRAGMENT,
      selectedNodeDSL.parentID || 'ROOT',
      nodeListDSL,
    );

    isFragmentOrTableChild = fragmentOrTableChild.validation;

    argFromLooper = isFragmentOrTableChild
      ? nodeListDSL[fragmentOrTableChild.componentID || '']?.iterator?.name || ITEM
      : '';
  }

  useEffect(() => {
    if (args.language) {
      return setLanguage(args.language);
    }

    setLanguage('typescript');
  }, [args]);

  // prevent unnecessary call, need to get connectedStatesDSL
  useEffect(() => {
    if (args?.nodeID) {
      const nodeStatesDSL = stateListSelectors.getAccessibleOnNodeStatesWithScopeListDSL(
        userAppStates,
        {
          nodeListDSL,
          nodeID: args.nodeID,
        },
      );
      // for Iterator Item
      const nodeList = nodeListSelectors.getIterableParents(nodeListDSL, { nodeID: args.nodeID });
      // for Select, Menu
      const currentNode = nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID: args.nodeID });

      if (nodeListSelectors.isIterable(nodeListDSL, { nodeID: args.nodeID })) {
        nodeList.push(currentNode);
      }

      setIterableNodeListDSL(nodeList);
      setConnectedAppStatesDSL(nodeStatesDSL);
    } else {
      setConnectedAppStatesDSL({});
      setIterableNodeListDSL([]);
    }
  }, [args, userAppStates, nodeListDSL]);

  const onDialogClose = useCallback(() => {
    closeDialog(DIALOG_ID);
  }, [closeDialog]);

  const submitForm = useCallback(
    (
      values: { code: string },
      helpers: { setErrors: (errors: FormikErrors<{ code: string }>) => void },
    ) => {
      // prevent useless changes
      if (
        (values?.code === args?.initialValues || (!values?.code && !args?.initialValues)) &&
        !args.hasRefactoredFunction
      ) {
        closeDialog(DIALOG_ID);
        return;
      }

      if (args.validateBeforeSave) {
        const error = args.validateBeforeSave(values?.code);

        if (error) {
          return helpers.setErrors({ code: error });
        }
      }

      if (args.onSave && typeof args.onSave === 'function') {
        if (values?.code) {
          args.onSave(values?.code);
        } else {
          args.onSave(undefined);
        }
      } else {
        log.warn('@builder/client/dialog/CodeEditorDialog does not have onSave args');
      }

      closeDialog(DIALOG_ID);
    },
    [closeDialog, args],
  );

  const onFieldChange = useCallback(
    (field: FieldInputProps<unknown>) => (value?: string) => {
      // TODO: [APB-914] [monaco]: add autodetected language type
      // if (hasPropJsCode(value) && language !== 'typescript') {
      //   setLanguage('typescript');
      // } else if (args?.language !== language) {
      //   setLanguage(args?.language);
      // }

      field.onChange(value);
    },
    [],
  );

  const event = window.event as Event;

  const initMonaco = useCallback(
    (monaco: Monaco) => {
      if (language === 'typescript') {
        const autocompleteVariables = ['event', 'value', 'reason', 'details'];
        const richtextEditoVariables = ['event', 'editor', 'error'];

        disposePreviousModels(monaco);
        addDefaultOptions(monaco);

        addPredefinedTypes(monaco, nodeListDSL);
        addGlobalTypes(monaco);
        addIterableTypes(monaco, iterableNodeListDSL);
        addAppStatesTypes({
          monaco,
          gqlSchema: schema,
          appStatesDSL: connectedStatesDSL,
          resourceListDSL,
          assetListDSL: assetFileWithBackendDataListDSL,
          authResourceDSL,
        });
        addFunctionTypes(monaco, userAppStates);
        addLibrariesTypes(monaco, libraryListDSLArray);

        if (isAutocompleteNode || isFormAutocompleteNode) {
          addEventTypes(monaco, event, { context: autocompleteVariables } as argTypes);
        } else if (isRichtextEditorNode) {
          addEventTypes(monaco, event, { context: richtextEditoVariables } as argTypes);
        } else {
          let newArgs = args?.arg;
          if (isFragmentOrTableChild) {
            newArgs = {
              ...newArgs,
              context: [...(newArgs?.context || []), argFromLooper],
            } as argTypes;
          }

          addEventTypes(monaco, event, newArgs);
        }
      }
    },
    [
      isFragmentOrTableChild,
      argFromLooper,
      language,
      nodeListDSL,
      args?.arg,
      iterableNodeListDSL,
      schema,
      connectedStatesDSL,
      resourceListDSL,
      assetFileWithBackendDataListDSL,
      authResourceDSL,
      userAppStates,
      libraryListDSLArray,
      event,
      isAutocompleteNode,
      isFormAutocompleteNode,
      isRichtextEditorNode,
    ],
  );

  return (
    <EditorFormDialog
      isDialogOpened={isOpen}
      onDialogClose={onDialogClose}
      title={args.label}
      formProps={{
        onSubmit: submitForm,
        initialValues: getEditorInitialValue(args.initialValues),
      }}
      args={args.arg}
      argFromLooper={argFromLooper}
    >
      {({ errors }) => {
        return (
          <>
            {errors.code && <ErrorText>{errors.code}</ErrorText>}
            <Field name="code" useValueOnChange>
              {({ field }) => (
                <MonacoEditor
                  height="100%"
                  language={language}
                  defaultValue={field.value as string}
                  defaultPath={`code-editor-dialog/${args?.nodeID || ''}index.ts`}
                  onChange={onFieldChange(field)}
                  beforeMount={initMonaco}
                  withBorders={false}
                  options={args.options}
                  data-test={`${args.dataTest}.dialog`}
                />
              )}
            </Field>
          </>
        );
      }}
    </EditorFormDialog>
  );
};
