import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { commonComponentProps } from '../../common/commonProps';
export const MaterialDialog = {
    name: COMPONENT_DSL_NAMES.MaterialDialog,
    displayName: 'Dialog',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@mui/material',
        componentName: 'Dialog',
    },
    schema: {
        dndTargetPropName: 'children',
        props: Object.assign(Object.assign({}, commonComponentProps), { 'aria-describedby': { type: COMPONENT_DSL_PROP_TYPES.string }, 'aria-labelledby': { type: COMPONENT_DSL_PROP_TYPES.string }, disableEscapeKeyDown: { type: COMPONENT_DSL_PROP_TYPES.boolean }, fullScreen: { type: COMPONENT_DSL_PROP_TYPES.boolean }, fullWidth: { type: COMPONENT_DSL_PROP_TYPES.boolean }, maxWidth: { type: COMPONENT_DSL_PROP_TYPES.enum }, onBackdropClick: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            }, onClose: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            }, open: { type: COMPONENT_DSL_PROP_TYPES.boolean, required: false }, PaperComponent: { type: COMPONENT_DSL_PROP_TYPES.elementType }, PaperProps: { type: COMPONENT_DSL_PROP_TYPES.object }, scroll: { type: COMPONENT_DSL_PROP_TYPES.enum }, TransitionComponent: { type: COMPONENT_DSL_PROP_TYPES.elementType }, transitionDuration: { type: COMPONENT_DSL_PROP_TYPES.object }, TransitionProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: {
                    onEnter: {
                        type: COMPONENT_DSL_PROP_TYPES.action,
                        body: COMPONENT_DSL_CALLBACK_TYPES.code,
                        args: [],
                    },
                    onEntered: {
                        type: COMPONENT_DSL_PROP_TYPES.action,
                        body: COMPONENT_DSL_CALLBACK_TYPES.code,
                        args: [],
                    },
                    onEntering: {
                        type: COMPONENT_DSL_PROP_TYPES.action,
                        body: COMPONENT_DSL_CALLBACK_TYPES.code,
                        args: [],
                    },
                    onEscapeKeyDown: {
                        type: COMPONENT_DSL_PROP_TYPES.action,
                        body: COMPONENT_DSL_CALLBACK_TYPES.code,
                        args: [],
                    },
                    onExit: {
                        type: COMPONENT_DSL_PROP_TYPES.action,
                        body: COMPONENT_DSL_CALLBACK_TYPES.code,
                        args: [],
                    },
                    onExited: {
                        type: COMPONENT_DSL_PROP_TYPES.action,
                        body: COMPONENT_DSL_CALLBACK_TYPES.code,
                        args: [],
                    },
                    onExiting: {
                        type: COMPONENT_DSL_PROP_TYPES.action,
                        body: COMPONENT_DSL_CALLBACK_TYPES.code,
                        args: [],
                    },
                },
            } }),
    },
    settings: null,
};
