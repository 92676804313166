/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types, no-new-func */
import memoize from 'fast-memoize';
import { isEmpty } from 'ramda';

import { AppDSL } from '@builder/schemas';
import { serialize, getFunctionDeps } from '@builder/utils';

import { AppRuntimeArrayState } from '../app-state';
import { UI_BUILDER_MODES } from '../providers/DashboardProvider';
import { AppRuntimeStateList } from '../types';

import { createFunction, throttledLogWarn } from './createFunction';
import { isRenderMoreHooksError } from './isRenderMoreHooksError';

export type CreateAndRunFunctionOptions = {
  throwReactRenderError?: boolean;
  onAppFunctionError?: (error: Error) => void;
  libraries?: AppDSL['libraries'];
  isNewFunction?: boolean;
};

export const createAndRunFunction = <T extends unknown = Function>(
  functionCode: string,
  appRuntimeStateList: AppRuntimeStateList,
  options?: CreateAndRunFunctionOptions,
): T | void => {
  const librariesUsedInCode = Object.values(options?.libraries || {}).filter(lib =>
    functionCode.includes(lib.alias),
  );

  const { uiBuilderMode } = window as any;

  try {
    const {
      predefinedState,
      globalState: appState,
      localState,
      temporaryState,
      localStates,
    } = appRuntimeStateList;
    const state = {
      ...predefinedState,
      ...appState,
      ...localState,
      ...temporaryState,
      ...localStates,
    };

    const func = createFunction(functionCode, state, librariesUsedInCode, !!options?.isNewFunction);
    return func();
  } catch (err) {
    throttledLogWarn(
      `\n\n_____Runtime Function Error_____\n\n\n`,
      err,
      `_____Function Code___`,
      functionCode,
    );
    options?.onAppFunctionError?.(err as Error);

    if (uiBuilderMode === UI_BUILDER_MODES.preview && (err as Error).name !== 'ReferenceError') {
      appRuntimeStateList.predefinedState.history.push('/500');
      const errorState = appRuntimeStateList.globalState.uncaughtErrors as AppRuntimeArrayState;

      if (!isEmpty((errorState as any).value[0].error)) {
        errorState.setValue([
          ...errorState.value,
          {
            message: 'Runtime Function Error',
            error: err,
            code: functionCode,
          },
        ]);
      } else {
        errorState.setValue([
          {
            message: 'Runtime Function Error',
            error: err,
            code: functionCode,
          },
        ]);
      }
    }

    // if hook is added then the need to catch this error on the top of AppEngine anr remount it
    if (options?.throwReactRenderError && isRenderMoreHooksError((err as Error).message)) {
      throw err;
    }
  }
};

export const createAndRunFunctionWithMemo = memoize(createAndRunFunction, {
  serializer: ([functionCode, appRuntimeStateList]) => {
    const { appState, localState = {} } = appRuntimeStateList;
    const states = { ...appState, localState };
    const stateNames = Object.keys(states);
    const functionDepNames = getFunctionDeps(functionCode, stateNames);

    const functionDeps = Object.keys(states).reduce((accum, stateName) => {
      if (functionDepNames.includes(stateName)) {
        return { ...accum, [stateName]: states[stateName] };
      }

      return accum;
    }, {});

    return functionCode + serialize.stringify(functionDeps, { removeComplexObjects: true });
  },
});
