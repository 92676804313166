var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { forwardRef, useState, useRef, useCallback, } from 'react';
import { FormControl, FormHelperText, FormControlLabel, Slider as MaterialSlider, } from '@mui/material';
import debounce from 'lodash.debounce';
import { isNil } from 'ramda';
import { useEffectDeep } from '@builder/utils';
import { fieldValidation } from '../common';
// why should we use such a weird styles there?
// by default Material-UI has negative marginLeft value and this component falls out its parent
// on the left side if it has the prop "size" equals to "small"
// so to avoid such weird behaviour we set marginLeft equals to "-6px"
const SMALL_SIZE_STYLES = {
    marginLeft: '-6px',
};
function createObjectFromCssString(stringValue) {
    const separator = (stringValue === null || stringValue === void 0 ? void 0 : stringValue.includes(';')) ? ';' : ',';
    let finalObject = {};
    if (stringValue)
        stringValue
            .slice(1, -1)
            .toString()
            .split(separator)
            // eslint-disable-next-line array-callback-return
            .map((stringElement) => {
            var _a, _b;
            const key = (_a = stringElement.split(':')[0]) === null || _a === void 0 ? void 0 : _a.replace(/\s+/g, '');
            const sxValue = (_b = stringElement.split(':')[1]) === null || _b === void 0 ? void 0 : _b.replace(/'|\s+/g, '');
            finalObject = Object.assign(Object.assign({}, finalObject), { [key]: sxValue });
        });
    return finalObject;
}
function preventHorizontalKeyboardNavigation(event) {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        event.preventDefault();
    }
}
export const Slider = forwardRef((_a, ref) => {
    var { debounce: debounceTime, id, title, translate, disabled, className, style, sx, css, error, helperText, size, name, lang, color, value, defaultValue, min, max, arialabel, arialabelledby, ariavaluetext, classes, valueLabelDisplay, disableSwap, track, marks, step, orientation, getAriaLabel, getAriaValueText, tabIndex, onChange, valueLabelFormat, scale, validation, regularExpression, label: initLabel = '', labelPlacement = 'end', 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath } = _a, otherSliderProps = __rest(_a, ["debounce", "id", "title", "translate", "disabled", "className", "style", "sx", "css", "error", "helperText", "size", "name", "lang", "color", "value", "defaultValue", "min", "max", "arialabel", "arialabelledby", "ariavaluetext", "classes", "valueLabelDisplay", "disableSwap", "track", "marks", "step", "orientation", "getAriaLabel", "getAriaValueText", "tabIndex", "onChange", "valueLabelFormat", "scale", "validation", "regularExpression", "label", "labelPlacement", 'data-test', 'data-node-id', 'data-node-render-path']);
    const label = Array.isArray(initLabel) ? initLabel[0] : initLabel;
    const [localValue, setLocalValue] = useState(value);
    const [isFirstLoad, setIsFirstLoad] = useState(false);
    const [errorType, setErrorType] = useState(false);
    useEffectDeep(() => {
        if (defaultValue && !isFirstLoad) {
            setIsFirstLoad(true);
            onChange === null || onChange === void 0 ? void 0 : onChange({
                target: {
                    value: Number(defaultValue),
                },
            }, defaultValue, 0);
        }
    }, [setIsFirstLoad, onChange, defaultValue, isFirstLoad]);
    useEffectDeep(() => {
        setLocalValue(value);
    }, [value, setLocalValue]);
    const createDebounceCallback = useCallback((debounceTimeArg) => {
        return debounce((event, nextValue) => {
            // eslint-disable-next-line no-param-reassign
            event.target.value = nextValue;
            onChange === null || onChange === void 0 ? void 0 : onChange(event, event.target.value, event.target.value);
        }, debounceTimeArg);
    }, [onChange]);
    const debouncedChangePropValue = useRef(createDebounceCallback(debounceTime));
    const setDebouncedValue = (event) => {
        const sliderValue = event.target.value;
        setLocalValue(sliderValue);
        if (isNil(debounceTime)) {
            onChange === null || onChange === void 0 ? void 0 : onChange(event, sliderValue, sliderValue);
        }
        else {
            debouncedChangePropValue.current(event, event.target.value);
        }
    };
    const handleValidation = (event) => {
        const validate = fieldValidation(event.target.value, validation, regularExpression, undefined);
        setErrorType(!!validate);
    };
    const sxObject = createObjectFromCssString(sx);
    // eslint-disable-next-line no-new-func
    const formatLabel = new Function('value', valueLabelFormat);
    return (_jsxs(FormControl, Object.assign({ size: size, sx: { width: 200, marginLeft: '20px' }, disabled: disabled, className: className, style: style, css: css, ref: ref, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, title: title, id: id, lang: lang, translate: translate }, { children: [_jsx(FormControlLabel, { disabled: disabled, label: label, labelPlacement: labelPlacement, disableTypography: true, sx: size === 'small' ? SMALL_SIZE_STYLES : undefined, control: _jsx(MaterialSlider, Object.assign({}, otherSliderProps, { size: size, value: localValue, min: min, max: max, onChange: event => {
                        handleValidation(event);
                        setDebouncedValue(event);
                    }, scale: actualValue => actualValue * Number(scale || 1), "arial-label": arialabel, "aria-labelledby": arialabelledby, "aria-valuetext": ariavaluetext, color: color, marks: marks, classes: classes, valueLabelDisplay: valueLabelDisplay, track: track !== 'normal' && track !== 'inverted' ? false : track, step: parseInt(step, 10), tabIndex: parseInt(tabIndex, 10) || 0, name: name, sx: Object.assign(Object.assign({}, sxObject), { '& input[type="range"]': {
                            WebkitAppearance: 'slider-vertical',
                        } }), disableSwap: disableSwap, orientation: orientation, getAriaLabel: () => getAriaLabel, valueLabelFormat: valueLabelFormat
                        ? valueToFormat => formatLabel(valueToFormat)
                        : valueToFormat => `${valueToFormat}`, getAriaValueText: labelvalue => getAriaValueText ? `${labelvalue}${getAriaValueText}` : ariavaluetext || '', onKeyDown: preventHorizontalKeyboardNavigation })) }), helperText && _jsx(FormHelperText, Object.assign({ error: errorType || error }, { children: helperText }))] })));
});
