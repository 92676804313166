import { useLocation } from 'react-router-dom';

import { getWorkspaceIDFromPathname } from '../utils/routing';

type UseCurrentWorkspaceIDReturn = {
  workspaceID: string | null;
};

export const useCurrentWorkspaceID = (): UseCurrentWorkspaceIDReturn => {
  const location = useLocation();
  const workspaceID = getWorkspaceIDFromPathname(location.pathname);

  return { workspaceID: workspaceID || null };
};
