/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'react';
import debounce from 'lodash.debounce';
import { has } from 'ramda';
import { ROOT_NODE_ID, createRenderPathData, } from '@builder/schemas';
import { serialize } from '@builder/utils';
import { NodeDSLToReactRenderer } from './NodeDSLToReactRenderer';
import { NodeDSLToReactTransformer } from './NodeDSLToReactTransformer';
/**
 * Recursively generates react nodes from dsl nodes.
 */
export class AppNodeGenerator extends Component {
    constructor() {
        super(...arguments);
        /**
         * Function calls to pass state to dashboard
         * Debounce needs to don't to call it each time on every node.
         */
        this.onRuntimeStateReportEffect = debounce(({ localStateList, presentationList, }) => {
            const { onRuntimeStateReport, appRuntimeState } = this.props;
            try {
                onRuntimeStateReport({
                    states: {
                        globalState: serialize.flatten(appRuntimeState.globalState),
                        predefinedState: serialize.flatten({
                            resources: appRuntimeState.predefinedState.resources,
                            theme: appRuntimeState.predefinedState.theme,
                            history: appRuntimeState.predefinedState.history,
                            location: appRuntimeState.predefinedState.location,
                            auth: appRuntimeState.predefinedState.auth,
                            apolloClient: appRuntimeState.predefinedState.apolloClient,
                            roles: appRuntimeState.predefinedState.roles,
                            router: appRuntimeState.predefinedState.router,
                            assets: appRuntimeState.predefinedState.assets,
                            requestRunner: appRuntimeState.predefinedState.requestRunner,
                        }),
                        localStateList: serialize.flatten(localStateList),
                        localStates: serialize.flatten(appRuntimeState.localStates),
                        presentationList,
                    },
                });
            }
            catch (e) {
                console.warn('onRuntimeStateReport failed!');
                console.error(e);
            }
        }, 30);
    }
    render() {
        const { isPreviewMode, appRuntimeState, libraries, nodeListDSL, appStates, appSettings, componentListDSL, resourceListDSL, assetListDSL, skipPresentations, onAppAuditNotifications, renderReactComponent, presentationStates, } = this.props;
        const nodeDSLToReactTransformer = new NodeDSLToReactTransformer({
            isPreviewMode,
            nodeListDSL,
            appStates,
            appSettings,
            componentListDSL,
            resourceListDSL,
            assetListDSL,
            skipPresentations,
            onAppAuditNotifications,
            libraries,
            presentationStates,
        });
        const nodeDSLToReactRenderer = new NodeDSLToReactRenderer({
            isPreviewMode,
            nodeListDSL,
            appStates,
            componentListDSL,
            resourceListDSL,
            assetListDSL,
            renderReactComponent,
            onAppAuditNotifications,
            onLocalStateUpdate: this.onRuntimeStateReportEffect,
            presentationStates,
        }, nodeDSLToReactTransformer, libraries);
        nodeDSLToReactRenderer.init();
        const nodeTree = nodeDSLToReactRenderer.generateReactNode(nodeListDSL.root, Object.assign({}, appRuntimeState), createRenderPathData({
            nodeID: ROOT_NODE_ID,
            parentRenderPath: null,
        }));
        const hasPendoUserMetadata = has('pendoUserMetadata');
        if (hasPendoUserMetadata(window.top)) {
            window.pendo.initialize(window.top.pendoUserMetadata);
            delete window.top.pendoUserMetadata;
        }
        return nodeTree;
    }
}
