import { pipe } from 'ramda';
import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { assocSettings, NS_ID, omitSettings } from '../../common';
import { getCommonFormFieldPropertiesSettings, getCommonFormFieldActionSettings, } from '../../common/settings';
import { getBuilderComponentsSelectSettings } from '../../material-components/BuilderComponentsSelect/BuilderComponentsSelect.settings';
const basicTextFieldSettings = getBuilderComponentsSelectSettings(['selectProps']);
export const BuilderComponentsFormSelectSettings = {
    properties: pipe(assocSettings([NS_ID.properties.root, NS_ID.properties.basic], settings => (Object.assign(Object.assign({}, settings), { children: [
            ...getCommonFormFieldPropertiesSettings(),
            {
                componentView: COMPONENT_SETTING_VIEWS.text,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['value'],
                label: 'Value',
            },
            ...settings.children,
        ] }))), omitSettings([[NS_ID.properties.root, NS_ID.properties.basic, 'value']]))(basicTextFieldSettings.properties),
    style: basicTextFieldSettings.style,
    events: pipe(assocSettings([NS_ID.events.root, NS_ID.events.events], settings => (Object.assign(Object.assign({}, settings), { children: [...getCommonFormFieldActionSettings(), ...settings.children] }))))(basicTextFieldSettings.events),
};
