var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef, useMemo } from 'react';
import { css as emotionCSS } from '@emotion/react';
import ReactHtmlParser from 'react-html-parser';
export const RichText = forwardRef((_a, ref) => {
    // we need to enforce overflow to force `div` to include inner margins,
    // otherwise those will collapse by default & we wont be able to size overlays accurately
    var { style = {}, id, title, className, lang, translate, content, css } = _a, rest = __rest(_a, ["style", "id", "title", "className", "lang", "translate", "content", "css"]);
    const baseStyles = useMemo(() => {
        return emotionCSS `
        .editor-nested-listitem {
          list-style-type: none;
        }
        .editor-lista__ol1 {
          list-style-position: inside;
        }
        .editor-lista__ol2 {
          list-style-type: upper-alpha;
          list-style-position: inside;
        }
        .editor-lista__ol3 {
          list-style-type: lower-alpha;
          list-style-position: inside;
        }
        .editor-lista__ol4 {
          list-style-type: upper-roman;
          list-style-position: inside;
        }
        .editor-lista__ol5 {
          list-style-type: lower-roman;
          list-style-position: inside;
        }
      `;
    }, []);
    return (_jsx("div", Object.assign({ ref: ref }, rest, { style: Object.assign(Object.assign({}, style), { overflow: 'auto' }), css: [baseStyles, css], id: id, title: title, className: className, lang: lang, translate: translate }, { children: content ? ReactHtmlParser(content) : null })));
});
