import {
  AppEvents,
  DashboardState,
  DASHBOARD_EVENTS,
  getBaseDashboardState,
} from '../../../common';
import { dashboardInitial } from '../../initial-state';

export const dashboardUndoRedoReducer = (
  state: DashboardState = dashboardInitial,
  event: AppEvents,
): DashboardState => {
  const newPast = [...state.history.past];
  const pastToPresent = newPast.shift();

  switch (event.type) {
    case DASHBOARD_EVENTS.dashboardUndo:
      if (!pastToPresent) {
        return state;
      }

      if (event.completely) {
        return {
          ...pastToPresent,
          history: {
            ...state.history,
            past: newPast,
            future: [],
          },
        };
      }

      return {
        ...pastToPresent,
        history: {
          ...state.history,
          past: newPast,
          future: [getBaseDashboardState(state), ...state.history.future],
        },
      };

    case DASHBOARD_EVENTS.dashboardRedo: {
      const newFuture = [...state.history.future];
      const futureToPresent = newFuture.shift();
      if (!futureToPresent) {
        return state;
      }

      return {
        ...futureToPresent,
        history: {
          ...state.history,
          future: newFuture,
          past: [getBaseDashboardState(state), ...state.history.past],
        },
      };
    }

    default:
      return state;
  }
};
