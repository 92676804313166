import { pipe } from 'ramda';
import { COMPONENT_DSL_NAMES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { assocSettings, BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, getBasicPropsSettings, NS_ID, pickSettings, } from '../../common';
import { getCommonActionListSettings, getCommonActionSettings, getDivider, getPropertySections, getStyleSections, getTypographySection, } from '../../common/settings';
export const getBuilderComponentsSelectSettings = (prefixPath = []) => ({
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'label'],
                            label: 'Label',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'value'],
                            label: 'Value',
                            name: 'value',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'disabled'],
                            label: 'Disabled',
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            keyPath: [...prefixPath, 'children'],
                            label: 'Menu Item',
                            name: 'selectItems',
                            required: true,
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialMenuItem,
                                    settings: [
                                        {
                                            componentView: COMPONENT_SETTING_VIEWS.text,
                                            type: COMPONENT_SETTING_TYPES.prop,
                                            keyPath: ['value'],
                                            label: 'Value',
                                        },
                                        {
                                            type: COMPONENT_SETTING_TYPES.nodeText,
                                            keyPath: ['children'],
                                            label: 'label',
                                        },
                                        {
                                            type: COMPONENT_SETTING_TYPES.iterator,
                                            dataLabel: 'Loop Data',
                                            itemNameLabel: 'Item Name',
                                            name: 'loop',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.style.basic,
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'variant'],
                            options: ['filled', 'outlined', 'standard'].map(createSelectOptionByValue),
                            label: 'Variant',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'size'],
                            label: 'Size',
                            options: ['small', 'medium'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'autoWidth'],
                            label: 'Auto Width',
                        },
                    ],
                },
                getDivider(),
                ...pipe(assocSettings([NS_ID.style.size.root, NS_ID.style.size.height], setting => (Object.assign(Object.assign({}, setting), { keyPath: [...prefixPath, 'style', 'height'] }))), assocSettings([NS_ID.style.font.root], () => getTypographySection(prefixPath)), assocSettings([NS_ID.style.spacing.root], setting => (Object.assign(Object.assign({}, setting), { disablePaddings: true }))), pickSettings([
                    [NS_ID.style.display.root],
                    [NS_ID.style.spacing.root],
                    [NS_ID.style.size.root, NS_ID.style.size.width],
                    [NS_ID.style.size.root, NS_ID.style.size.height],
                    [NS_ID.style.font.root, NS_ID.style.font.color],
                    [NS_ID.style.font.root, NS_ID.style.font.fontFamily],
                    [NS_ID.style.font.root, NS_ID.style.font.fontSize],
                    [NS_ID.style.font.root, NS_ID.style.font.fontStyle],
                    [NS_ID.style.font.root, NS_ID.style.font.fontWeight],
                    [NS_ID.style.font.root, NS_ID.style.font.textAlign],
                    [NS_ID.style.font.root, NS_ID.style.font.textDecoration],
                    [NS_ID.style.font.root, NS_ID.style.font.textTransform],
                    [NS_ID.style.border.root],
                    [NS_ID.style.background.root],
                    [NS_ID.style.css.root],
                ]))(getStyleSections(prefixPath)),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onChangeAction',
                            label: 'On Change',
                            keyPath: [...prefixPath, 'onChange'],
                            children: getCommonActionSettings([...prefixPath, 'onChange']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onOpenAction',
                            label: 'On Open',
                            keyPath: [...prefixPath, 'onOpen'],
                            children: getCommonActionSettings([...prefixPath, 'onOpen']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onCloseAction',
                            label: 'On Close',
                            keyPath: [...prefixPath, 'onClose'],
                            children: getCommonActionSettings([...prefixPath, 'onClose']),
                        },
                        ...getCommonActionListSettings(prefixPath),
                    ],
                },
            ],
        },
    ],
});
export const BuilderComponentsSelectSettings = getBuilderComponentsSelectSettings();
