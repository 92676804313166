import { useCallback } from 'react';
import { omit } from 'ramda';
import { useLocation, matchPath, useHistory } from 'react-router-dom';
import { nodeListSelectors } from '@builder/schemas';
import { getCurrentRouteSearchParams } from '../../utils/getCurrentRouteFragments';
import { getCurrentRouteParams } from '../../utils/getCurrentRouteParams';
function camelize(str) {
    return (str
        // eslint-disable-next-line func-names
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
        .replace(/\s+/g, ''));
}
const getRouteName = (name) => camelize(name.replace('Wrapper', ''));
const DEFAULT_ROUTE = {
    path: '',
    title: '',
    exact: false,
    strict: false,
    params: {},
    fragment: {},
    meta: {},
    name: '',
};
export const useRoutes = (nodeListDSL) => {
    const location = useLocation();
    const history = useHistory();
    const routesList = nodeListSelectors.getAllRouteNodes(nodeListDSL).map(routeNodeDSL => ({
        path: routeNodeDSL.props.path,
        title: routeNodeDSL.props.title,
        exact: routeNodeDSL.props.exact,
        strict: routeNodeDSL.props.strict,
        // eslint-disable-next-line no-underscore-dangle
        meta: routeNodeDSL.props.__meta || {},
        name: getRouteName(routeNodeDSL.alias),
        fragment: {},
        queryString: {},
        // eslint-disable-next-line no-underscore-dangle
        params: routeNodeDSL.props.__defaultPathParams,
    }));
    const currentRoute = routesList.find(route => matchPath(location.pathname, { path: route.path, exact: true })) ||
        DEFAULT_ROUTE;
    const currentParams = getCurrentRouteParams(currentRoute.path, location.pathname);
    const currentFragments = getCurrentRouteSearchParams(window.location.hash ? window.location.hash : location.hash);
    const urlSearchQueryString = new URLSearchParams(location.search);
    const urlSearchQueryStringParams = Object.fromEntries(urlSearchQueryString.entries());
    const currentRouteWithParams = Object.assign(Object.assign({}, currentRoute), { params: currentParams, fragment: currentFragments, queryString: urlSearchQueryStringParams });
    const pages = routesList.reduce((accum, currentValue) => {
        return Object.assign(Object.assign({}, accum), { [currentValue.name]: omit(['params', 'fragment'], currentValue) });
    }, {});
    const pagesList = routesList.map(route => omit(['params', 'fragment'], route));
    const navigate = useCallback((path, params) => {
        const routePath = path instanceof Object ? path.path : path;
        const paramsKeys = Object.keys(params || {});
        const { nextPath, query } = paramsKeys.reduce((accum, key) => {
            const isParamInPath = accum.nextPath.includes(`/:${key}`);
            const paramValue = (params === null || params === void 0 ? void 0 : params[key]) || '';
            const next = accum.nextPath.replace(`:${key}`, paramValue);
            const paramsArray = [...accum.query];
            if (!isParamInPath) {
                paramsArray.push(`${key}=${paramValue}`);
            }
            return {
                nextPath: next,
                query: paramsArray,
            };
        }, { nextPath: routePath, query: [] });
        const pathToPush = query.length ? `${nextPath}?${query.join('&&')}` : nextPath;
        history.push(pathToPush);
    }, [history]);
    return Object.assign(Object.assign({ list: pagesList }, pages), { currentRoute: currentRouteWithParams, navigate });
};
