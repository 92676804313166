import { pipe } from 'ramda';
import { COMPONENT_SETTING_VIEWS, COMPONENT_SETTING_TYPES } from '../../../constants';
import { BASIC_PROPS_TOOLTIP, NS_ID, pickSettings } from '../../common';
import { getCommonActionSettings, getDivider, getPropertySections, getStyleSections, } from '../../common/settings';
const DIALOG_PROPS_PREFIX_PATH = ['dialogProps'];
const DIALOG_TITLE_PROPS_PREFIX_PATH = ['titleProps'];
export const DialogSymbolSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: 'basic',
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.textArea,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...DIALOG_TITLE_PROPS_PREFIX_PATH, 'title'],
                            label: 'Title',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...DIALOG_PROPS_PREFIX_PATH, 'open'],
                            label: 'Open',
                            required: false,
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...DIALOG_PROPS_PREFIX_PATH, 'maxWidth'],
                            label: 'Max Width',
                            options: [
                                { value: 'xs', label: 'xs' },
                                { value: 'sm', label: 'sm' },
                                { value: 'md', label: 'md' },
                                { value: 'lg', label: 'lg' },
                                { value: 'xl', label: 'xl' },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...DIALOG_PROPS_PREFIX_PATH, 'fullWidth'],
                            label: 'Full Width',
                            required: false,
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...DIALOG_PROPS_PREFIX_PATH, 'fullScreen'],
                            label: 'Full Screen',
                            required: false,
                        },
                    ],
                },
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: pipe(pickSettings([[NS_ID.style.css.root]]))(getStyleSections()),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: 'events',
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onCloseAction',
                            label: 'On Close',
                            keyPath: [...DIALOG_PROPS_PREFIX_PATH, 'onClose'],
                            children: getCommonActionSettings([...DIALOG_PROPS_PREFIX_PATH, 'onClose']),
                        },
                    ],
                },
            ],
        },
    ],
};
