import React from 'react';

import styled from '@emotion/styled';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DoneIcon from '@mui/icons-material/Done';
import { IconProps } from '@mui/material';

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const getResizedIcon = (Icon: React.FC<IconProps>, size: number): React.FC<IconProps> => styled(
  Icon,
)`
  width: ${size}px !important;
  height: ${size}px !important;
  font-size: ${size}px !important;
  line-height: ${size}px !important;
`;

export const StyledDoneIcon = getResizedIcon(DoneIcon as React.FC<IconProps>, 60);
export const StyledCancelOutlinedIcon = getResizedIcon(
  CancelOutlinedIcon as React.FC<IconProps>,
  70,
);
