var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { assetListSelectors, DEFAULT_HTML_TITLE, DEFAULT_META_TAG_NAME, stateListSelectors, hasPropJsCode, } from '@builder/schemas';
import { AppNodeGenerator } from '../app-nodes/node-generator';
import { useRuntimeErrorCallbacks } from '../providers';
import { UI_BUILDER_MODES, useDashboardHook } from '../providers/DashboardProvider';
import { RouteHookProvider } from '../providers/RouterHookProvider';
import { useTransformValue, verifyUniqStateNames } from '../utils';
import { sortStatesByTimesUsed } from '../utils/sortStatesByTimesUsed';
import { useAppEnginePredefinedHooks } from './predefined-hooks';
import { useGlobalAndLocalStates } from './useAppEngineStates';
const useAppRuntimeState = ({ appDSL, componentListDSL, assetBackendList, onAppAuditNotifications, }) => {
    const predefinedState = useAppEnginePredefinedHooks({
        appDSL,
        componentListDSL,
        assetBackendList,
    });
    const resourceListDSL = appDSL.resources;
    const assetListDSL = useMemo(() => appDSL.assets || {}, [appDSL.assets]);
    const assetFileList = useMemo(() => assetListSelectors.getAssetFileWithBackendDataList(assetListDSL, {
        assetBackendList,
    }), [assetBackendList, assetListDSL]);
    const sortStatesByUse = sortStatesByTimesUsed(appDSL, onAppAuditNotifications);
    const globalStateList = stateListSelectors.getGlobalStateArrayDSL(sortStatesByUse);
    const localStateList = stateListSelectors.getLocalStateArrayDSL(sortStatesByUse);
    verifyUniqStateNames({
        globalStateList,
        localStateList,
        nodeList: appDSL.nodes,
        componentListDSL,
        currentRoute: predefinedState.router.currentRoute.path,
        onAppAuditNotifications,
    });
    const { globalState, localStates } = useGlobalAndLocalStates({
        globalStateList,
        localStateList,
        componentListDSL,
        resourceListDSL,
        assetListDSL: assetFileList,
        appRuntimeState: {
            predefinedState,
            globalState: {},
            localStates: {},
        },
        libraries: appDSL.libraries,
    });
    const state = { predefinedState, globalState, localStates };
    const { router, resources } = predefinedState, predefinedHooksState = __rest(predefinedState, ["router", "resources"]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.appRuntimeState = state;
    const transformer = useTransformValue(state, appDSL.libraries);
    return {
        predefinedState: Object.assign(Object.assign({}, predefinedHooksState), { router: transformer(router), resources: transformer(resources) }),
        globalState: transformer(globalState),
        localStates,
    };
};
/**
 * Executes all DSL states and pass them to the nodes generator.
 */
export const AppEngineStates = ({ currentRoute, appDSL, assetBackendList, componentListDSL, skipPresentations, renderReactComponent, onRuntimeStateReport, presentationStates, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { mode } = useDashboardHook();
    const { onAppAuditNotifications } = useRuntimeErrorCallbacks();
    const appRuntimeState = useAppRuntimeState({
        appDSL,
        componentListDSL,
        assetBackendList,
        onAppAuditNotifications,
        currentRoute,
    });
    const resourceListDSL = appDSL.resources;
    const assetListDSL = useMemo(() => appDSL.assets || {}, [appDSL.assets]);
    const assetFileList = useMemo(() => assetListSelectors.getAssetFileWithBackendDataList(assetListDSL, {
        assetBackendList,
    }), [assetBackendList, assetListDSL]);
    const isPreviewMode = mode === UI_BUILDER_MODES.preview;
    window.appRuntimeState = appRuntimeState;
    const showDefaultMetaTagCharset = useMemo(() => {
        var _a;
        return !(((_a = appDSL === null || appDSL === void 0 ? void 0 : appDSL.settings) === null || _a === void 0 ? void 0 : _a.htmlDefaultMetaTags) || []).some(metaTag => metaTag.name === DEFAULT_META_TAG_NAME.charset);
    }, [(_a = appDSL === null || appDSL === void 0 ? void 0 : appDSL.settings) === null || _a === void 0 ? void 0 : _a.htmlDefaultMetaTags]);
    const showDefaultMetaTagViewport = useMemo(() => {
        var _a;
        return !(((_a = appDSL === null || appDSL === void 0 ? void 0 : appDSL.settings) === null || _a === void 0 ? void 0 : _a.htmlDefaultMetaTags) || []).some(metaTag => metaTag.name === DEFAULT_META_TAG_NAME.viewport);
    }, [(_b = appDSL === null || appDSL === void 0 ? void 0 : appDSL.settings) === null || _b === void 0 ? void 0 : _b.htmlDefaultMetaTags]);
    const transformer = useTransformValue(appRuntimeState, appDSL.libraries);
    return (_jsx(RouteHookProvider, Object.assign({ globalHooks: appDSL.routeHooks, nodeListDSL: appDSL.nodes, currentRoute: currentRoute, stateList: appDSL.states, libraries: appDSL.libraries, appRuntimeState: appRuntimeState }, { children: _jsxs(_Fragment, { children: [_jsxs(Helmet, { children: [_jsx("title", { children: hasPropJsCode((_c = appDSL === null || appDSL === void 0 ? void 0 : appDSL.settings) === null || _c === void 0 ? void 0 : _c.htmlDefaultTitle) && ((_d = appDSL === null || appDSL === void 0 ? void 0 : appDSL.settings) === null || _d === void 0 ? void 0 : _d.htmlDefaultTitle)
                                ? transformer((_e = appDSL === null || appDSL === void 0 ? void 0 : appDSL.settings) === null || _e === void 0 ? void 0 : _e.htmlDefaultTitle)
                                : ((_f = appDSL === null || appDSL === void 0 ? void 0 : appDSL.settings) === null || _f === void 0 ? void 0 : _f.htmlDefaultTitle) || DEFAULT_HTML_TITLE }), (((_g = appDSL === null || appDSL === void 0 ? void 0 : appDSL.settings) === null || _g === void 0 ? void 0 : _g.htmlDefaultMetaTags) || []).map(metaTag => {
                            switch (metaTag.name) {
                                case DEFAULT_META_TAG_NAME.charset: {
                                    return _jsx("meta", { charSet: metaTag.content });
                                }
                                default: {
                                    return _jsx("meta", { name: metaTag.name, content: metaTag.content });
                                }
                            }
                        }), showDefaultMetaTagCharset && _jsx("meta", { charSet: "UTF-8" }), showDefaultMetaTagViewport && (_jsx("meta", { name: "viewport", content: "minimum-scale=1, initial-scale=1, width=device-width" }))] }), _jsx(AppNodeGenerator, { isPreviewMode: isPreviewMode, presentationStates: presentationStates, nodeListDSL: appDSL.nodes, libraries: appDSL.libraries, appStates: appDSL.states, appSettings: appDSL.settings, componentListDSL: componentListDSL, resourceListDSL: resourceListDSL, assetListDSL: assetFileList, appRuntimeState: appRuntimeState, skipPresentations: skipPresentations, renderReactComponent: renderReactComponent, onAppAuditNotifications: onAppAuditNotifications, onRuntimeStateReport: onRuntimeStateReport })] }) })));
};
