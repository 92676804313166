import { combineReducers } from 'redux';

import {
  getBaseDashboardState,
  DASHBOARD_EVENTS,
  DASHBOARD_ANIM_EVENTS,
  AppEvents,
  DashboardState,
  BaseDashboardState,
  ALL_POSSIBLE_EVENTS,
  UI_BUILDER_EVENTS,
} from '../../common';
import { dashboardInitial } from '../initial-state';
import { shallowEqualNaive } from 'src/shared/utils/shallowEqual';

import { appConfigurationReducer, layoutReducer, themeManagerReducer } from './basic';
import { dashboardComplexReducer } from './dashboardComplexReducer';
import { dashboardCompleteUpdateRestoreReducer } from './edge/dashboardCompleteUpdateRestoreReducer';
import { dashboardOperationsReducer } from './edge/dashboardOperationsReducer';
import { dashboardUndoRedoReducer } from './edge/dashboardUndoRedoReducer';

// arbitrary history window
const HISTORY_LIMIT = 50;
const BLACKLIST_HISTORY_ACTIONS: AppEvents['type'][] = [
  DASHBOARD_EVENTS.appConfigurationInit,
  DASHBOARD_EVENTS.dashboardRedo,
  DASHBOARD_EVENTS.dashboardUndo,
  DASHBOARD_EVENTS.dashboardClearSelection,
  DASHBOARD_EVENTS.dashboardIFrameRefresh,
  DASHBOARD_EVENTS.dashboardSetLoadingConfiguration,
  DASHBOARD_EVENTS.userAppResize,
  DASHBOARD_EVENTS.componentToggleSelect,
  DASHBOARD_EVENTS.componentSelect,
  DASHBOARD_EVENTS.componentToggleSelectByID,
  DASHBOARD_EVENTS.componentSelectedRemove,
  DASHBOARD_EVENTS.componentDeselect,
  DASHBOARD_EVENTS.componentToggleFocus,
  DASHBOARD_EVENTS.updateNodeMeasures,
  DASHBOARD_EVENTS.stateReport,
  DASHBOARD_EVENTS.routerPathUpdate,
  DASHBOARD_EVENTS.routerPathUpdateByUserApp,
  DASHBOARD_EVENTS.navigatorExpandNodeList,
  DASHBOARD_EVENTS.navigatorExpandNode,
  DASHBOARD_EVENTS.navigatorCollapseNode,
  DASHBOARD_EVENTS.navigatorCollapseNodeList,
  DASHBOARD_EVENTS.layoutModeUpdate,
  DASHBOARD_EVENTS.setThemeManagerVisibility,
  ...Object.values(UI_BUILDER_EVENTS),
  ...Object.values(DASHBOARD_ANIM_EVENTS),
];

const intermediateBasicReducers = combineReducers<DashboardState, AppEvents>({
  operations: (s: DashboardState['operations'] = dashboardInitial.operations) => s,
  copyBuffer: (s: DashboardState['copyBuffer'] = dashboardInitial.copyBuffer) => s,
  copyStyle: (s: DashboardState['copyStyle'] = dashboardInitial.copyStyle) => s,
  openAsRootNode: (s: DashboardState['openAsRootNode'] = dashboardInitial.openAsRootNode) => s,
  components: (s: DashboardState['components'] = dashboardInitial.components) => s,
  history: (s: DashboardState['history'] = dashboardInitial.history) => s,
  router: (s: DashboardState['router'] = dashboardInitial.router) => s,
  iFrameRefreshed: (s: DashboardState['iFrameRefreshed'] = dashboardInitial.iFrameRefreshed) => s,
  navigator: (s: DashboardState['navigator'] = dashboardInitial.navigator) => s,
  loadingConfiguration: (
    s: DashboardState['loadingConfiguration'] = dashboardInitial.loadingConfiguration,
  ) => s,
  appConfiguration: appConfigurationReducer,
  themeManager: themeManagerReducer,
  layout: layoutReducer,
});

const withHistory = (
  oldState: DashboardState,
  newState: DashboardState,
  event: AppEvents,
): DashboardState => {
  // Also checking ALL_POSSIBLE_EVENTS to ignore redux init events like "@@INIT"
  if (BLACKLIST_HISTORY_ACTIONS.includes(event.type) || !ALL_POSSIBLE_EVENTS.includes(event.type)) {
    return newState;
  }

  // historical action, hence we need to preserve prev-state
  const prevBaseState: BaseDashboardState = {
    ...getBaseDashboardState(oldState),
    // no point commit operations to history
    operations: { ...dashboardInitial.operations },
  };

  // adding to history
  return {
    ...newState,
    history: {
      future: [],
      past:
        newState.history.past.length < HISTORY_LIMIT
          ? // just adding
            [prevBaseState, ...newState.history.past]
          : // dropping one to fit one
            [prevBaseState, ...newState.history.past.slice(0, HISTORY_LIMIT - 1)],
    },
  };
};

const hasChanges = (
  oldState: Record<string, unknown>,
  newState: Record<string, unknown>,
  keysFrom: Record<string, unknown> = dashboardInitial,
): boolean => {
  const stateKeys = Object.keys(keysFrom);
  const equal = shallowEqualNaive(oldState, newState, stateKeys);
  return !equal;
};

export const dashboardReducer = (
  state: DashboardState = dashboardInitial,
  event: AppEvents,
): DashboardState => {
  /**
   * Reducers are composed and lined up based on their effect on `history`
   * , and complexity/scope (ie, whether or not they depend on the entire DashboardState)
   */

  /**
   * NOTE: should you struggle to understand where to put an event:
   * - when in doubt, just look at which state is available
   * - when you want to change an event to update something outside its initial scope:
   *    - consider moving the use-case to a more complex-reducer
   *    - if that feels wrong, consider adding an event that comes after the initial one
   *      and handle this case using sagas -- produce an event from another event
   *
   *    eg: componentSelected should also expand page-structure tree -- hence,
   *        componentSelected should actually produce another event navigatorExpandNode,
   *    ie: you can always try and split an event into sequence of smaller events,
   *        each of managing their own small business-cases
   */

  const newOperationsState = dashboardOperationsReducer(state.operations, event);
  if (hasChanges(state.operations, newOperationsState, dashboardInitial.operations)) {
    // operations are potentially high frequency and should not be committed to history
    return { ...state, operations: newOperationsState };
  }

  const newUndoRedoBasedState = dashboardUndoRedoReducer(state, event);
  if (hasChanges(state, newUndoRedoBasedState)) {
    // undo-redo reducer changes history directly
    return newUndoRedoBasedState;
  }

  const newRestoredState = dashboardCompleteUpdateRestoreReducer(state, event);
  if (hasChanges(state, newRestoredState)) {
    // restore-actions still require history update
    return withHistory(state, newRestoredState, event);
  }

  // those are simple scoped reducers
  const intermediateState = intermediateBasicReducers(state, event);
  // these require the entire DashboardState to reduce properly
  const newDashboardState = dashboardComplexReducer(intermediateState, event);

  if (hasChanges(state, newDashboardState)) {
    // have to commit to history at the end
    return withHistory(state, newDashboardState, event);
  }

  // no changes - no problems
  return state;
};
