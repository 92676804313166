var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-empty-function */
import { Children, forwardRef, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { getIsInvalid, RESET_STATUS } from '../common';
import { RadioGroup } from '../RadioGroup';
export const FormRadioGroup = forwardRef((_a, ref) => {
    var _b, _c;
    var { children, radioGroupProps, fieldProps, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, id, title, className, lang, translate, onChange = () => { } } = _a, rest = __rest(_a, ["children", "radioGroupProps", "fieldProps", 'data-test', 'data-node-id', 'data-node-render-path', "id", "title", "className", "lang", "translate", "onChange"]);
    const arrayChildren = Children.toArray(children);
    // eslint-disable-next-line
    const initialValue = (_c = (_b = arrayChildren.map((child, index) => {
        return Object.assign({}, child);
    })[0]) === null || _b === void 0 ? void 0 : _b.props) === null || _c === void 0 ? void 0 : _c.value;
    const { setFieldValue, status, setStatus, values } = useFormikContext();
    const handleOnChange = (event) => {
        event.preventDefault();
        const { value: newValue } = event.currentTarget;
        setFieldValue(fieldProps.name, newValue);
        onChange(event, newValue);
    };
    useEffect(() => {
        setFieldValue(fieldProps.name, initialValue);
        return () => setFieldValue(fieldProps.name, undefined);
        // eslint-disable-next-line
    }, [fieldProps.name, setFieldValue]);
    useEffect(() => {
        if (status === RESET_STATUS) {
            setStatus(undefined);
            const event = {
                target: {
                    value: initialValue,
                },
                // eslint-disable-next-line
            };
            onChange(event, initialValue);
            setFieldValue(fieldProps.name, initialValue);
        }
        // eslint-disable-next-line
    }, [radioGroupProps, status, setStatus]);
    useEffect(() => {
        if (radioGroupProps.value !== values[fieldProps.name]) {
            setFieldValue(fieldProps.name, radioGroupProps.value);
        }
    }, [fieldProps.name, setFieldValue, radioGroupProps.value, values]);
    return (_jsx(Field, Object.assign({ name: fieldProps.name, validate: fieldProps.validate }, { children: ({ field, meta, form }) => {
            const isInvalid = getIsInvalid({ meta, form });
            const errorText = isInvalid ? meta.error : undefined;
            return (_jsx(RadioGroup, Object.assign({}, radioGroupProps, { className: className, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, "data-test": dataTest, error: isInvalid, helperText: errorText, id: id, lang: lang, onChange: event => {
                    handleOnChange(event);
                }, ref: ref, title: title, translate: translate, value: field.value || radioGroupProps.value }, rest, { children: children })));
        } })));
});
