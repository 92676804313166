import { COMPONENT_SETTING_ACTION_TYPE, forEachNodeListProps, PropChecker, PREDEFINED_ACTIONS, nodeListSelectors, } from '@builder/schemas';
import { isArray, isNil, isObject } from '@builder/utils';
import { ROLES_STATE_NAME } from '../generators/app-states-generator/predefined-state/constants';
import { ASSETS_STATE_NAME } from '../generators/providers-generators/constants';
/**
 * @returns True if usedVariable is used in the code
 */
export const hasUsedVarInJSCode = (usedVariable, code) => {
    if (isNil(code)) {
        return false;
    }
    return (code.includes(`${usedVariable}.`) ||
        code.includes(`${usedVariable}[`) ||
        code.includes(`${usedVariable}(`) ||
        code.includes(`${usedVariable}?`) ||
        code.includes(`${usedVariable}\``));
};
/**
 * @returns True if usedVariable is used in the nodes
 */
export const hasUsedVarInNodeListDSL = (usedVariable, usedNodeListDSL, componentListDSL) => {
    let isVarUsed = false;
    // check if some node has allowedRoles rule
    if (usedVariable === ROLES_STATE_NAME) {
        const hasAllowedRolesRule = nodeListSelectors.hasAllowedRolesRule(usedNodeListDSL);
        if (hasAllowedRolesRule)
            return true;
    }
    // check for variable in showIf condition
    const usedInCondition = Object.values(usedNodeListDSL).some(nodeDSL => {
        var _a, _b;
        return ((_a = nodeDSL.condition) === null || _a === void 0 ? void 0 : _a.showIf) &&
            hasUsedVarInJSCode(usedVariable, JSON.stringify((_b = nodeDSL.condition) === null || _b === void 0 ? void 0 : _b.showIf));
    });
    if (usedInCondition) {
        return true;
    }
    // check for variable in iterator data
    const usedInIterator = Object.values(usedNodeListDSL).some(nodeDSL => {
        var _a, _b;
        return ((_a = nodeDSL.iterator) === null || _a === void 0 ? void 0 : _a.data) &&
            hasUsedVarInJSCode(usedVariable, JSON.stringify((_b = nodeDSL.iterator) === null || _b === void 0 ? void 0 : _b.data));
    });
    if (usedInIterator) {
        return true;
    }
    forEachNodeListProps(({ propValue, propDSL }) => {
        var _a;
        if (usedVariable === ASSETS_STATE_NAME && PropChecker.Value.isAssetProp(propValue)) {
            isVarUsed = true;
        }
        if (PropChecker.Schema.isActionProp(propDSL) && PropChecker.Value.isActionProp(propValue)) {
            if (
            // if action is custom code then just check target value in the code
            propValue.actionType === COMPONENT_SETTING_ACTION_TYPE.changePage &&
                hasUsedVarInJSCode(usedVariable, JSON.stringify((_a = propValue === null || propValue === void 0 ? void 0 : propValue.args) === null || _a === void 0 ? void 0 : _a.code))) {
                isVarUsed = true;
            }
            else {
                const predefinedAction = PREDEFINED_ACTIONS[propValue.actionType];
                if (
                // if action is not custom code then check target value in the args and predefined actions
                hasUsedVarInJSCode(usedVariable, JSON.stringify(propValue.args)) ||
                    hasUsedVarInJSCode(usedVariable, predefinedAction)) {
                    isVarUsed = true;
                }
            }
        }
        else if (
        // if prop is style or object without defined props then check target value in the stringified object
        PropChecker.Schema.isArrayPropWithDefinedObjectProps(propDSL) ||
            PropChecker.Schema.isObjectPropWithNoDefinedProps(propDSL) ||
            PropChecker.Schema.isStyleProp(propDSL)) {
            if (hasUsedVarInJSCode(usedVariable, JSON.stringify(propValue))) {
                isVarUsed = true;
            }
        }
        else if (hasUsedVarInJSCode(usedVariable, JSON.stringify(propValue))) {
            isVarUsed = true;
        }
    }, componentListDSL, usedNodeListDSL);
    return isVarUsed;
};
export const hasUsedVarInJs = (usedVariable, toLookFor) => {
    if (isObject(toLookFor)) {
        return Object.values(toLookFor).some(value => hasUsedVarInJs(usedVariable, value));
    }
    if (isArray(toLookFor)) {
        return toLookFor.some(value => hasUsedVarInJs(usedVariable, value));
    }
    return hasUsedVarInJSCode(usedVariable, JSON.stringify(toLookFor));
};
