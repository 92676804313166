/* eslint-disable @typescript-eslint/ban-ts-comment, import/no-webpack-loader-syntax */
import { nodeListSelectors } from '@builder/schemas';
// @ts-ignore
import AUTH_TYPINGS from '!!raw-loader!@builder/types-generators/src/types-templates/auth.global.d.ts.template';
// @ts-ignore
import ROUTER_HISTORY_TYPINGS from '!!raw-loader!@builder/types-generators/src/types-templates/history.global.d.ts.template';
// @ts-ignore
import MUI_PALETTE_TYPINGS from '!!raw-loader!@builder/types-generators/src/types-templates/mui-palette.global.d.ts.template';
// @ts-ignore
import MUI_THEME_TYPINGS from '!!raw-loader!@builder/types-generators/src/types-templates/mui-theme.global.d.ts.template';
// @ts-ignore
import ROLES_TYPINGS from '!!raw-loader!@builder/types-generators/src/types-templates/roles.global.d.ts.template';
// @ts-ignore
import ROUTES_TYPINGS from '!!raw-loader!@builder/types-generators/src/types-templates/routes.global.d.ts.template';
// @ts-ignore
import DOM_TYPINGS from '!!raw-loader!typescript/lib/lib.dom.d.ts';
function camelize(str) {
    return (str
        // eslint-disable-next-line func-names
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
        .replace(/\s+/g, ''));
}
/**
 * Generate types for monaco editor
 * related hook `useAppEnginePredefinedHooks`
 */
export class PredefinedTypesGenerator {
    generateHistoryType() {
        return 'declare const history: RouterHistory;';
    }
    generateLocationType() {
        return `declare const location: RouterHistory['location'];`;
    }
    /**
     * Important: for this we need global theme type definition (GlobalTypesGenerator - MUI_THEME_TYPINGS)
     */
    generateThemeType() {
        const themeType = `type ThemeFrameworkSettingsDSL = ThemeOptions & {
      name?: string;
      css?: string;
    };`;
        return `${themeType} declare const theme: ThemeFrameworkSettingsDSL;`;
    }
    generateAuthType() {
        return `declare const auth: AuthContextProps;`;
    }
    generateRolesType() {
        return `declare const roles: RolesReturnType;`;
    }
    generateRoutesType(nodeListDSL) {
        const routesList = nodeListSelectors.getAllRouteNodes(nodeListDSL).map(routeNodeDSL => ({
            path: routeNodeDSL.props.path,
            title: routeNodeDSL.props.title,
            alias: camelize(routeNodeDSL.alias ? routeNodeDSL.alias.replace('Wrapper', '') : ''),
        }));
        const appRouteTitles = routesList.map(route => `${route.alias}: RouteList;`).join('\n');
        const appRoutePathsTypes = routesList.map(route => `'${route.path}'`).join(' | ');
        const appRouteTitlesTypes = routesList
            .map(route => `'${route.alias ? route.alias : ''}'`)
            .join(' | ');
        const routesTheme = `
      type RoutePathTypes = ${appRoutePathsTypes};
      type RouteTitleTypes = ${appRouteTitlesTypes};
      type RouteList = {
        path: string;
        meta: string;
        name: string;
      };
      type Route = {
        path: RoutePathTypes;
        title: RouteTitleTypes;
        exact?: boolean;
        strict?: boolean;
        params: Record<string, unknown>;
        fragment: Record<string, unknown>;
        meta: {};
        name: '';
      type RoutesReturnType = {
        list: Route[];
        ${appRouteTitles}
        currentRoute?: Route;
        navigate: Route[];
      };`;
        return `${routesTheme} declare const router: RoutesReturnType;`;
    }
    generateTypes(nodeListDSL) {
        return [
            {
                content: ROUTER_HISTORY_TYPINGS,
                filePath: 'ts:router-history.d.ts',
            },
            {
                content: MUI_PALETTE_TYPINGS,
                filePath: 'ts:mui-palette.d.ts',
            },
            {
                content: MUI_THEME_TYPINGS,
                filePath: 'ts:mui-theme.d.ts',
            },
            {
                content: AUTH_TYPINGS,
                filePath: 'ts:auth.d.ts',
            },
            {
                content: ROLES_TYPINGS,
                filePath: 'ts.roles.d.ts',
            },
            {
                content: ROUTES_TYPINGS,
                filePath: 'ts.routes.d.ts',
            },
            {
                content: [
                    this.generateHistoryType(),
                    this.generateLocationType(),
                    this.generateThemeType(),
                    this.generateAuthType(),
                    this.generateRolesType(),
                    this.generateRoutesType(nodeListDSL),
                ].join('\n'),
                filePath: 'ts:predefined.d.ts',
            },
            /**
             * Rewriting DOM typings because of overriding global variables
             * For example, "history" and "location"
             */
            {
                content: DOM_TYPINGS,
                filePath: 'ts:dom.d.ts',
            },
        ];
    }
}
