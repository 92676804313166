import { values } from 'ramda';
import { appSettingsSelectors, nodeListSelectors, nodeSelectors, SYMBOL_DSL_NAMES, } from '@builder/schemas';
import { onlyUnique } from '@builder/utils';
import { generateTypeFromPropListDSL, generateTypeImportsFromPropListDSL } from '../../utils';
import { CodeSymbolNodesGenerator } from '../app-nodes-generator';
import { PredefinedStateListUsageGenerator } from '../app-states-generator';
import { ImportsGenerator } from '../imports-generator';
import { GlobalStateProviderGenerator } from '../providers-generators';
import { SymbolAccessor } from './SymbolAccessor';
export class SymbolListUsageGenerator {
    constructor({ appDSL, componentListDSL, assetBackendList, assetListDSL, }) {
        this.appDSL = appDSL;
        this.componentListDSL = componentListDSL;
        this.assetListDSL = assetListDSL;
        this.predefinedStateListUsageGenerator = new PredefinedStateListUsageGenerator(componentListDSL);
        this.importsGenerator = new ImportsGenerator({
            appDSL,
            componentListDSL,
            assetBackendList,
            assetListDSL,
        });
        this.globalStateProviderGenerator = new GlobalStateProviderGenerator({
            appDSL,
            componentListDSL,
            assetBackendList,
            assetListDSL,
        });
        this.symbolAccessor = new SymbolAccessor(componentListDSL);
    }
    getAllSymbolNames() {
        return Object.values(this.appDSL.nodes)
            .filter(nodeDSL => nodeSelectors.isSymbol(nodeDSL, this.componentListDSL))
            .map(({ name }) => name)
            .filter(onlyUnique);
    }
    generateImports(symbolName) {
        const symbolComponentDSL = this.symbolAccessor.getSymbolComponentDSL(symbolName);
        const imports = [
            ...this.importsGenerator.predefinedStateImports.getSymbolImportDataList(symbolComponentDSL.schema.symbolNodes),
            ...this.generateSymbolTypeDefinitionImports(symbolComponentDSL),
            ...this.importsGenerator.getDSLImports(values(symbolComponentDSL.schema.symbolNodes).map(({ name }) => name)),
            ...this.importsGenerator.symbolsImports.getImportDataList(symbolComponentDSL.schema.symbolNodes),
            ...this.importsGenerator.hookImports.getLocalImportDataList(symbolComponentDSL.schema.symbolNodes),
            ...this.importsGenerator.providersImports.getProviderHooksImportDataList(symbolComponentDSL.schema.symbolNodes),
        ];
        if (symbolComponentDSL.name === SYMBOL_DSL_NAMES.BaseLayout ||
            symbolComponentDSL.name === SYMBOL_DSL_NAMES.MobileLayout) {
            imports.push({ importName: 'shared/hooks', varName: 'useRoutes' }, { importName: 'react-router-dom', varName: 'Redirect' });
        }
        return this.importsGenerator.generateImports(imports);
    }
    generateSymbolTypeDefinitionImports(symbolComponentDSL) {
        return generateTypeImportsFromPropListDSL(symbolComponentDSL.schema.props);
    }
    generateSymbolTypeDefinition(symbolComponentDSL) {
        return generateTypeFromPropListDSL(symbolComponentDSL.schema.props);
    }
    generateSymbolBody(symbolName) {
        const symbolNodeListDSL = this.symbolAccessor.getSymbolNodeListDSL(symbolName);
        const rootNode = nodeListSelectors.getRootNodeDSL(symbolNodeListDSL);
        const shouldMinSizeBeAppliedInCodeEngine = appSettingsSelectors.shouldMinSizeBeAppliedInCodeEngine(this.appDSL.settings);
        const nodeGenerator = new CodeSymbolNodesGenerator({
            nodeListDSL: symbolNodeListDSL,
            componentListDSL: this.componentListDSL,
            assetListDSL: this.assetListDSL,
            options: {
                rootNodeID: rootNode.id,
                applyMinSizeStyles: shouldMinSizeBeAppliedInCodeEngine,
                stateListDSL: this.appDSL.states,
            },
        });
        return nodeGenerator.runGeneratingNode();
    }
    generateSymbolDeclaration(symbolName) {
        const symbolComponentDSL = this.symbolAccessor.getSymbolComponentDSL(symbolName);
        const componentName = this.symbolAccessor.getSymbolComponentName(symbolName);
        const componentTypeName = `${componentName}Props`;
        const componentTypeDefinition = this.generateSymbolTypeDefinition(symbolComponentDSL);
        const useRoutesVariable = componentName === SYMBOL_DSL_NAMES.BaseLayout ||
            componentName === SYMBOL_DSL_NAMES.MobileLayout
            ? `
        const router = useRoutes()

        if(!router.currentRoute?.path){
          return <Redirect to="/404" />;
        }`
            : '';
        return `
      type ${componentTypeName} = ${componentTypeDefinition};

      export const ${componentName}: React.FC<${componentTypeName}> = (symbolProps) => {
        ${this.predefinedStateListUsageGenerator.generatePredefinedHooksCallsForComponent(symbolComponentDSL.schema.symbolNodes)}

        ${useRoutesVariable}

        return ( ${this.generateSymbolBody(symbolName)} )
      };
    `;
    }
    generateSymbolFile(symbolName) {
        return `
      /** @jsxRuntime classic */
      /** @jsx jsx */
      ${this.generateImports(symbolName)}

      ${this.generateSymbolDeclaration(symbolName)}
    `;
    }
    generateSymbolsDeclaration() {
        return this.getAllSymbolNames()
            .map(symbolName => this.generateSymbolDeclaration(symbolName))
            .join('\n');
    }
    generateSymbolIndex() {
        return this.getAllSymbolNames()
            .map(symbolName => {
            const symbolDeclarationName = this.symbolAccessor.getSymbolComponentName(symbolName);
            return `export { ${symbolDeclarationName} } from './${symbolDeclarationName}';`;
        })
            .join('\n');
    }
}
