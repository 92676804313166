/* eslint-disable no-underscore-dangle */
import { assocPath, pick, not, path, isNil, sortBy, pathOr, values } from 'ramda';
import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';
import { ERROR_SCOPES, isNull, isString, isUndefined, onlyUnique, SchemaError, splitPath, } from '@builder/utils';
import { COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_KINDS, COMPONENT_DSL_NAMES, COMPONENT_DSL_RULES_INTERACTION_TYPES, EMPTY_PAGE_ROUTER_SWITCH_NODE_ID, LAYOUT_MODES, ROOT_NODE_ID, SYMBOL_DSL_NAMES, } from '../constants';
import { sortAlphabetically } from '../utils/sortAlphabetically';
import { NodeChecker, PropAssert } from '../validation';
import { PropSchemaChecker } from '../validation/helpers/PropSchemaChecker';
import * as componentListSelectors from './component-list-selectors';
import * as nodeSchemaSelectors from './node-schema-selectors';
import * as nodeSelectors from './node-selectors';
import { formChildrenValidNames, formContainerNames } from './utils';
export const getNodeListDSL = (nodeListDSL) => {
    return nodeListDSL;
};
const HIDDEN_LAYOUT = 'hiddenLayout';
const getNodeID = (_, props) => props.nodeID;
const getParentID = (_, props) => props.parentID;
const getStateListDSL = (_, props) => props.stateListDSL;
// eslint-disable-next-line camelcase
const getComponentListDSL__legacy = (_, componentListDSL) => componentListDSL;
const getComponentListDSL = (_, props) => props.componentListDSL;
const getComponentDSL = (_, props) => props.componentDSL;
const getUserComponentSchemaDSL = (_, props) => props.userComponentDSL;
const getCurrentPathname = (_, props) => props.currentPathname;
const getComponentName = (_, props) => props.componentName;
const getNodeAlias = (_, props) => props.alias;
const getPropName = (_, props) => props.propName;
const getNodesIDs = (_, props) => props.nodeIDs;
/**
 * Helper proxies ramda assocPath with typechecking.
 */
export const assocAppNodesPath = (nodeListDSL, absolutePath, value) => {
    return assocPath(absolutePath, value, nodeListDSL);
};
export const getNodesArrayDSL = createSelector(getNodeListDSL, nodeListDSL => {
    return Object.values(nodeListDSL);
});
export const getNodeDSL = createSelector(getNodeListDSL, getNodeID, (nodeListDSL, nodeID) => {
    const nodeDSL = isUndefined(nodeID)
        ? nodeListDSL.NotFoundRootRouteLayout_ID || values(nodeListDSL)[0]
        : nodeListDSL[nodeID];
    if (!nodeDSL) {
        throw new SchemaError(ERROR_SCOPES.schemas, `Node with id ${nodeID} not found.`);
    }
    return nodeDSL;
});
export const getRouteParent = (nodeList, node) => {
    if (!node)
        return;
    if (node.name === COMPONENT_DSL_NAMES.BuilderComponentsRoute) {
        return node;
    }
    if (node.parentID && nodeList[node.parentID]) {
        const newNodeID = nodeList[node.parentID];
        return getRouteParent(nodeList, newNodeID);
    }
};
/**
 * Get layout child nodes by root id
 */
export const getNodesLayoutDSL = createSelector(getNodeListDSL, getNodeID, (nodeListDSL, nodeID) => {
    var _a, _b, _c, _d, _e, _f;
    const nodeDSL = nodeListDSL[nodeID];
    if (!nodeDSL) {
        throw new SchemaError(ERROR_SCOPES.schemas, `Node with id ${nodeID} not found.`);
    }
    return (((_b = (_a = nodeDSL.props) === null || _a === void 0 ? void 0 : _a.content) === null || _b === void 0 ? void 0 : _b.nodes) ||
        ((_d = (_c = nodeDSL.props) === null || _c === void 0 ? void 0 : _c.routes) === null || _d === void 0 ? void 0 : _d.nodes) ||
        ((_f = (_e = nodeDSL.props) === null || _e === void 0 ? void 0 : _e.children) === null || _f === void 0 ? void 0 : _f.nodes) ||
        []);
});
/**
 * Get layout child nodes ids by rootId
 */
export const getChildIdNodesLayoutByRootId = createSelector(getNodeListDSL, getNodeID, (nodeListDSL, nodeID) => {
    let childNodes = [];
    let targetID = nodeID;
    const size = Object.keys(nodeListDSL).length;
    for (let i = 0; i <= size; i++) {
        const nodeChildrens = getNodesLayoutDSL(nodeListDSL, {
            nodeID: targetID,
        });
        if (Object.values(nodeChildrens).length) {
            const [key] = nodeChildrens;
            targetID = key;
            if (targetID !== 'EmptyPageRootRoute_ID' && targetID !== 'EmptyPageRootRouteLayout_ID')
                childNodes = [...childNodes, targetID];
        }
    }
    return childNodes.reduce((result, key) => (Object.assign(Object.assign({}, result), { [key]: nodeListDSL[key] })), {});
});
/**
 * Get layout node binded to route page.
 */
export const getBindedLayoutNodeDSL = createSelector(getNodeListDSL, getNodeID, (nodeListDSL, nodeID) => {
    const currentRouteNode = nodeListDSL[nodeID];
    const getLayoutNode = (currentNodeParentID) => {
        const layoutNode = nodeListDSL[currentNodeParentID];
        const isDefaultLayout = layoutNode.id === EMPTY_PAGE_ROUTER_SWITCH_NODE_ID;
        if (!isDefaultLayout && Object.prototype.hasOwnProperty.call(layoutNode.props, 'path')) {
            return layoutNode;
        }
        return isDefaultLayout ? layoutNode : getLayoutNode(String(layoutNode.parentID));
    };
    return getLayoutNode(String(currentRouteNode.parentID));
});
/**
 * Change layout child name by component name
 */
export const changeLayoutNameChildByComponent = createSelector(getNodeListDSL, getNodeAlias, (_, props) => props, (nodeListDSL, nodeAlias, props) => {
    const newNodeList = nodeListDSL;
    Object.keys(newNodeList).forEach(key => {
        var _a;
        if (((_a = newNodeList[key]) === null || _a === void 0 ? void 0 : _a.name) === props.name) {
            newNodeList[key].alias = nodeAlias;
        }
    });
    return newNodeList;
});
export const getRootNodeDSL = createSelector(getNodeListDSL, nodeListDSL => {
    const rootNodeDSL = Object.values(nodeListDSL).find(({ parentID }) => isNull(parentID));
    if (!rootNodeDSL) {
        throw new SchemaError(ERROR_SCOPES.schemas, `Root node wasn't found.`);
    }
    return rootNodeDSL;
});
export const getFilteredNodeListDSL = createSelector(getNodeListDSL, (_, props) => props.nodeIDs, (nodeListDSL, nodeIDs) => nodeIDs.reduce((accum, id) => {
    const nodeDSL = nodeListDSL[id];
    if (nodeDSL) {
        return Object.assign(Object.assign({}, accum), { [id]: nodeDSL });
    }
    return accum;
}, {}));
/**
 * @returns Node which has parentNodeID, but doesn't have appropriate parent node
 */
export const getNodeWithoutParentDSL = createSelector(getFilteredNodeListDSL, nodeListDSL => {
    const nodeWithoutParentDSL = Object.values(nodeListDSL).find(({ parentID }) => {
        if (isNull(parentID)) {
            return true;
        }
        const parentNodeDSL = nodeListDSL[parentID];
        if (!parentNodeDSL) {
            return true;
        }
        return false;
    });
    if (!nodeWithoutParentDSL) {
        throw new SchemaError(ERROR_SCOPES.schemas, `Node without parent wasn't found.`);
    }
    return nodeWithoutParentDSL;
});
export const getAllDialogsNodes = createSelector(getNodeListDSL, getRootNodeDSL, (nodeListDSL, rootNode) => {
    var _a;
    const dialogsProp = rootNode.props.dialogs;
    PropAssert.Value.assertIsReactNodeProp(dialogsProp, 'dialogs', { allowEmpty: true });
    const dialogNodeIDs = (_a = dialogsProp === null || dialogsProp === void 0 ? void 0 : dialogsProp.nodes) !== null && _a !== void 0 ? _a : [];
    return sortAlphabetically(dialogNodeIDs.map(dialogID => getNodeDSL(nodeListDSL, { nodeID: dialogID })), 'alias');
});
export const getAllLooperContentNodes = createSelector(getNodeListDSL, nodeListDSL => {
    const looperNodes = Object.values(nodeListDSL)
        .filter(node => node.name === COMPONENT_DSL_NAMES.Iterator)
        .reduce((acc, currentNode) => {
        var _a;
        const currentNodeChildren = currentNode.props.children;
        if ((_a = currentNodeChildren === null || currentNodeChildren === void 0 ? void 0 : currentNodeChildren.nodes) === null || _a === void 0 ? void 0 : _a[0]) {
            const looperContentNodeId = currentNodeChildren.nodes[0];
            return Object.assign(Object.assign({}, acc), { [looperContentNodeId]: nodeListDSL[looperContentNodeId] });
        }
        return acc;
    }, {});
    return looperNodes;
});
/**
 * @returns Parent node or null if target node is root.
 */
export const getParentNodeDSL = createSelector(getNodeListDSL, getNodeDSL, (nodeListDSL, node) => {
    return (node.parentID && nodeListDSL[node.parentID]) || null;
});
/**
 * @returns All route paths that are in the schema.
 */
export const getAllRoutePaths = createSelector(getNodeListDSL, (nodeListDSL) => {
    const routePaths = Object.values(nodeListDSL)
        .filter(nodeDSL => NodeChecker.Value.isRouteNode(nodeDSL) &&
        Boolean(nodeDSL.props.path) &&
        isString(nodeDSL.props.path))
        .map(nodeDSL => nodeDSL.props.path);
    return routePaths.length > 0 ? routePaths : ['/'];
});
/**
 * @returns A list of nodes that include only nodes with the given component name.
 */
export const getNodesListDSLByName = createSelector(getNodeListDSL, getComponentName, (nodeListDSL, componentName) => {
    return Object.values(nodeListDSL).filter(node => node.name === componentName);
});
/**
 * @returns Lis of all node IDs.
 */
export const getAllNodeIDs = createSelector(getNodeListDSL, (nodeListDSL) => {
    return Object.keys(nodeListDSL);
});
/**
 * Loops through an entire branch starting with the @nodeID
 * @returns List of all children including grand-grand-grand-... ones
 */
export const getAllChildrenIDs = createSelector(getNodeListDSL, getComponentListDSL, getNodeID, (nodeListDSL, componentListDSL, nodeID) => {
    const targetNode = nodeListDSL[nodeID];
    const result = nodeSelectors.getAllImmediateChildrenIDs(targetNode, {
        componentListDSL,
    });
    let cursorStart = 0;
    let cursorEnd = result.length;
    while (cursorStart - cursorEnd) {
        for (let i = cursorStart; i < cursorEnd; i += 1) {
            const cursorID = result[i];
            const cursorNode = nodeListDSL[cursorID];
            result.push(...nodeSelectors.getAllImmediateChildrenIDs(cursorNode, { componentListDSL }));
        }
        cursorStart = cursorEnd;
        cursorEnd = result.length;
    }
    return result;
});
export const getAllImmediateChildrenIDs = createSelector(getNodeListDSL, getComponentListDSL, getNodeID, (nodeListDSL, componentListDSL, nodeID) => {
    const targetNode = nodeListDSL[nodeID];
    return nodeSelectors.getAllImmediateChildrenIDs(targetNode, { componentListDSL });
});
/**
 * @returns List of all children nodes for target node
 */
export const getAllChildrenNodes = createSelector(getNodesArrayDSL, getAllChildrenIDs, (nodesArrayDSL, allChildrenIDs) => {
    return nodesArrayDSL.filter(({ id }) => allChildrenIDs.some(childID => childID === id));
});
/**
 * @returns List of all children Route nodes for target node.
 */
export const getAllChildrenRouteNodes = createSelector(getAllChildrenNodes, childrenNodeListDSL => {
    return childrenNodeListDSL.filter(({ name }) => name === COMPONENT_DSL_NAMES.BuilderComponentsRoute ||
        name === COMPONENT_DSL_NAMES.BuilderComponentsRouteForLayout);
});
/**
 * @returns List of all children Route Content nodes for target node.
 */
export const getAllChildrenRouteContentNodes = createSelector(getAllChildrenNodes, childrenNodeListDSL => {
    return childrenNodeListDSL.filter(({ name }) => name === COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout ||
        name === COMPONENT_DSL_NAMES.BuilderComponentsRoute);
});
/**
 * @returns List of all Route nodes with layout as a parent.
 */
export const getAllChildrenLayoutRouteNodes = createSelector(getNodeListDSL, getComponentListDSL, getNodeID, (nodeListDSL, componentListDSL, nodeID) => {
    const targetNode = nodeListDSL[nodeID];
    const componentLayoutID = nodeSelectors.getAllImmediateChildrenIDs(getNodeDSL(nodeListDSL, {
        nodeID: nodeSelectors.getAllImmediateChildrenIDs(targetNode, {
            componentListDSL,
        })[0],
    }), {
        componentListDSL,
    });
    const recursiveSearch = (currentNode) => {
        let results = [];
        if (currentNode.name === COMPONENT_DSL_NAMES.RouterSwitchSymbol) {
            return getAllChildrenRouteNodes(nodeListDSL, {
                nodeID: currentNode.id,
                componentListDSL,
            });
        }
        const insideNodes = nodeSelectors.getAllImmediateChildrenIDs(currentNode, {
            componentListDSL,
        });
        for (const nodeId of insideNodes) {
            const node = nodeListDSL[nodeId];
            results = [...results, ...recursiveSearch(node)];
        }
        return results;
    };
    for (const id of componentLayoutID) {
        const currentNode = nodeListDSL[id];
        const result = recursiveSearch(currentNode);
        if (result.length > 0) {
            return result;
        }
    }
    return [];
});
export const findRouterSwitch = (nodeListDSL, nodeID) => {
    var _a, _b, _c, _d;
    const targetNode = nodeListDSL[nodeID];
    let switchNode = null;
    if (targetNode.name === COMPONENT_DSL_NAMES.BuilderComponentsBox) {
        switchNode = nodeListDSL[(_b = (_a = targetNode.props) === null || _a === void 0 ? void 0 : _a.children) === null || _b === void 0 ? void 0 : _b.nodes[0]];
        if (switchNode.name === COMPONENT_DSL_NAMES.BuilderComponentsBox) {
            return findRouterSwitch(nodeListDSL, (_d = (_c = switchNode.props) === null || _c === void 0 ? void 0 : _c.children) === null || _d === void 0 ? void 0 : _d.nodes[0]);
        }
        return switchNode;
    }
    return targetNode;
};
export const getAllRouteNodesIncludeSystem = createSelector(getNodeListDSL, nodeListDSL => {
    const allRouteNodes = Object.values(nodeListDSL).filter(({ name, props }) => 
    // eslint-disable-next-line no-underscore-dangle
    name === COMPONENT_DSL_NAMES.BuilderComponentsRoute ||
        name === COMPONENT_DSL_NAMES.BuilderComponentsRouteForLayout);
    return sortBy(pathOr('', ['props', 'path']), allRouteNodes);
});
export const getAllBaseLayoutNodes = createSelector(getNodeListDSL, nodeListDSL => {
    const allBaseLayoutNodes = Object.values(nodeListDSL).filter(({ name, props }) => 
    // eslint-disable-next-line no-underscore-dangle
    name === SYMBOL_DSL_NAMES.BasePageLayout);
    return sortBy(pathOr('', ['props', 'path']), allBaseLayoutNodes);
});
/**
 * @returns List of all Route nodes in the nodes DSL. The same is to get all children nodes for root node.
 * @props system is related to hide the page for the client
 */
export const getAllRouteNodes = createSelector(getNodeListDSL, nodeListDSL => {
    const allRouteNodes = Object.values(nodeListDSL).filter(({ name, props }) => (name === COMPONENT_DSL_NAMES.BuilderComponentsRoute ||
        name === COMPONENT_DSL_NAMES.BuilderComponentsRouteForLayout) &&
        props.system !== true);
    return sortBy(pathOr('', ['props', 'path']), allRouteNodes);
});
/**
 * @returns List of all Route layout nodes created by default when creating a new page in the nodes DSL.
 */
export const getAllDefaultRouteLayoutNodes = createSelector(getNodeListDSL, nodeListDSL => {
    const allRouteNodes = Object.values(nodeListDSL).filter(({ name, id }) => {
        var _a;
        return name === COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout &&
            ((_a = getParentNodeDSL(nodeListDSL, { nodeID: id })) === null || _a === void 0 ? void 0 : _a.props.system) !== true;
    });
    return sortBy(pathOr('', ['props', 'path']), allRouteNodes);
});
/**
 * @returns List of all Route nodes in the nodes DSL. The same is to get all children nodes for root node.
 */
export const getAllRouteContentNodes = createSelector(getNodeListDSL, getComponentListDSL, (nodeListDSL, componentListDSL) => {
    return getAllChildrenRouteContentNodes(nodeListDSL, { nodeID: ROOT_NODE_ID, componentListDSL });
});
/**
 * @returns List of all children RouterSwitch nodes for target node.
 */
export const getAllChildrenRouterSwitchNodes = createSelector(getNodeListDSL, getComponentListDSL__legacy, (_, __, nodeID) => nodeID, (nodeListDSL, componentListDSL, nodeID) => {
    return getAllChildrenNodes(nodeListDSL, { nodeID, componentListDSL }).filter(childNodeDSL => {
        const childComponentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
            componentName: childNodeDSL.name,
        });
        return childComponentDSL.kind === COMPONENT_DSL_KINDS.routerChangeableContent;
    });
});
/**
 * @returns List of all RouterSwitch nodes in the nodes DSL. The same is to get all children nodes for root node.
 */
export const getAllRouterSwitchNodes = createSelector(getNodeListDSL, getComponentListDSL, (nodeListDSL, componentListDSL) => {
    return getAllChildrenRouterSwitchNodes(nodeListDSL, componentListDSL, ROOT_NODE_ID);
});
/**
 * @returns Closest parent node with taken kind.
 */
export const getClosestNodeByKind = createSelector(getNodeListDSL, getComponentListDSL__legacy, (_, __, nodeID) => nodeID, (_, __, ___, componentKind) => componentKind, (nodeListDSL, componentListDSL, nodeID, componentKind) => {
    let parentNodeDSL = getParentNodeDSL(nodeListDSL, { nodeID });
    while (parentNodeDSL !== null) {
        const parentNodeComponentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
            componentName: parentNodeDSL.name,
        });
        if (parentNodeComponentDSL.kind === componentKind) {
            return parentNodeDSL;
        }
        parentNodeDSL = getParentNodeDSL(nodeListDSL, { nodeID: parentNodeDSL.id });
    }
    return null;
});
/**
 * @returns Closest parent node with taken route kind.
 */
export const getClosestRouteNodeDSL = createSelector(getNodeListDSL, getComponentListDSL, getNodeID, (nodeListDSL, componentListDSL, nodeID) => {
    const closestRouteNodeDSL = getClosestNodeByKind(nodeListDSL, componentListDSL, nodeID, COMPONENT_DSL_KINDS.route);
    return closestRouteNodeDSL;
});
/**
 * @returns Closest parent node which parent taken route kind.
 */
export const getClosestRouteContentNodeDSL = createSelector(getNodeListDSL, getClosestRouteNodeDSL, (nodeListDSL, closestRouteNodeDSL) => {
    if (!closestRouteNodeDSL) {
        return null;
    }
    return getNodeDSL(nodeListDSL, { nodeID: closestRouteNodeDSL.props.children.nodes[0] });
});
/**
 * @returns Closest nodal node with taken route kind.
 */
export const getClosestModalNodeDSL = createSelector(getNodeListDSL, getComponentListDSL, getNodeID, (nodeListDSL, componentListDSL, nodeID) => {
    return getClosestNodeByKind(nodeListDSL, componentListDSL, nodeID, COMPONENT_DSL_KINDS.modal);
});
/**
 * @returns Closest parent node with taken layout kind.
 */
export const getClosestLayoutNodeDSL = createSelector(getNodeListDSL, getComponentListDSL, getNodeID, (nodeListDSL, componentListDSL, nodeID) => {
    const closetLayoutNode = getClosestNodeByKind(nodeListDSL, componentListDSL, nodeID, COMPONENT_DSL_KINDS.layout);
    return closetLayoutNode;
});
/**
 * @returns Closest layout name which can be either layout node name or route title name.
 */
export const getClosestLayoutName = createSelector(getClosestRouteContentNodeDSL, getClosestLayoutNodeDSL, getComponentListDSL, (closetRouteContentNode, closetLayoutNode, componentListDSL) => {
    var _a, _b, _c;
    if (!closetLayoutNode) {
        return null;
    }
    if (closetRouteContentNode) {
        return (_a = closetRouteContentNode.alias) !== null && _a !== void 0 ? _a : '';
    }
    const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
        componentName: closetLayoutNode.name,
    });
    if (((_b = componentDSL.schema.props) === null || _b === void 0 ? void 0 : _b.__presentationName) !== undefined &&
        ((_c = componentDSL.schema.props) === null || _c === void 0 ? void 0 : _c.__presentationName.system) === true) {
        return closetLayoutNode.alias || componentDSL.displayName;
    }
    return componentDSL.displayName;
});
/**
 * @returns Closest layout kind which can be either layout or route.
 */
export const getClosestLayoutKind = createSelector(getClosestRouteNodeDSL, getClosestLayoutNodeDSL, (closetRouteNodeDSL) => {
    if (closetRouteNodeDSL) {
        return COMPONENT_DSL_KINDS.route;
    }
    return COMPONENT_DSL_KINDS.layout;
});
export const getAllLayoutsAlias = createSelector(getNodeListDSL, getComponentListDSL, (nodeListDSL, componentListDSL) => {
    const allChildrenRoutesWitchNodes = getAllChildrenRouterSwitchNodes(nodeListDSL, componentListDSL, ROOT_NODE_ID);
    return allChildrenRoutesWitchNodes;
});
/**
 * @returns List of all RouterSwitch options which contains name of the closet layout or route.
 */
export const getAllRouterSystemOptionsUnsorted = createSelector(getNodeListDSL, getComponentListDSL, getAllRouterSwitchNodes, (nodeListDSL, componentListDSL, switchNodes) => {
    const visibleSwitchNodes = [];
    for (const switchNode of switchNodes) {
        if (switchNode.hiddenLayout !== undefined && !switchNode.hiddenLayout) {
            visibleSwitchNodes.push(Object.assign(Object.assign({}, switchNode), { layoutName: getClosestLayoutName(nodeListDSL, { componentListDSL, nodeID: switchNode.id }) ||
                    '__not_found__' }));
        }
    }
    return visibleSwitchNodes;
});
export const getAllRouterSystemOptions = createSelector(getAllRouterSystemOptionsUnsorted, options => sortBy(pathOr('', ['layoutName']), options));
export const getAllRouterViewComponentsOptions = createSelector(getNodeListDSL, getComponentListDSL, getAllRouterSwitchNodes, (nodeListDSL, componentListDSL, switchNodes) => {
    const visibleSwitchNodes = switchNodes.filter(item => isNil(item.hiddenLayout));
    return sortBy(pathOr('', ['layoutName']), visibleSwitchNodes.map(switchNode => {
        const layoutName = getClosestLayoutName(nodeListDSL, {
            componentListDSL,
            nodeID: switchNode.id,
        });
        return Object.assign(Object.assign({}, switchNode), { layoutName: layoutName || '__not_found__' });
    }));
});
export const getAllRouterSwitchOptions = createSelector(getNodeListDSL, getComponentListDSL, getAllRouterSwitchNodes, (nodeListDSL, componentListDSL, switchNodes) => {
    const visibleSwitchNodes = switchNodes.filter(item => !path([HIDDEN_LAYOUT], item));
    return sortBy(pathOr('', ['layoutName']), visibleSwitchNodes.map(switchNode => {
        const layoutName = getClosestLayoutName(nodeListDSL, {
            componentListDSL,
            nodeID: switchNode.id,
        });
        return Object.assign(Object.assign({}, switchNode), { layoutName: layoutName || '__not_found__' });
    }));
});
/**
 * @returns List of all routes which are placed inside target node.
 */
export const getAllChildrenRoutes = createSelector(getAllChildrenRouteNodes, childrenRouteNodeListDSL => {
    return childrenRouteNodeListDSL.map(nodeDSL => pick(['title', 'path', 'exact', 'strict', 'sensitive'], nodeDSL.props));
});
export const isIterable = createSelector(getNodeDSL, nodeSelectors.isIterable);
export const getNodesListDSL = (nodeListDSL) => nodeListDSL;
export const getParents = createSelector(getNodesListDSL, getNodeDSL, (nodeListDSL, currentNodeDSL) => {
    const parents = [];
    let nodeDSL = nodeListDSL[currentNodeDSL.id];
    while (nodeDSL && nodeDSL.parentID !== null) {
        nodeDSL = nodeListDSL[nodeDSL.parentID];
        parents.push(nodeDSL);
    }
    return parents;
});
export const getIterableParents = createSelector(getParents, (nodeListDSL) => {
    return nodeListDSL.filter(el => nodeSelectors.isIterable(el));
});
/**
 * @returns List of recent routes in the DSL filtered by history.
 */
export const getRecentRoutes = createSelector(getAllRouteNodesIncludeSystem, (_, options) => options, (routeListDSL, { history, limit }) => {
    const recentPathList = history.filter(onlyUnique).slice(0, limit);
    const filteredList = routeListDSL.filter(route => recentPathList.some(recentPath => route.props.path === recentPath));
    return filteredList;
});
/**
 * @returns Current route BuilderComponentsRoute node in the DSL
 */
export const getCurrentRouteNodeDSL = createSelector(getAllRouteNodesIncludeSystem, getCurrentPathname, (routeListDSL, currentPathname) => {
    let currentRoute = routeListDSL.find(el => {
        const match = matchPath(currentPathname, {
            path: el.props.path,
            exact: true,
            strict: false,
        });
        return Boolean(match);
    });
    if (!currentRoute) {
        currentRoute = routeListDSL.find(el => {
            const match = matchPath('/404', {
                path: el.props.path,
                exact: true,
                strict: false,
            });
            return Boolean(match);
        });
    }
    if (!currentRoute) {
        throw new SchemaError(ERROR_SCOPES.schemas, `Current route node not found`);
    }
    return currentRoute;
});
/**
 * @returns Closest layout node which is used on the current page.
 */
export const getCurrentLayoutNodeDSL = createSelector(getNodeListDSL, getComponentListDSL, getCurrentRouteNodeDSL, (nodeListDSL, componentListDSL, currentRouteNodeDSL) => {
    const currentLayoutNode = getClosestLayoutNodeDSL(nodeListDSL, {
        componentListDSL,
        nodeID: currentRouteNodeDSL.id,
    });
    if (!currentLayoutNode) {
        throw new SchemaError(ERROR_SCOPES.schemas, `At least one Layout node must exist for any route.`);
    }
    return currentLayoutNode;
});
/**
 * @returns All nodes with kind=route" which are visible on the current page
 */
export const getAllRouteNodesOnCurrentPage = createSelector(getNodeListDSL, getComponentListDSL, getCurrentRouteNodeDSL, (nodeListDSL, componentListDSL, currentRouteNodeDSL) => {
    const routeNodes = [currentRouteNodeDSL];
    let closestRouteNode = getClosestRouteNodeDSL(nodeListDSL, {
        componentListDSL,
        nodeID: currentRouteNodeDSL.id,
    });
    while (closestRouteNode !== null) {
        routeNodes.push(closestRouteNode);
        closestRouteNode = getClosestRouteNodeDSL(nodeListDSL, {
            componentListDSL,
            nodeID: closestRouteNode.id,
        });
    }
    return routeNodes;
});
/**
 * @returns All nodes which is placed under the current BuilderComponentsRoute node
 */
export const getCurrentRouteContentNodeListDSL = createSelector(getNodeListDSL, getCurrentRouteNodeDSL, (nodeListDSL, currentRouteNodeDSL) => {
    return Object.values(nodeListDSL).reduce((accum, nodeDSL) => {
        if (hasParentNode(nodeListDSL, { nodeID: nodeDSL.id, parentID: currentRouteNodeDSL.id })) {
            return Object.assign(Object.assign({}, accum), { [nodeDSL.id]: nodeDSL });
        }
        return accum;
    }, {});
});
/**
 * @returns All nodes which is visible on current route except root layout nodes
 */
export const getCurrentRouteNodesExceptLayoutNodeListDSL = createSelector(getNodeListDSL, getComponentListDSL, getAllRouteNodesOnCurrentPage, (nodeListDSL, componentListDSL, allRouteNodes) => {
    return Object.values(nodeListDSL).reduce((accum, nodeDSL) => {
        const closestRouteNodeDSL = getClosestRouteNodeDSL(nodeListDSL, {
            componentListDSL,
            nodeID: nodeDSL.id,
        });
        const isClosetRouteNodeVisibleOnCurrentPage = allRouteNodes.some(routeNodeDSL => routeNodeDSL.id === (closestRouteNodeDSL === null || closestRouteNodeDSL === void 0 ? void 0 : closestRouteNodeDSL.id));
        if (isClosetRouteNodeVisibleOnCurrentPage) {
            return Object.assign(Object.assign({}, accum), { [nodeDSL.id]: nodeDSL });
        }
        return accum;
    }, {});
});
/**
 * @returns All nodes which is visible on current layout
 */
export const getCurrentLayoutNodeListDSL = createSelector(getNodeListDSL, getComponentListDSL, getCurrentPathname, getAllRouteNodesOnCurrentPage, (nodeListDSL, componentListDSL, currentPathname, allRoutes) => {
    const currentLayoutNodeDSL = getCurrentLayoutNodeDSL(nodeListDSL, {
        componentListDSL,
        currentPathname,
    });
    const currentRouteNodeDSL = getCurrentRouteNodeDSL(nodeListDSL, {
        currentPathname,
    });
    return Object.values(nodeListDSL).reduce((accum, nodeDSL) => {
        const isNodeRouteOnThePage = Boolean(allRoutes.find(routeNodeDSL => routeNodeDSL.id === nodeDSL.id));
        const isNodeVisibleOnRouteResult = isNodeVisibleOnRoute(nodeListDSL, {
            componentListDSL,
            nodeID: nodeDSL.id,
            currentPathname,
        });
        if (nodeDSL.id === currentLayoutNodeDSL.id || isNodeRouteOnThePage) {
            return Object.assign(Object.assign({}, accum), { [nodeDSL.id]: nodeDSL });
        }
        const closestRouteNodeDSL = getClosestRouteNodeDSL(nodeListDSL, {
            componentListDSL,
            nodeID: nodeDSL.id,
        });
        const closestLayoutNodeDSL = getClosestLayoutNodeDSL(nodeListDSL, {
            componentListDSL,
            nodeID: nodeDSL.id,
        });
        const isClosestLayoutNodeVisibleOnCurrentPage = (closestLayoutNodeDSL === null || closestLayoutNodeDSL === void 0 ? void 0 : closestLayoutNodeDSL.id) === currentLayoutNodeDSL.id;
        // if node doesn't have any route node as parent
        if (!closestRouteNodeDSL &&
            isClosestLayoutNodeVisibleOnCurrentPage &&
            nodeDSL.name !== COMPONENT_DSL_NAMES.BuilderComponentsRoute &&
            nodeDSL.name !== COMPONENT_DSL_NAMES.BuilderComponentsRouteForLayout) {
            return Object.assign(Object.assign({}, accum), { [nodeDSL.id]: nodeDSL });
        }
        // if node has route node as parent and doesn't have route node as children
        if (closestRouteNodeDSL &&
            isNodeVisibleOnRouteResult &&
            closestRouteNodeDSL.id !== currentRouteNodeDSL.id) {
            return Object.assign(Object.assign({}, accum), { [nodeDSL.id]: nodeDSL });
        }
        return accum;
    }, {});
});
/**
 * @returns All nodes which is visible on current route and can be edited in the dashboard
 */
export const getAllPageNodeListDSL = createSelector(getCurrentRouteNodesExceptLayoutNodeListDSL, getCurrentLayoutNodeListDSL, getAllRouteNodesOnCurrentPage, (currentRouteNodes, currentLayoutNode, allRouteNodes) => {
    return Object.assign(Object.assign(Object.assign({}, currentRouteNodes), currentLayoutNode), allRouteNodes.reduce((accum, nodeDSL) => (Object.assign(Object.assign({}, accum), { [nodeDSL.id]: nodeDSL })), {}));
});
/**
 * @returns All nodes which is placed under the any node with kind="modal"
 */
export const getModalsNodeListDSL = createSelector(getNodeListDSL, getComponentListDSL, (nodeListDSL, componentListDSL) => {
    return Object.values(nodeListDSL).reduce((accum, nodeDSL) => {
        const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
            componentName: nodeDSL.name,
        });
        const closestModalNodeDSL = getClosestModalNodeDSL(nodeListDSL, {
            componentListDSL,
            nodeID: nodeDSL.id,
        });
        if (
        // if nodeDSL has modal as parent or node DSL is modal or root
        !isNil(closestModalNodeDSL) ||
            componentDSL.kind === COMPONENT_DSL_KINDS.modal ||
            componentDSL.kind === COMPONENT_DSL_KINDS.root) {
            return Object.assign(Object.assign({}, accum), { [nodeDSL.id]: nodeDSL });
        }
        return accum;
    }, {});
});
export const getLayoutMode = createSelector(getCurrentRouteNodesExceptLayoutNodeListDSL, getCurrentLayoutNodeListDSL, getModalsNodeListDSL, getNodeID, (currentRouteNodes, currentLayoutNode, modalsNodeListDSL, nodeID) => {
    if (currentRouteNodes[nodeID]) {
        return LAYOUT_MODES.currentRouteNodes;
    }
    if (currentLayoutNode[nodeID]) {
        return LAYOUT_MODES.layoutNodes;
    }
    if (modalsNodeListDSL[nodeID]) {
        return LAYOUT_MODES.hiddenCanvasNodes;
    }
    return LAYOUT_MODES.allPageNodes;
});
/**
 * @returns First children of current route in the DSL.
 */
export const getCurrentRouteContentNodeDSL = createSelector(getNodeListDSL, getCurrentRouteNodeDSL, (_, props) => props.currentPathname, (nodeListDSL, routeNodeDSL, currentPathname) => {
    var _a, _b;
    const routeNodeChildren = (_a = routeNodeDSL === null || routeNodeDSL === void 0 ? void 0 : routeNodeDSL.props) === null || _a === void 0 ? void 0 : _a.children;
    const routeContentNodeID = (_b = routeNodeChildren === null || routeNodeChildren === void 0 ? void 0 : routeNodeChildren.nodes) === null || _b === void 0 ? void 0 : _b[0];
    const routeContentNodeDSL = getNodeDSL(nodeListDSL, { nodeID: routeContentNodeID });
    if (!routeContentNodeDSL) {
        throw new SchemaError(ERROR_SCOPES.schemas, `Route Content node not found for ${currentPathname} route`);
    }
    return routeContentNodeDSL;
});
/**
 * @returns Nested route for target node.
 */
export const getNodePathPattern = createSelector(getNodeListDSL, getComponentListDSL__legacy, (_, __, nodeID) => nodeID, (nodeListDSL, componentListDSL, nodeID) => {
    var _a;
    const closetParentRoute = getClosestNodeByKind(nodeListDSL, componentListDSL, nodeID, COMPONENT_DSL_KINDS.route);
    const nodeDSL = getNodeDSL(nodeListDSL, { nodeID });
    const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
        componentName: nodeDSL.name,
    });
    const routePath = componentDSL.kind === COMPONENT_DSL_KINDS.route ? nodeDSL.props.path : '';
    const nestedPath = splitPath((_a = closetParentRoute === null || closetParentRoute === void 0 ? void 0 : closetParentRoute.props) === null || _a === void 0 ? void 0 : _a.path, routePath);
    return nestedPath;
});
/**
 * @returns True if there is at least one route which is matches the pathname.
 */
export const hasChildrenRouteMatchPath = createSelector(getAllChildrenRouteNodes, (_, __, pathname) => pathname, (childrenRouteNodeListDSL, pathname) => {
    return (childrenRouteNodeListDSL
        .map(nodeDSL => nodeSelectors.matchRouteNodePath(nodeDSL, pathname))
        .filter(Boolean).length > 0);
});
export const isParentPropRequired = createSelector(getNodeID, getNodeListDSL, getComponentListDSL, (nodeID, nodeListDSL, componentListDSL) => {
    const nodeDSL = (nodeID && nodeListDSL[nodeID]) || null;
    const parentNode = (nodeDSL && nodeDSL.parentID && nodeListDSL[nodeDSL.parentID]) || null;
    if (!parentNode)
        return false;
    return nodeSelectors.getChildNodeRequired(parentNode, componentListDSL, nodeID);
});
/**
 * @returns List of all children RouteLayout nodes for target node.
 */
export const getAllRouteLayoutNodes = createSelector(getAllChildrenNodes, childrenNodeList => {
    return childrenNodeList.filter(({ name }) => name === COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout);
});
/**
 * @returns New countered alias for node.
 * @example
 * BuilderComponentsButton DSL => 'Button 1'
 * { alias: 'Unique Alias' } => 'Unique Alias 1'
 * { alias: 'Unique Alias 1' } => 'Unique Alias 2'
 * { alias: 'Unique Alias 123 1' } => 'Unique Alias 123 2'
 */
export const getIncrementedNodeAlias = createSelector(getNodeListDSL, getComponentDSL, getNodeID, (nodeListDSL, componentDSL, nodeID) => {
    const targetNodeDSL = getNodeDSL(nodeListDSL, { nodeID });
    const nodesArrayDSL = getNodesArrayDSL(nodeListDSL);
    const aliasPrefix = nodeSelectors.getNodeAliasPrefix(targetNodeDSL, { componentDSL });
    const allNodesAliases = nodesArrayDSL
        .filter(({ id }) => id !== targetNodeDSL.id)
        .map(({ alias }) => alias)
        .filter(Boolean);
    const allSameAliasesCounters = allNodesAliases
        .map(alias => Number(alias.replace(new RegExp(`^${aliasPrefix} `), '')))
        .filter(counter => counter && !isNaN(counter));
    const newIndex = Math.max(0, ...allSameAliasesCounters) + 1;
    return `${aliasPrefix} ${newIndex}`;
});
/**
 * @returns First node which is connected to state.
 */
export const getNodeWithConnectedState = createSelector(getNodesArrayDSL, (_, props) => props.stateDSL, (nodesArrayDSL, stateDSL) => {
    var _a;
    return ((_a = nodesArrayDSL.find(nodeDSL => { var _a; return (_a = nodeDSL.states) === null || _a === void 0 ? void 0 : _a.some(({ stateID }) => stateID === stateDSL.id); })) !== null && _a !== void 0 ? _a : null);
});
export const isNodeVisibleOnRoute = createSelector(getClosestRouteNodeDSL, getAllRouteNodesOnCurrentPage, (closestRouteNodeDSL, allRoutes) => {
    if (isNull(closestRouteNodeDSL)) {
        return false;
    }
    return Boolean(allRoutes.find(routeNodeDSL => routeNodeDSL.id === closestRouteNodeDSL.id));
});
/**
 * @returns true if node has specified parent
 */
export const hasParentNode = createSelector(getNodeListDSL, getNodeDSL, getParentID, (nodeListDSL, nodeDSL, parentID) => {
    let currentNode = nodeDSL;
    while (currentNode.parentID !== null) {
        if (currentNode.parentID === parentID) {
            return true;
        }
        currentNode = getNodeDSL(nodeListDSL, { nodeID: currentNode.parentID });
    }
    return false;
});
// /**
//  * Checks if target node meets the requirements of "insideOnly" and "allowedNodes" rules.
//  * @example
//  * COMPONENT_DSL_NAMES.BuilderComponentsFormTextField into empty COMPONENT_DSL_NAMES.root
//  * will return false because the form components requires COMPONENT_DSL_NAMES.BuilderComponentsForm ancestor.
//  * @example
//  * COMPONENT_DSL_NAMES.BuilderComponentsBox into COMPONENT_DSL_NAMES.BuilderComponentsMenu
//  * will return false because COMPONENT_DSL_NAMES.BuilderComponentsMenu accepts only COMPONENT_DSL_NAMES.ListItemSymbol
//  */
export const isAllowedToPutNodeInto = createSelector(getNodeListDSL, getComponentListDSL, (_, props) => props.targetNodeSchema, (_, props) => props.intoNodeID, (_, props) => props.propName, (nodeListDSL, componentListDSL, targetNodeSchema, intoNodeID, propName) => {
    const intoNodeDSL = getNodeDSL(nodeListDSL, { nodeID: intoNodeID });
    const intoNodeSchema = componentListSelectors.getComponentDSL(componentListDSL, {
        componentName: intoNodeDSL.name,
    });
    const childrenPropDSL = path(propName, intoNodeSchema.schema.props);
    const haveParentsConstraints = targetNodeSchema.schema.parentContraints &&
        targetNodeSchema.schema.parentContraints.some(ContraintsRule => {
            return (canBeParentFor(null, {
                parentContraintsRule: ContraintsRule,
                targetNode: intoNodeDSL,
            }) ||
                canBeNestedParentFor(nodeListDSL, {
                    parentContraintsRule: ContraintsRule,
                    targetNode: intoNodeDSL,
                }));
        });
    if (formContainerNames.includes(intoNodeSchema.name))
        return (formChildrenValidNames.includes(targetNodeSchema.name) ||
            targetNodeSchema.name.includes('Form'));
    if (!isNil(haveParentsConstraints) && !haveParentsConstraints) {
        return false;
    }
    return (PropSchemaChecker.isRenderableProp(childrenPropDSL) &&
        (!childrenPropDSL.allowedNodes ||
            childrenPropDSL.allowedNodes === 'all' ||
            childrenPropDSL.allowedNodes.includes(targetNodeSchema.name)));
});
export const hasAncestors = createSelector(getNodeListDSL, getNodeDSL, (_, props) => props.ancestorNames, (nodeListDSL, nodeDSL, ancestorNames) => {
    let { parentID } = nodeDSL;
    if (!parentID) {
        return false;
    }
    while (parentID) {
        const parentNodeDSL = getNodeDSL(nodeListDSL, {
            nodeID: parentID,
        });
        if (ancestorNames.some(ancestorName => parentNodeDSL.name === ancestorName)) {
            return true;
        }
        parentID = parentNodeDSL.parentID;
    }
    return false;
});
const getParentContraintsRule = (_, props) => props.parentContraintsRule;
const getTargetNode = (_, props) => props.targetNode;
export const canBeParentFor = createSelector(getParentContraintsRule, getTargetNode, (parentContraintsRule, targetNode) => {
    const hasDirectParentContainerType = parentContraintsRule.container.type === COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent;
    const hasParentInsideOnlyRule = parentContraintsRule.type === COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly;
    const hasParentExceptOnlyRule = parentContraintsRule.type === COMPONENT_DSL_RULES_INTERACTION_TYPES.exceptOnly;
    const isParent = parentContraintsRule.container.name === targetNode.name;
    const hasExceptContent = hasParentInsideOnlyRule && isParent;
    const hasAcceptContent = hasParentExceptOnlyRule && !isParent;
    return hasDirectParentContainerType && (hasExceptContent || hasAcceptContent);
});
export const canBeNestedParentFor = createSelector(getNodeListDSL, getParentContraintsRule, getTargetNode, (nodeListDSL, parentContraintsRule, targetNode) => {
    const isHasAncestors = hasAncestors(nodeListDSL, {
        nodeID: targetNode.id,
        ancestorNames: [parentContraintsRule.container.name],
    });
    const hasAnyAncestorContainerType = parentContraintsRule.container.type === COMPONENT_DSL_INSIDE_ONLY_TYPES.anyAncestor;
    const hasParentInsideOnlyRule = parentContraintsRule.type === COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly;
    const hasParentExceptOnlyRule = parentContraintsRule.type === COMPONENT_DSL_RULES_INTERACTION_TYPES.exceptOnly;
    const isParent = parentContraintsRule.container.name === targetNode.name;
    const hasExceptContent = hasParentInsideOnlyRule && (isParent || isHasAncestors);
    const hasAcceptContent = hasParentExceptOnlyRule && !isParent && !isHasAncestors;
    return hasAnyAncestorContainerType && (hasExceptContent || hasAcceptContent);
});
/**
 * @returns true if node can be deleted from the UI
 */
export const isNodeDeletable = createSelector(getNodeListDSL, getNodeID, getComponentListDSL, (nodeListDSL, nodeID, componentListDSL) => {
    const nodeDSL = getNodeDSL(nodeListDSL, { nodeID });
    const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
        componentName: nodeDSL.name,
    });
    const isRootNode = nodeSelectors.isRoot(nodeDSL);
    const isParentPropertyRequired = isParentPropRequired(nodeListDSL, {
        nodeID: nodeDSL.id,
        componentListDSL,
    });
    const interaction = nodeSchemaSelectors.getInteraction({
        nodeDSL,
        componentDSL,
    });
    return not(isRootNode ||
        isParentPropertyRequired ||
        interaction === COMPONENT_DSL_INTERACTION_TYPES.none ||
        interaction === COMPONENT_DSL_INTERACTION_TYPES.onlyEditable);
});
export const isUserComponentNodeExists = createSelector(getNodesArrayDSL, getUserComponentSchemaDSL, (nodeArrayDSL, userComponentSchemaDSL) => {
    return nodeArrayDSL.some(({ name }) => name === userComponentSchemaDSL.name);
});
export const isNodeAliasUnique = createSelector(getNodesArrayDSL, getNodeID, getNodeAlias, (nodeArrayDSL, nodeID, nodeAlias) => {
    return !nodeArrayDSL.some(searchedNodeDSL => searchedNodeDSL.alias === nodeAlias && searchedNodeDSL.id !== nodeID);
});
export const hasAllowedRolesRule = createSelector(getNodesArrayDSL, nodeArrayDSL => {
    return nodeArrayDSL.some(nodeSelectors.hasAllowedRolesRule);
});
export const getAllDSLProps = createSelector(getNodesArrayDSL, nodeArrayDSL => {
    return nodeArrayDSL.map(node => node.props);
});
export const getAllPropsByKey = createSelector(getAllDSLProps, getPropName, (allProps, propName) => {
    return allProps
        .filter(props => props === null || props === void 0 ? void 0 : props[propName])
        .map(props => props === null || props === void 0 ? void 0 : props[propName]);
});
export const getNodeListDSLWithoutNodes = createSelector(getNodesArrayDSL, getNodesIDs, (nodeListDSL, nodeIDs) => {
    return Object.values(nodeListDSL).reduce((accum, nodeDSL) => {
        if (nodeIDs.some(id => nodeDSL.id !== id)) {
            return Object.assign(Object.assign({}, accum), { [nodeDSL.id]: nodeDSL });
        }
        return accum;
    }, {});
});
export const getConnectedStates = createSelector(getNodeListDSL, getStateListDSL, getNodeID, getComponentListDSL, (nodeListDSL, stateListDSL, nodeID, componentListDSL) => {
    var _a;
    const closestParentRoute = getClosestNodeByKind(nodeListDSL, componentListDSL, nodeID, COMPONENT_DSL_KINDS.route);
    const childrenIDs = getAllChildrenIDs(nodeListDSL, { componentListDSL, nodeID });
    const connectedStates = (_a = closestParentRoute === null || closestParentRoute === void 0 ? void 0 : closestParentRoute.states) === null || _a === void 0 ? void 0 : _a.filter(state => state.componentBoundID && [nodeID, ...childrenIDs].includes(state.componentBoundID)).map(state => stateListDSL[state.stateID]);
    return connectedStates;
});
