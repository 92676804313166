import React from 'react';

type initialStateType = {
  mode: string;
};

type Action = {
  type: string;
};

const inDashboard = window.location.href?.includes('in-dashboard');

export const UI_BUILDER_MODES = {
  construction: 'construction',
  preview: 'preview',
};

const initialState = {
  mode: inDashboard ? UI_BUILDER_MODES.construction : UI_BUILDER_MODES.preview,
};

export const actions = {
  modeRequestPreview: '@builder/mode-req/preview',
  modeRequestConstruction: '@builder/mode-req/construction',
};

const DashboardContext = React.createContext(initialState);
DashboardContext.displayName = 'DashboardContext';

type DashboardProviderProps = {
  children?: React.ReactNode;
};

const reducer = (state: initialStateType, action: Action) => {
  switch (action.type) {
    case actions.modeRequestPreview:
      return {
        ...state,
        mode: UI_BUILDER_MODES.preview,
      };
    case actions.modeRequestConstruction:
      return {
        ...state,
        mode: UI_BUILDER_MODES.construction,
      };
    default:
      return state;
  }
};

export const DashboardProvider: React.FC<DashboardProviderProps> = ({
  children,
}): JSX.Element | null => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    const listener = (event: {
      origin: string;
      data: { source: string; type: string; payload: Record<string, unknown> };
    }) => {
      if (
        event.origin !== window.location.origin ||
        event?.data?.source !== 'APP_BUILDER_SOURCE' ||
        !event?.data?.type
      ) {
        return;
      }

      const { type, payload } = event.data;

      if ([actions.modeRequestPreview, actions.modeRequestConstruction].includes(type)) {
        dispatch({
          type,
          ...(payload || {}),
        });
      }
    };

    window.addEventListener('message', listener, false);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  const value = {
    mode: state.mode,
  };

  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
};

export const useDashboardHook = (): {
  mode: string;
} => {
  const context = React.useContext(DashboardContext);

  if (context === undefined) {
    throw new Error('useDashboardHook must be used within a DashboardProvider');
  }

  return {
    mode: context.mode,
  };
};
