import { useMemo } from 'react';

import { useAuth } from '@8base-react/auth';
import { useQuery } from '@apollo/client';

import {
  AvailableWorkspacesListQuery,
  SystemWorkspaceItem,
  WorkspacesListQuery,
} from '../__generated__';
import { BACKEND_WORKSPACES_LIST_QUERY, FRONTEND_WORKSPACES_LIST_QUERY } from '../fragments';
import { useCurrentWorkspaceID } from 'src/shared/hooks/useCurrentWorkspaceID';

type UseCurrentWorkspaceReturn = {
  workspace?: SystemWorkspaceItem;
  backendWorkspaceId?: string;
  backendWorkspaceApiHost?: string;
  loading: boolean;
};

export const useCurrentWorkspace = (): UseCurrentWorkspaceReturn => {
  const { isAuthorized } = useAuth();
  const { workspaceID } = useCurrentWorkspaceID();

  const { data, loading } = useQuery<WorkspacesListQuery>(FRONTEND_WORKSPACES_LIST_QUERY, {
    skip: !workspaceID || !isAuthorized,
  });

  const {
    data: backendQueryData,
    loading: backendQueryLoading,
  } = useQuery<AvailableWorkspacesListQuery>(BACKEND_WORKSPACES_LIST_QUERY);

  const workspace: SystemWorkspaceItem = useMemo(
    () => data?.system?.workspacesFrontendList?.items.find(item => item.id === workspaceID),
    [data, workspaceID],
  ) as SystemWorkspaceItem;

  const backendWorkspace = useMemo(
    () =>
      backendQueryData?.system?.workspacesList?.items.find(
        WS => WS?.project?.id === workspace?.project?.id,
      ),
    [backendQueryData?.system?.workspacesList?.items, workspace?.project?.id],
  );

  return {
    workspace,
    backendWorkspaceId: backendWorkspace?.id,
    backendWorkspaceApiHost: backendWorkspace?.apiHost as string | undefined,
    loading: loading || backendQueryLoading,
  };
};
