import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import styled from '@emotion/styled';
import { CircularProgress, Dialog, Typography } from '@mui/material';

import { useDeployContext } from '../../../providers/DeployProvider';
import { DASHBOARD_DIALOGS } from '../dialogsMap';
import { DeployProgress } from 'src/features/deploy/deploy-progress';
import { useDialogState } from 'src/providers';
import { Button } from 'src/shared/components';
import { DEPLOY_STATUSES } from 'src/shared/constants';
import { useDeploySettingsHooks } from 'src/shared/graphql/hooks';

import {
  BACKGROUND_ICON_COLORS,
  BackgroundWrapperPaper,
} from './components/BackgroundWrapperPaper';
import {
  ButtonsWrapper,
  StyledCancelOutlinedIcon,
  StyledDoneIcon,
} from './DeployProgressDialog.styles';

type DeployProgressDialogArgs = {
  buildName: string;
};

const DIALOG_ID = DASHBOARD_DIALOGS.DEPLOY_PROGRESS_DIALOG_ID;

const DialogWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  max-width: 1040px;
`;

const DeployProgressWrapper = styled.div`
  width: 100%;
  max-height: 320px;
  overflow: auto;
`;

export const DeployProgressDialog: React.FC = () => {
  const { isOpen, closeDialog } = useDialogState<DeployProgressDialogArgs>(DIALOG_ID);
  const { deploy, isLoadingDeployResult } = useDeployContext();
  const { deploySettingsResult } = useDeploySettingsHooks();
  const projectUrl = useMemo(() => {
    return deploySettingsResult.data?.appbuilder?.settings?.projectUrl as string;
  }, [deploySettingsResult]);

  const handleDialogClose = useCallback(() => {
    closeDialog(DIALOG_ID);
  }, [closeDialog]);

  // When we perform first deployment we assign update settings (ex: projectUrl)
  useEffect(() => {
    if (deploy) {
      deploySettingsResult.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deploy, deploySettingsResult.refetch]);

  const backgroundIconColor =
    deploy?.status === DEPLOY_STATUSES.resolved
      ? BACKGROUND_ICON_COLORS.green
      : BACKGROUND_ICON_COLORS.red;

  const deployWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(
    function scrollDeployLogsToBottom() {
      if (deploy?.status === DEPLOY_STATUSES.pending) {
        const documentNode = deployWrapperRef.current;

        if (documentNode) {
          const timeoutId = setInterval(() => {
            documentNode.scroll({
              top: documentNode.scrollHeight,
              behavior: 'smooth',
            });
          }, 1000);

          const scrollEventListener = () => {
            clearInterval(timeoutId);
          };

          documentNode.addEventListener('wheel', scrollEventListener);

          return () => {
            clearInterval(timeoutId);
            documentNode.removeEventListener('wheel', scrollEventListener);
          };
        }
      }
    },
    [deploy],
  );

  return (
    <Dialog
      fullScreen
      open={isOpen}
      PaperComponent={props => (
        <BackgroundWrapperPaper {...props} backgroundIconColor={backgroundIconColor} />
      )}
    >
      <DialogWrapper>
        <Choose>
          <When condition={isLoadingDeployResult}>
            <CircularProgress color="error" />
          </When>
          <Otherwise>
            <Choose>
              <When condition={!deploy || deploy?.status === DEPLOY_STATUSES.pending}>
                <CircularProgress color="error" />
                <Typography variant="h3">Deployment in progress</Typography>
                <DeployProgressWrapper ref={deployWrapperRef}>
                  <DeployProgress deploy={deploy} />
                </DeployProgressWrapper>
                <Button variant="contained" onClick={handleDialogClose}>
                  Go Back
                </Button>
              </When>
              <When condition={deploy?.status === DEPLOY_STATUSES.resolved}>
                <StyledDoneIcon color="success" />
                <Typography variant="h3">Workspace deployed</Typography>
                <Typography variant="h4" fontWeight="normal" lineHeight="1.6" textAlign="center">
                  {deploy?.message}
                </Typography>
                <ButtonsWrapper>
                  <Button variant="contained" color="default" onClick={handleDialogClose}>
                    Go Back
                  </Button>
                  <Button
                    variant="contained"
                    disabled={!projectUrl}
                    href={projectUrl ?? ''}
                    target="_blank"
                  >
                    Open App
                  </Button>
                </ButtonsWrapper>
              </When>
              <When condition={deploy?.status === DEPLOY_STATUSES.rejected}>
                <StyledCancelOutlinedIcon color="error" />
                <Typography variant="h3">Deployment failed</Typography>
                <Typography variant="h4" fontWeight="normal" lineHeight="1.6">
                  {deploy?.message}
                </Typography>
                <DeployProgressWrapper>
                  <DeployProgress deploy={deploy} />
                </DeployProgressWrapper>
                <Button variant="contained" onClick={handleDialogClose}>
                  Go Back
                </Button>
              </When>
            </Choose>
          </Otherwise>
        </Choose>
      </DialogWrapper>
    </Dialog>
  );
};
