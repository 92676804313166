import { isEmpty, values, omit } from 'ramda';
import { PropChecker, componentListSelectors, NodeChecker, nodeSelectors, PropAssert, appNodesSchemaSelectors, } from '@builder/schemas';
import { isObject, isString } from '@builder/utils';
import { SymbolAccessor } from '../..';
import { OUTPUT_PROP_MODES } from '../../../constants';
import { transformVariableName } from '../../../utils/transformVariableName';
import { transformCondition, transformIterator, getTransformIteratorIndexName, } from '../node-transformers';
import { transformPropsByMode } from '../prop-transformers';
import { LOCAL_STATES_CONST, PROP_VALID_TYPE, ROOT_HOOK } from './constants';
export class CodeNodeBaseGenerator {
    constructor({ nodeListDSL, componentListDSL, assetListDSL, options, }) {
        var _a;
        this.componentListDSL = componentListDSL;
        this.nodeListDSL = nodeListDSL;
        this.assetListDSL = assetListDSL;
        this.stateListDSL = options.stateListDSL;
        this.rootNodeID = options.rootNodeID;
        this.applyMinSizeStyles = (_a = options.applyMinSizeStyles) !== null && _a !== void 0 ? _a : false;
        this.symbolAccessor = new SymbolAccessor(componentListDSL);
    }
    removeInnerQuotes(str) {
        // Validates if the value is a string and is enclosed in quotes (each string is enclosed in double quotes)
        if (/^".*"$/.test(str)) {
            // Remove all internal quotes (Double ", and single ').
            return `"${str.replace(/["']/g, '')}"`;
        }
        return str;
    }
    convertPropsMapToString(otherPropsMap) {
        const otherPropsString = Object.keys(otherPropsMap).reduce((accum, propName) => {
            const isVariableRegexp = /"{{(\w+| [^ ]+|$)/gm; // checks if string starts with "{{
            if (typeof otherPropsMap[propName] === PROP_VALID_TYPE &&
                isVariableRegexp.test(otherPropsMap[propName])) {
                if (otherPropsMap[propName].match(/{{.*?}}/g)) {
                    return `${accum} ${propName}=${otherPropsMap[propName]}`;
                }
                return `${accum} ${propName}=${otherPropsMap[propName]
                    .replace('"{{', '{')
                    .replace('}}"', '}')}`;
            }
            const isStringEmpty = (str) => {
                return str === null || str.match(/^""$/) !== null;
            };
            if (propName === 'primaryField' && isStringEmpty(otherPropsMap[propName])) {
                return accum;
            }
            if (propName === 'htmlFragment') {
                const prop = `${accum} ${propName}`;
                const value = `${otherPropsMap[propName]}`;
                if (value.startsWith('{') || value.includes('.value') || value.includes('.fn')) {
                    return `${prop}=${value}`;
                }
                return `${prop}={\`${value}\`}`;
            }
            return `${accum} ${propName}=${this.removeInnerQuotes(otherPropsMap[propName])}`;
        }, ``);
        if (otherPropsString.includes('htmlFragment')) {
            const result = otherPropsString
                .replaceAll('htmlFragment={`"', 'htmlFragment={`')
                .replaceAll('"`}', '`}');
            return result;
        }
        return otherPropsString;
    }
    /**
     * Adds min size styles before regular prop transformation.
     */
    beforeStylePropTransformation(nodeDSL) {
        var _a;
        const minSizeStyleProp = appNodesSchemaSelectors.getMinSizeStyles({ nodeListDSL: this.nodeListDSL, componentListDSL: this.componentListDSL }, {
            nodeID: nodeDSL.id,
        });
        const beforeStyle = (_a = nodeDSL.props.style) !== null && _a !== void 0 ? _a : {};
        if (!isObject(minSizeStyleProp) ||
            !this.applyMinSizeStyles ||
            PropChecker.Value.isJSInjectionProp(beforeStyle)) {
            return nodeDSL;
        }
        PropAssert.Value.assertIsObjectProp(beforeStyle, 'style');
        return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { style: Object.assign(Object.assign({}, minSizeStyleProp), beforeStyle) }) });
    }
    beforeTransformNodeProp(nodeDSL) {
        return this.beforeStylePropTransformation(nodeDSL);
    }
    afterTransformNodeProp(args) {
        return args;
    }
    transformNodeProp(nodeDSL) {
        const nodeAlias = transformVariableName(nodeSelectors.getNodeAlias(nodeDSL, {
            componentListDSL: this.componentListDSL,
        }));
        const transformedNodeDSL = this.beforeTransformNodeProp(nodeDSL);
        const { children, otherPropsMap } = transformPropsByMode({
            nodePropData: {
                nodeDSL: transformedNodeDSL,
                componentListDSL: this.componentListDSL,
                entityName: nodeAlias,
            },
            outputMode: OUTPUT_PROP_MODES.jsx,
            generateNode: this.generateNode.bind(this),
            assetListDSL: this.assetListDSL,
            stateListDSL: this.stateListDSL,
        });
        return this.afterTransformNodeProp({ nodeDSL, children, otherPropsMap });
    }
    getLocalStates(ComponentName, nodeDSL) {
        if (ComponentName === ROOT_HOOK) {
            const statesNames = values(this.stateListDSL)
                .filter(state => { var _a; return (_a = nodeDSL.states) === null || _a === void 0 ? void 0 : _a.some(nodeState => nodeState.stateID === state.id); })
                .map(state => state.name);
            if (!isEmpty(statesNames)) {
                return `${LOCAL_STATES_CONST}={{${statesNames.join(', ')}}}`;
            }
        }
        return '';
    }
    /**
     * It takes a the node of RichTextComponent, and converts it to a string of html.
     * @param {string} ComponentName - The name of the component that will be rendered.
     * @param {NodeDSL} nodeDSL - The nodeDSL object that was passed to the generateNode function.
     * @param otherPropsMap - This is a map of all the props that are not content.
     * @returns A string of JSX code.
     */
    generateRichTextNode(ComponentName, nodeDSL, otherPropsMap) {
        const { content } = nodeDSL.props;
        const otherPropsString = this.convertPropsMapToString(omit(['content'], otherPropsMap));
        const formattedContent = (content === null || content === void 0 ? void 0 : content.replace(/{{(.*?)}}/g, '{$1}').replace(/<br>/g, '<br />')) || '';
        return `<${ComponentName} ${otherPropsString}>${formattedContent}</${ComponentName}>`;
    }
    /**
     * Transform node DSL to react jsx string.
     */
    transformNodeDSLToJSXString(ComponentName, nodeDSL) {
        var _a, _b, _c;
        const { children, otherPropsMap } = this.transformNodeProp(nodeDSL);
        const otherPropsString = this.convertPropsMapToString(otherPropsMap);
        /// HERE
        if (NodeChecker.Value.isRichTextNode(nodeDSL)) {
            return this.generateRichTextNode(ComponentName, nodeDSL, otherPropsMap);
        }
        if (PropChecker.Value.isEmptyProp((_a = nodeDSL.props) === null || _a === void 0 ? void 0 : _a.children) ||
            (PropChecker.Value.isRenderableProp((_b = nodeDSL.props) === null || _b === void 0 ? void 0 : _b.children) &&
                PropChecker.Value.isRenderablePropEmpty((_c = nodeDSL.props) === null || _c === void 0 ? void 0 : _c.children))) {
            return `<${ComponentName} ${otherPropsString} />`;
        }
        const localStates = this.getLocalStates(ComponentName, nodeDSL);
        return `
      <${ComponentName} ${localStates} ${otherPropsString}>
        ${children}
      </${ComponentName}>`;
    }
    generateNodeBasic(nodeDSL, { outputMode }) {
        const componentDSL = componentListSelectors.getComponentDSL(this.componentListDSL, {
            componentName: nodeDSL.name,
        });
        if (NodeChecker.Value.isReactImportComponent(componentDSL)) {
            const { componentName } = componentDSL.source;
            return this.transformNodeDSLToJSXString(componentName, nodeDSL);
        }
        if (NodeChecker.Value.isHTMLElementComponent(componentDSL)) {
            const componentName = componentDSL.source.htmlElement;
            return this.transformNodeDSLToJSXString(componentName, nodeDSL);
        }
        if (NodeChecker.Value.isTextComponent(componentDSL)) {
            const nodeAlias = transformVariableName(nodeSelectors.getNodeAlias(nodeDSL, {
                componentListDSL: this.componentListDSL,
            }));
            const { children } = transformPropsByMode({
                nodePropData: {
                    nodeDSL,
                    componentListDSL: this.componentListDSL,
                    entityName: nodeAlias,
                },
                outputMode,
                assetListDSL: this.assetListDSL,
                stateListDSL: this.stateListDSL,
            });
            return children !== null && children !== void 0 ? children : ' ';
        }
        if (NodeChecker.Value.isSymbolComponent(componentDSL)) {
            return this.transformNodeDSLToJSXString(this.symbolAccessor.getSymbolComponentName(componentDSL.name), nodeDSL);
        }
        return '';
    }
    generateNodeWithIterator(nodeDSL, { outputMode }) {
        const iterator = nodeSelectors.getIterator(nodeDSL);
        if (iterator) {
            const transformedIndexName = getTransformIteratorIndexName(iterator);
            const nodeText = this.generateNodeBasic(Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { key: `{{ ${transformedIndexName} }}` }) }), { outputMode });
            const transformedNodeText = transformIterator(iterator, nodeText);
            return outputMode === OUTPUT_PROP_MODES.jsx
                ? `{ ${transformedNodeText} }`
                : transformedNodeText;
        }
        return this.generateNodeBasic(nodeDSL, { outputMode });
    }
    generateNodeWithCondition(nodeDSL, { outputMode }) {
        const hasConditionRule = nodeSelectors.hasConditionRule(nodeDSL);
        const hasAllowedRolesRule = nodeSelectors.hasAllowedRolesRule(nodeDSL);
        const nodeText = this.generateNodeWithIterator(nodeDSL, { outputMode });
        if (hasConditionRule || hasAllowedRolesRule) {
            const showIfCondition = nodeSelectors.getShowIfCondition(nodeDSL);
            const allowedRoles = nodeSelectors.getAllowedRoles(nodeDSL);
            const transformedCondition = transformCondition(showIfCondition, allowedRoles);
            const transformedNodeText = `${transformedCondition} && (${nodeText})`;
            const reg = new RegExp(/{{\s*}}/g);
            const regexValidation = reg.test(showIfCondition);
            if (isString(showIfCondition) && regexValidation)
                return nodeText;
            return outputMode === OUTPUT_PROP_MODES.jsx
                ? `{ ${transformedNodeText} }`
                : transformedNodeText;
        }
        return nodeText;
    }
    generateNode(nodeID, { outputMode }) {
        const nodeDSL = isString(nodeID) ? this.nodeListDSL[nodeID] : nodeID;
        if (!(nodeDSL === null || nodeDSL === void 0 ? void 0 : nodeDSL.name)) {
            return '';
        }
        return this.generateNodeWithCondition(nodeDSL, { outputMode });
    }
    runGeneratingNode() {
        return this.generateNode(this.rootNodeID, { outputMode: OUTPUT_PROP_MODES.jsx });
    }
}
