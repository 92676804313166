import React, { useEffect } from 'react';

import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { $selectAll } from '@lexical/selection';
import {
  $getRoot,
  $insertNodes,
  $getSelection,
  EditorState,
  LexicalEditor,
  $isRangeSelection,
  EditorThemeClasses,
} from 'lexical';
import debounce from 'lodash/debounce';

import {
  ComponentSettingChildrenTextRichElementDSL,
  componentSettingSelectors,
  NodePropValue,
} from '@builder/schemas';

import { useTheme } from '../../../providers/ThemeProvider';
import { useAppDispatch, useUIBuilderState } from 'src/providers';
import { NODE_SETTINGS_TABS, RIGHT_PANEL_TABS, UI_BUILDER_EVENTS } from 'src/store';

import ListMaxIndentLevelPlugin from './maxIndentPlugin';
import Toolbar from './Toolbar';

const EditorBody = styled.div`
  text-align: initial;
`;

const EditorControls = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;

  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(6, 42px);
  grid-template-rows: 1fr;
`;

const EditorInputArea = styled.div`
  overflow-y: auto;
  max-height: 130px;
  min-height: 130px;
  padding: 0 8px;
`;

const EditorInputCss = ({ theme }: { theme: Theme }) => css`
  border: 1px solid ${theme.palette.border.input};
  border-radius: ${theme.shape.borderRadius}px;
  background-color: ${theme.palette.background.input};
  a:link,
  a:active {
    color: #398ff9;
  }
  a:visited {
    color: #c939f9;
  }
  &:hover {
    border-color: ${theme.palette.primary.main}B3;
  }
  // focus is not working because the external component is not using input field
  &:focus {
    border-color: ${theme.palette.primary.main};
  }
  .editor-textItalic {
    font-style: italic;
  }
  .editor-textBold {
    font-weight: 700;
  }
  .editor-nested-listitem {
    list-style-type: none;
  }
  .editor-lista__ol1 {
    list-style-position: inside;
  }
  .editor-lista__ol2 {
    list-style-type: upper-alpha;
    list-style-position: inside;
  }
  .editor-lista__ol3 {
    list-style-type: lower-alpha;
    list-style-position: inside;
  }
  .editor-lista__ol4 {
    list-style-type: upper-roman;
    list-style-position: inside;
  }
  .editor-lista__ol5 {
    list-style-type: lower-roman;
    list-style-position: inside;
  }
`;

/**
 * Thin wrapper around lexical components
 */
export const RichTextEditor = (props: {
  setting: ComponentSettingChildrenTextRichElementDSL;
  onChange: (propData: { keyValue: unknown; keyPath: (string | number)[] }) => void;
  dataTest: string;
  targetNodeId: string;
  keyValue: NodePropValue<string> | null;
}): JSX.Element => {
  const lexicalTheme: EditorThemeClasses = {
    paragraph: 'editor-paragraph',
    quote: 'editor-quote',
    heading: {
      h1: 'editor-heading-h1',
      h2: 'editor-heading-h2',
      h3: 'editor-heading-h3',
      h4: 'editor-heading-h4',
      h5: 'editor-heading-h5',
      h6: 'editor-heading-h6',
    },
    list: {
      nested: {
        listitem: 'editor-nested-listitem',
      },
      ol: 'editor-lista-ol',
      ul: 'editor-lista-ul',
      listitem: 'editor-listItem',
      listitemChecked: 'editor-listItemChecked',
      listitemUnchecked: 'editor-listItemUnchecked',
      olDepth: [
        'editor-lista__ol1',
        'editor-lista__ol2',
        'editor-lista__ol3',
        'editor-lista__ol4',
        'editor-lista__ol5',
      ],
    },
    link: 'editor-link',
    text: {
      bold: 'editor-textBold',
      italic: 'editor-textItalic',
    },
  };
  const initialConfig = {
    namespace: '8baseEditor',
    theme: lexicalTheme,
    onError: (error: Error, errorEditor: LexicalEditor) => {
      console.error(error, errorEditor);
    },
    nodes: [LinkNode, AutoLinkNode, ListNode, ListItemNode, QuoteNode, HeadingNode],
  };
  return (
    <LexicalComposer initialConfig={initialConfig}>
      <RichEditor {...props} />
    </LexicalComposer>
  );
};

const RichEditor = (props: {
  setting: ComponentSettingChildrenTextRichElementDSL;
  onChange: (propData: { keyValue: unknown; keyPath: (string | number)[] }) => void;
  dataTest: string;
  targetNodeId: string;
  keyValue: NodePropValue<string> | null;
}): JSX.Element => {
  const { onChange, dataTest, setting, keyValue, targetNodeId } = props;
  const { nodeSettingsArgs } = useUIBuilderState().rightPanel;
  const theme = useTheme();
  const send = useAppDispatch();
  const [editor] = useLexicalComposerContext();

  const onEditorChange = debounce((editorState: EditorState, callbackEditor: LexicalEditor) => {
    editorState.read(() => {
      const html = $generateHtmlFromNodes(callbackEditor, null);
      const replaced = html.replaceAll('text-indent', 'padding-inline-start');

      onChange({
        keyValue: replaced,
        keyPath: componentSettingSelectors.getSettingsKeyPath(setting),
      });
    });
  }, 100);

  // Set Lexical content on first mount
  useEffect(() => {
    editor.update(() => {
      if (keyValue !== '<p><br></p>') {
        const parser = new DOMParser();
        const dom = parser.parseFromString(keyValue as string, 'text/html');
        const nodes = $generateNodesFromDOM(editor, dom);
        const root = $getRoot();
        root.select();
        $insertNodes(nodes);
      }

      const shouldFocusInputRef =
        nodeSettingsArgs?.focusLabelForCurrentNode?.nodeId === targetNodeId &&
        !nodeSettingsArgs?.focusLabelForCurrentNode?.focused;
      if (shouldFocusInputRef) {
        send({
          type: UI_BUILDER_EVENTS.updateRightPanel,
          rightPanel: {
            currentTab: RIGHT_PANEL_TABS.componentList,
            nodeSettingsArgs: {
              currentTab: NODE_SETTINGS_TABS.properties,
              focusLabelForCurrentNode: {
                inputLabelToFocus: nodeSettingsArgs?.focusLabelForCurrentNode?.nodeId as string,
                focused: true,
                nodeId: targetNodeId,
              },
            },
          },
        });

        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $selectAll(selection);
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <EditorBody>
      <EditorControls>
        <Toolbar />
      </EditorControls>

      <EditorInputArea data-test={dataTest} css={EditorInputCss({ theme })}>
        <RichTextPlugin
          contentEditable={
            <ContentEditable style={{ border: 'none', outline: 'none', height: '105px' }} />
          }
          placeholder={null}
          ErrorBoundary={LexicalErrorBoundary}
        />
      </EditorInputArea>

      {/* PLUGINS */}
      <HistoryPlugin />
      <LinkPlugin />
      <ListPlugin />
      <ListMaxIndentLevelPlugin maxDepth={5} />
      <TabIndentationPlugin />
      <OnChangePlugin onChange={onEditorChange} />
      <ClearEditorPlugin />
    </EditorBody>
  );
};
