import { COMPONENT_SETTING_CONDITION_TYPES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants/component-settings';
import { createSelectOptionByValue } from '../../../utils';
import { SIZE_POSSIBLE_UNITS, STYLE_SECTION_TYPES, FONT_POSSIBLE_UNITS, BORDER_RADIUS_POSSIBLE_UNITS, BORDER_WIDTH_POSSIBLE_UNITS, NS_ID, DISPLAY_OPTION_VALUES, GRID_AUTOFLOW_OPTIONS, GRID_PLACE_SELF_OPTIONS, GRID_PLACE_CONTENT_OPTIONS, GRID_PLACE_ITEMS_OPTIONS, } from '../constants';
import { getDivider } from './commonSettings';
export const getLayoutSection = (prefixPath = []) => ({
    type: COMPONENT_SETTING_TYPES.section,
    title: 'Layout',
    titleDescription: {
        title: 'Layout',
        subtitle: 'Attributes to define the layout organization of this component children',
    },
    name: NS_ID.style.display.root,
    children: [
        {
            componentView: COMPONENT_SETTING_VIEWS.radio,
            type: COMPONENT_SETTING_TYPES.prop,
            name: NS_ID.style.display.display,
            keyPath: [...prefixPath, 'style', 'display'],
            label: 'Display',
            options: [
                {
                    value: DISPLAY_OPTION_VALUES.block,
                    icon: 'displayBlock',
                    tooltip: 'Block elements start on a new line and take up the full available width.',
                },
                {
                    value: DISPLAY_OPTION_VALUES.flex,
                    icon: 'displayFlex',
                    tooltip: 'Flex lays out its child elements on a horizontal or vertical axis',
                },
                {
                    value: DISPLAY_OPTION_VALUES.grid,
                    icon: 'displayGrid',
                    tooltip: 'Grid lets you place items within rows and columns',
                },
                {
                    value: DISPLAY_OPTION_VALUES.inlineBlock,
                    icon: 'displayInlineBlock',
                    tooltip: 'Inline-block behaves like inline, but accepts width and height properties',
                },
                {
                    value: DISPLAY_OPTION_VALUES.inline,
                    icon: 'displayInline',
                    tooltip: `Inline is the default for text content. The text's font size and line height determine its size`,
                },
                {
                    value: DISPLAY_OPTION_VALUES.none,
                    icon: 'visibilityOff',
                    tooltip: 'None hides elements',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'gridTemplate'],
            label: 'Grid Template',
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'grid',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'gridTemplateAreas'],
            label: 'Grid Template Areas',
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'grid',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'gridTemplateColumns'],
            label: 'Grid Template Columns',
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'grid',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'gridTemplateRows'],
            label: 'Grid Template Rows',
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'grid',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'gridAutoColumns'],
            label: 'Grid Auto Columns',
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'grid',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'gridAutoRows'],
            label: 'Grid Auto Rows',
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'grid',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.select,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'gridAutoFlow'],
            label: 'Grid Auto Flow',
            options: GRID_AUTOFLOW_OPTIONS.map(createSelectOptionByValue),
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'grid',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'gridColumnGap'],
            label: 'Grid Column Gap',
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'grid',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'gridRowGap'],
            label: 'Grid Row Gap',
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'grid',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'gridGap'],
            label: 'Grid Gap',
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'grid',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.select,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'placeContent'],
            label: 'Place Content',
            options: GRID_PLACE_CONTENT_OPTIONS.map(createSelectOptionByValue),
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'grid',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.select,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'placeItems'],
            label: 'Place Items',
            options: GRID_PLACE_ITEMS_OPTIONS.map(createSelectOptionByValue),
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'grid',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.radio,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'alignItems'],
            label: 'Align Items',
            options: [
                {
                    value: 'start',
                    icon: 'styleAlignStart',
                    tooltip: 'Start',
                },
                {
                    value: 'center',
                    icon: 'styleAlignCenter',
                    tooltip: 'Center',
                },
                {
                    value: 'end',
                    icon: 'styleAlignEnd',
                    tooltip: 'End',
                },
                {
                    value: 'stretch',
                    icon: 'styleAlignStretch',
                    tooltip: 'Stretch',
                },
            ],
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'grid',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.radio,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'alignContent'],
            label: 'Align Content',
            options: [
                {
                    value: 'start',
                    icon: 'styleJustifyStart',
                    tooltip: 'Start',
                },
                {
                    value: 'center',
                    icon: 'styleJustifyCenter',
                    tooltip: 'Center',
                },
                {
                    value: 'end',
                    icon: 'styleJustifyEnd',
                    tooltip: 'End',
                },
                {
                    value: 'stretch',
                    icon: 'styleAlignStretch',
                    tooltip: 'Stretch',
                },
                {
                    value: 'space-between',
                    icon: 'styleJustifyBetween',
                    tooltip: 'Space between',
                },
                {
                    value: 'space-around',
                    icon: 'styleJustifyAround',
                    tooltip: 'Space around',
                },
                {
                    value: 'space-evenly',
                    icon: 'SE',
                    isTextIcon: true,
                    tooltip: 'Space evenly',
                },
            ],
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'grid',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.radio,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'justifyItems'],
            label: 'Justify Items',
            options: [
                {
                    value: 'start',
                    icon: 'styleAlignStart',
                    tooltip: 'Start',
                },
                {
                    value: 'center',
                    icon: 'styleAlignCenter',
                    tooltip: 'Center',
                },
                {
                    value: 'end',
                    icon: 'styleAlignEnd',
                    tooltip: 'End',
                },
                {
                    value: 'stretch',
                    icon: 'styleAlignStretch',
                    tooltip: 'Stretch',
                },
            ],
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'grid',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.radio,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'justifyContent'],
            label: 'Justify Content',
            options: [
                {
                    value: 'start',
                    icon: 'styleJustifyStart',
                    tooltip: 'Start',
                },
                {
                    value: 'center',
                    icon: 'styleJustifyCenter',
                    tooltip: 'Center',
                },
                {
                    value: 'end',
                    icon: 'styleJustifyEnd',
                    tooltip: 'End',
                },
                {
                    value: 'stretch',
                    icon: 'styleAlignStretch',
                    tooltip: 'Stretch',
                },
                {
                    value: 'space-between',
                    icon: 'styleJustifyBetween',
                    tooltip: 'Space between',
                },
                {
                    value: 'space-around',
                    icon: 'styleJustifyAround',
                    tooltip: 'Space around',
                },
                {
                    value: 'space-evenly',
                    icon: 'SE',
                    isTextIcon: true,
                    tooltip: 'Space evenly',
                },
            ],
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'grid',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.radio,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'flexDirection'],
            label: 'Direction',
            options: [
                {
                    value: 'row',
                    icon: 'styleArrowRight',
                    tooltip: 'Row',
                },
                {
                    value: 'row-reverse',
                    icon: 'styleArrowLeft',
                    tooltip: 'Row reverse',
                },
                {
                    value: 'column-reverse',
                    icon: 'styleArrowUp',
                    tooltip: 'Column reverse',
                },
                {
                    value: 'column',
                    icon: 'styleArrowDown',
                    tooltip: 'Column',
                },
            ],
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'flex',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.radio,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'alignItems'],
            label: 'Align',
            options: [
                {
                    value: 'flex-start',
                    icon: 'styleAlignStart',
                    tooltip: 'Flex start',
                },
                {
                    value: 'center',
                    icon: 'styleAlignCenter',
                    tooltip: 'Center',
                },
                {
                    value: 'flex-end',
                    icon: 'styleAlignEnd',
                    tooltip: 'Flex end',
                },
                {
                    value: 'stretch',
                    icon: 'styleAlignStretch',
                    tooltip: 'Stretch',
                },
                {
                    value: 'baseline',
                    icon: 'styleAlignBaseline',
                    tooltip: 'Baseline',
                },
            ],
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'flex',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.radio,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'justifyContent'],
            label: 'Justify',
            options: [
                {
                    value: 'flex-start',
                    icon: 'styleJustifyStart',
                    tooltip: 'Flex start',
                },
                {
                    value: 'center',
                    icon: 'styleJustifyCenter',
                    tooltip: 'Center',
                },
                {
                    value: 'flex-end',
                    icon: 'styleJustifyEnd',
                    tooltip: 'Flex end',
                },
                {
                    value: 'space-between',
                    icon: 'styleJustifyBetween',
                    tooltip: 'Space between',
                },
                {
                    value: 'space-around',
                    icon: 'styleJustifyAround',
                    tooltip: 'Space around',
                },
            ],
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'flex',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.radio,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'flexWrap'],
            label: 'Children',
            options: [
                {
                    value: 'nowrap',
                    icon: 'cross',
                    tooltip: 'nowrap',
                },
                {
                    value: 'wrap',
                    icon: 'Wrap',
                    isTextIcon: true,
                    tooltip: '',
                },
                {
                    value: 'wrap-reverse',
                    icon: 'Reverse',
                    isTextIcon: true,
                    tooltip: '',
                },
            ],
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'flex',
                },
            ],
        },
    ],
});
export const getSpacingSection = (prefixPath = []) => ({
    type: COMPONENT_SETTING_TYPES.section,
    title: 'Spacing',
    titleDescription: {
        title: 'Spacing',
        subtitle: 'Attributes to control the distance between this component and neighbors',
    },
    name: NS_ID.style.spacing.root,
    children: [
        {
            componentView: COMPONENT_SETTING_VIEWS.spacingEditor,
            type: COMPONENT_SETTING_TYPES.prop,
            name: NS_ID.style.spacing.editor,
            keyPath: [...prefixPath, 'style'],
            label: 'Spacing',
            skipDebounce: false,
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'columnGap'],
            label: 'Column Gap',
            possibleUnits: SIZE_POSSIBLE_UNITS,
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'flex',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'columnGap'],
            label: 'Column Gap',
            possibleUnits: SIZE_POSSIBLE_UNITS,
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'grid',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'rowGap'],
            label: 'Row Gap',
            possibleUnits: SIZE_POSSIBLE_UNITS,
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'flex',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'rowGap'],
            label: 'Row Gap',
            possibleUnits: SIZE_POSSIBLE_UNITS,
            showIf: [
                {
                    keyPath: [...prefixPath, 'style', 'display'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'grid',
                },
            ],
        },
    ],
});
export const getFlexChildSection = (prefixPath = []) => ({
    type: COMPONENT_SETTING_TYPES.section,
    title: 'Flex Child',
    titleDescription: {
        title: 'Flex child',
        subtitle: 'Attributes to control additional children organization if the parent is using Flex layout',
    },
    name: NS_ID.style.flexChild.root,
    expandedByDefault: false,
    children: [
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'flexGrow'],
            label: 'Flex Grow',
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'flexShrink'],
            label: 'Flex Shrink',
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'flexBasis'],
            label: 'Flex Basis',
            possibleUnits: SIZE_POSSIBLE_UNITS,
        },
    ],
});
export const getGridChildSection = (prefixPath = []) => ({
    type: COMPONENT_SETTING_TYPES.section,
    title: 'Grid Child',
    titleDescription: {
        title: 'Grid child',
        subtitle: 'Attributes to control additional children organization if the parent is using Grid layout',
    },
    name: NS_ID.style.gridChild.root,
    expandedByDefault: false,
    children: [
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'gridArea'],
            label: 'Grid Area',
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'gridColumn'],
            label: 'Grid Column',
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'gridColumnStart'],
            label: 'Grid Column Start',
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'gridColumnEnd'],
            label: 'Grid Column End',
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'gridRow'],
            label: 'Grid Row',
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'gridRowStart'],
            label: 'Grid Row Start',
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'gridRowEnd'],
            label: 'Grid Row End',
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.select,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'placeSelf'],
            label: 'Place Self',
            options: GRID_PLACE_SELF_OPTIONS.map(createSelectOptionByValue),
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.radio,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'alignSelf'],
            label: 'Align Self',
            options: [
                {
                    value: 'start',
                    icon: 'styleAlignStart',
                    tooltip: 'Start',
                },
                {
                    value: 'center',
                    icon: 'styleAlignCenter',
                    tooltip: 'Center',
                },
                {
                    value: 'end',
                    icon: 'styleAlignEnd',
                    tooltip: 'End',
                },
                {
                    value: 'stretch',
                    icon: 'styleAlignStretch',
                    tooltip: 'Stretch',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.radio,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'justifySelf'],
            label: 'Justify Self',
            options: [
                {
                    value: 'start',
                    icon: 'styleAlignStart',
                    tooltip: 'Start',
                },
                {
                    value: 'center',
                    icon: 'styleAlignCenter',
                    tooltip: 'Center',
                },
                {
                    value: 'end',
                    icon: 'styleAlignEnd',
                    tooltip: 'End',
                },
                {
                    value: 'stretch',
                    icon: 'styleAlignStretch',
                    tooltip: 'Stretch',
                },
            ],
        },
    ],
});
export const getSizeSection = (prefixPath = []) => ({
    type: COMPONENT_SETTING_TYPES.section,
    title: 'Size',
    titleDescription: {
        title: 'Size',
        subtitle: 'Attributes to control the sizing of the component',
    },
    name: STYLE_SECTION_TYPES.size,
    expandedByDefault: false,
    children: [
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'width'],
            name: 'width',
            label: 'Width',
            possibleUnits: SIZE_POSSIBLE_UNITS,
            // fixes a problem when resizing a dashboard with an open section for a node
            skipDebounce: true,
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'height'],
            name: 'height',
            label: 'Height',
            possibleUnits: SIZE_POSSIBLE_UNITS,
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'minWidth'],
            label: 'Min Width',
            name: 'minWidth',
            possibleUnits: SIZE_POSSIBLE_UNITS,
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'minHeight'],
            label: 'Min Height',
            name: 'minHeight',
            possibleUnits: SIZE_POSSIBLE_UNITS,
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'maxWidth'],
            label: 'Max Width',
            name: 'maxWidth',
            possibleUnits: SIZE_POSSIBLE_UNITS,
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'maxHeight'],
            label: 'Max Height',
            name: 'maxHeight',
            possibleUnits: SIZE_POSSIBLE_UNITS,
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.radio,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'overflow'],
            label: 'Overflow',
            name: 'overflow',
            options: [
                {
                    value: 'visible',
                    icon: 'visibilityOn',
                    tooltip: 'Visible',
                },
                {
                    value: 'hidden',
                    icon: 'visibilityOff',
                    tooltip: 'Hidden',
                },
                {
                    value: 'scroll',
                    icon: 'styleScroll',
                    tooltip: 'Scroll',
                },
                {
                    value: 'auto',
                    icon: 'styleAuto',
                    tooltip: '',
                },
            ],
        },
    ],
});
export const getTypographySection = (prefixPath = [], title) => ({
    type: COMPONENT_SETTING_TYPES.section,
    title: title || 'Typography',
    titleDescription: {
        title: 'Typography',
        subtitle: 'Attributes to control the style of text within the component',
    },
    name: NS_ID.style.font.root,
    expandedByDefault: false,
    children: [
        {
            componentView: COMPONENT_SETTING_VIEWS.select,
            type: COMPONENT_SETTING_TYPES.customFont,
            keyPath: [...prefixPath, 'style', 'fontFamily'],
            label: 'Font',
            name: NS_ID.style.customFont.fontFamily,
            options: [
                {
                    label: 'Serif',
                    value: 'serif',
                },
                {
                    label: 'Sans-serif',
                    value: 'sans-serif',
                },
                {
                    label: 'Monospace',
                    value: 'monospace',
                },
                {
                    label: 'Arial',
                    value: 'arial',
                },
                {
                    label: 'Arial Black',
                    value: 'arial-black',
                },
                {
                    label: 'Verdana',
                    value: 'verdana',
                },
                {
                    label: 'Times New Roman',
                    value: 'times-new-roman',
                },
                {
                    label: 'Didot',
                    value: 'didot',
                },
                {
                    label: 'Georgia',
                    value: 'georgia',
                },
                {
                    label: 'American Typewriter',
                    value: 'American Typewriter',
                },
                {
                    label: 'Courier',
                    value: 'Courier',
                },
                {
                    label: 'Monaco',
                    value: 'Monaco',
                },
                {
                    label: 'Bradley Hand',
                    value: 'Bradley Hand',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.select,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'fontWeight'],
            label: 'Weight',
            name: NS_ID.style.font.fontWeight,
            options: [
                {
                    label: '100 - Thin',
                    value: 100,
                },
                {
                    label: '200 - Extra Light',
                    value: 200,
                },
                {
                    label: '300 - Light',
                    value: 300,
                },
                {
                    label: '400 - Normal',
                    value: 400,
                },
                {
                    label: '500 - Medium',
                    value: 500,
                },
                {
                    label: '600 - Semi Bold',
                    value: 600,
                },
                {
                    label: '700 - Bold',
                    value: 700,
                },
                {
                    label: '800 - Extra Bold',
                    value: 800,
                },
                {
                    label: '900 - Black',
                    value: 900,
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'fontSize'],
            label: 'Size',
            name: NS_ID.style.font.fontSize,
            possibleUnits: FONT_POSSIBLE_UNITS,
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'lineHeight'],
            label: 'Line height',
            name: NS_ID.style.font.lineHeight,
            possibleUnits: FONT_POSSIBLE_UNITS,
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.colorEditor,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'color'],
            label: 'Color',
            name: NS_ID.style.font.color,
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.radio,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'textAlign'],
            label: 'Align',
            name: NS_ID.style.font.textAlign,
            options: [
                {
                    value: 'left',
                    icon: 'styleTextAlignLeft',
                    tooltip: 'Left',
                },
                {
                    value: 'center',
                    icon: 'styleTextAlignCenter',
                    tooltip: 'Center',
                },
                {
                    value: 'right',
                    icon: 'styleTextAlignRight',
                    tooltip: 'Right',
                },
                {
                    value: 'justify',
                    icon: 'styleTextAlignJustify',
                    tooltip: 'Justify',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.radio,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'fontStyle'],
            label: 'Italicize',
            name: NS_ID.style.font.fontStyle,
            options: [
                {
                    value: 'normal',
                    icon: 'styleFontNormal',
                    tooltip: 'Normal',
                },
                {
                    value: 'italic',
                    icon: 'styleFontItalic',
                    tooltip: 'Italic',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.radio,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'textDecoration'],
            label: 'Decoration',
            name: NS_ID.style.font.textDecoration,
            options: [
                {
                    value: 'none',
                    icon: 'cross',
                    tooltip: '',
                },
                {
                    value: 'line-through',
                    icon: 'styleTextDecorationStrike',
                    tooltip: 'Line through',
                },
                {
                    value: 'underline',
                    icon: 'styleTextDecorationUnderline',
                    tooltip: 'Underline',
                },
                {
                    value: 'overline',
                    icon: 'styleTextDecorationOverline',
                    tooltip: 'Overline',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.radio,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'textTransform'],
            name: NS_ID.style.font.textTransform,
            label: 'Capitalize',
            options: [
                {
                    value: 'none',
                    icon: 'cross',
                    tooltip: '',
                },
                {
                    value: 'uppercase',
                    icon: 'styleTextTransformUppercase',
                    tooltip: 'Uppercase',
                },
                {
                    value: 'capitalize',
                    icon: 'styleTextDecorationCapitalize',
                    tooltip: 'Capitalize',
                },
                {
                    value: 'lowercase',
                    icon: 'styleTextDecorationLowercase',
                    tooltip: 'Lowercase',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.radio,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'direction'],
            label: 'Direction',
            name: NS_ID.style.font.direction,
            options: [
                {
                    value: 'none',
                    icon: 'styleTextDirectionLtr',
                    tooltip: 'ltr',
                },
                {
                    value: 'rtl',
                    icon: 'styleTextDirectionRtl',
                    tooltip: 'rtl',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.select,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'whiteSpace'],
            label: 'White Space',
            name: NS_ID.style.font.whiteSpace,
            options: [
                {
                    value: 'normal',
                    label: 'Normal',
                },
                {
                    value: 'nowrap',
                    label: 'No Wrap',
                },
                {
                    value: 'pre',
                    label: 'Pre',
                },
                {
                    value: 'pre-wrap',
                    label: 'Pre Wrap',
                },
                {
                    value: 'break-spaces',
                    label: 'Break Spaces',
                },
            ],
        },
    ],
});
export const getBackgroundSection = (prefixPath = []) => ({
    type: COMPONENT_SETTING_TYPES.section,
    title: 'Background',
    titleDescription: {
        title: 'Background',
        subtitle: 'Attributes to define the style of the component background',
    },
    name: NS_ID.style.background.root,
    expandedByDefault: false,
    children: [
        {
            componentView: COMPONENT_SETTING_VIEWS.colorEditor,
            type: COMPONENT_SETTING_TYPES.prop,
            name: NS_ID.style.background.backgroundColor,
            keyPath: [...prefixPath, 'style', 'backgroundColor'],
            label: 'Color',
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.assetEditor,
            type: COMPONENT_SETTING_TYPES.prop,
            name: NS_ID.style.background.backgroundImage,
            keyPath: [...prefixPath, 'style', 'backgroundImage'],
            label: 'Image',
        },
    ],
});
const getBorderSetting = ({ prefixPath = [], prefixStyle, showIf, }) => [
    {
        componentView: COMPONENT_SETTING_VIEWS.radio,
        type: COMPONENT_SETTING_TYPES.prop,
        name: STYLE_SECTION_TYPES.border,
        label: 'Style',
        keyPath: [...prefixPath, 'style', `${prefixStyle}Style`],
        options: [
            {
                value: 'none',
                icon: 'cross',
                tooltip: '',
            },
            {
                value: 'solid',
                icon: 'styleBorderStyleSolid',
                tooltip: '',
            },
            {
                value: 'dashed',
                icon: 'styleBorderStyleDashed',
                tooltip: '',
            },
            {
                value: 'dotted',
                icon: 'styleBorderStyleDotted',
                tooltip: '',
            },
        ],
        showIf,
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', `${prefixStyle}Width`],
        label: 'Width',
        possibleUnits: BORDER_WIDTH_POSSIBLE_UNITS,
        showIf,
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.colorEditor,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', `${prefixStyle}Color`],
        label: 'Color',
        showIf,
    },
];
export const getBorderSection = ({ prefixPath, systemPrefixPath, }) => ({
    type: COMPONENT_SETTING_TYPES.section,
    title: 'Borders',
    titleDescription: {
        title: 'Borders',
        subtitle: 'Attributes to define the component border style',
    },
    name: STYLE_SECTION_TYPES.border,
    expandedByDefault: false,
    children: [
        {
            type: COMPONENT_SETTING_TYPES.mixed,
            label: 'Radius',
            name: 'borderRadius',
            children: [
                {
                    componentView: COMPONENT_SETTING_VIEWS.radio,
                    type: COMPONENT_SETTING_TYPES.prop,
                    keyPath: [...systemPrefixPath, '__borderRadiusMode'],
                    options: [
                        {
                            value: 'singleValue',
                            icon: 'styleBorderRadiusSingleValue',
                            tooltip: '',
                        },
                        {
                            value: 'separateValues',
                            icon: 'styleBorderRadiusSeparateValues',
                            tooltip: '',
                        },
                    ],
                },
                {
                    componentView: COMPONENT_SETTING_VIEWS.text,
                    type: COMPONENT_SETTING_TYPES.prop,
                    keyPath: [...prefixPath, 'style', 'borderRadius'],
                    possibleUnits: BORDER_RADIUS_POSSIBLE_UNITS,
                    hasFxButton: false,
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'borderTopLeftRadius'],
            label: 'Top left',
            icon: 'styleTopLeftCorner',
            possibleUnits: BORDER_RADIUS_POSSIBLE_UNITS,
            showIf: [
                {
                    keyPath: [...systemPrefixPath, '__borderRadiusMode'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'separateValues',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'borderTopRightRadius'],
            label: 'Top right',
            icon: 'styleTopRightCorner',
            possibleUnits: BORDER_RADIUS_POSSIBLE_UNITS,
            showIf: [
                {
                    keyPath: [...systemPrefixPath, '__borderRadiusMode'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'separateValues',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'borderBottomRightRadius'],
            label: 'Bottom right',
            icon: 'styleBottomRightCorner',
            possibleUnits: BORDER_RADIUS_POSSIBLE_UNITS,
            showIf: [
                {
                    keyPath: [...systemPrefixPath, '__borderRadiusMode'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'separateValues',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.text,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...systemPrefixPath, 'style', 'borderBottomLeftRadius'],
            label: 'Bottom left',
            icon: 'styleBottomLeftCorner',
            possibleUnits: BORDER_RADIUS_POSSIBLE_UNITS,
            showIf: [
                {
                    keyPath: [...systemPrefixPath, '__borderRadiusMode'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'separateValues',
                },
            ],
        },
        {
            componentView: COMPONENT_SETTING_VIEWS.radio,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...systemPrefixPath, '__borderMode'],
            options: [
                {
                    value: 'singleValue',
                    icon: 'styleBorderSingleValue',
                    tooltip: '',
                },
                {
                    value: 'separateValues',
                    icon: 'styleBorderSeparateValues',
                    tooltip: '',
                },
            ],
        },
        ...getBorderSetting({
            prefixPath,
            prefixStyle: 'border',
            showIf: [
                {
                    keyPath: [...systemPrefixPath, '__borderMode'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'singleValue',
                },
            ],
        }),
        {
            type: COMPONENT_SETTING_TYPES.popupsSection,
            name: 'borderSettings',
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.popup,
                    name: 'borderTop',
                    label: 'Top',
                    title: 'Top border',
                    icon: 'styleBorderTop',
                    children: getBorderSetting({
                        prefixPath,
                        prefixStyle: 'borderTop',
                        showIf: [
                            {
                                keyPath: [...systemPrefixPath, '__borderMode'],
                                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                value: 'separateValues',
                            },
                        ],
                    }),
                },
                {
                    type: COMPONENT_SETTING_TYPES.popup,
                    name: 'borderRight',
                    label: 'Right',
                    title: 'Right border',
                    icon: 'styleBorderRight',
                    children: getBorderSetting({
                        prefixPath,
                        prefixStyle: 'borderRight',
                        showIf: [
                            {
                                keyPath: [...systemPrefixPath, '__borderMode'],
                                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                value: 'separateValues',
                            },
                        ],
                    }),
                },
                {
                    type: COMPONENT_SETTING_TYPES.popup,
                    name: 'borderBottom',
                    label: 'Bottom',
                    title: 'Bottom border',
                    icon: 'styleBorderBottom',
                    children: getBorderSetting({
                        prefixPath,
                        prefixStyle: 'borderBottom',
                        showIf: [
                            {
                                keyPath: [...systemPrefixPath, '__borderMode'],
                                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                value: 'separateValues',
                            },
                        ],
                    }),
                },
                {
                    type: COMPONENT_SETTING_TYPES.popup,
                    name: 'borderLeft',
                    label: 'Left',
                    title: 'Left border',
                    icon: 'styleBorderLeft',
                    children: getBorderSetting({
                        prefixPath,
                        prefixStyle: 'borderLeft',
                        showIf: [
                            {
                                keyPath: [...systemPrefixPath, '__borderMode'],
                                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                value: 'separateValues',
                            },
                        ],
                    }),
                },
            ],
            showIf: [
                {
                    keyPath: [...systemPrefixPath, '__borderMode'],
                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                    value: 'separateValues',
                },
            ],
        },
    ],
});
export const getCSSSection = (prefixPath = []) => ({
    type: COMPONENT_SETTING_TYPES.section,
    title: 'Custom style',
    titleDescription: {
        title: 'Custom style',
        subtitle: 'Additional CSS styling specific to this component',
    },
    name: STYLE_SECTION_TYPES.css,
    expandedByDefault: false,
    children: [
        {
            componentView: COMPONENT_SETTING_VIEWS.cssEditor,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'css'],
            label: 'Custom style',
        },
    ],
});
export const getCursorSection = (prefixPath = []) => ({
    type: COMPONENT_SETTING_TYPES.section,
    title: 'Cursor',
    titleDescription: {
        title: 'Cursor',
        subtitle: 'Attributes to define the mouse pointer when hovering or interacting with the component',
    },
    name: NS_ID.style.cursor.root,
    expandedByDefault: false,
    children: [
        {
            componentView: COMPONENT_SETTING_VIEWS.select,
            type: COMPONENT_SETTING_TYPES.prop,
            keyPath: [...prefixPath, 'style', 'cursor'],
            label: 'Cursor',
            name: NS_ID.style.cursor.root,
            options: [
                {
                    label: 'Alias',
                    value: 'alias',
                },
                {
                    label: 'All-scroll',
                    value: 'all-scroll',
                },
                {
                    label: 'Auto',
                    value: 'auto',
                },
                {
                    label: 'Cell',
                    value: 'cell',
                },
                {
                    label: 'Context-menu',
                    value: 'context-menu',
                },
                {
                    label: 'Col-resize',
                    value: 'col-resize',
                },
                {
                    label: 'Copy',
                    value: 'copy',
                },
                {
                    label: 'Crosshair',
                    value: 'crosshair',
                },
                {
                    label: 'Default',
                    value: 'default',
                },
                {
                    label: 'E-resize',
                    value: 'e-resize',
                },
                {
                    label: 'Ew-resize',
                    value: 'ew-resize',
                },
                {
                    label: 'Grab',
                    value: 'grab',
                },
                {
                    label: 'Grabbing',
                    value: 'grabbing',
                },
                {
                    label: 'Help',
                    value: 'help',
                },
                {
                    label: 'Move',
                    value: 'move',
                },
                {
                    label: 'N-resize',
                    value: 'n-resize',
                },
                {
                    label: 'Ne-resize',
                    value: 'ne-resize',
                },
                {
                    label: 'Nesw-resize',
                    value: 'nesw-resize',
                },
                {
                    label: 'Ns-resize',
                    value: 'ns-resize',
                },
                {
                    label: 'Nw-resize',
                    value: 'nw-resize',
                },
                {
                    label: 'Nwse-resize',
                    value: 'nwse-resize',
                },
                {
                    label: 'No-drop',
                    value: 'no-drop',
                },
                {
                    label: 'None',
                    value: 'none',
                },
                {
                    label: 'Not-allowed',
                    value: 'not-allowed',
                },
                {
                    label: 'Pointer',
                    value: 'pointer',
                },
                {
                    label: 'Progress',
                    value: 'progress',
                },
                {
                    label: 'Row-resize',
                    value: 'row-resize',
                },
                {
                    label: 'S-resize',
                    value: 's-resize',
                },
                {
                    label: 'Se-resize',
                    value: 'se-resize',
                },
                {
                    label: 'Sw-resize',
                    value: 'sw-resize',
                },
                {
                    label: 'Text',
                    value: 'text',
                },
                {
                    label: 'Url(myBall.cur),auto',
                    value: 'url(myBall.cur),auto',
                },
                {
                    label: 'Vertical-text',
                    value: 'vertical-text',
                },
                {
                    label: 'W-resize',
                    value: 'w-resize',
                },
                {
                    label: 'Wait',
                    value: 'wait',
                },
                {
                    label: 'Zoom-in',
                    value: 'zoom-in',
                },
                {
                    label: 'Zoom-out',
                    value: 'zoom-out',
                },
                {
                    label: 'Initial',
                    value: 'initial',
                },
            ],
        },
    ],
});
export const getStyleSections = (prefixPath = []) => [
    getLayoutSection(prefixPath),
    getDivider(),
    getSpacingSection(prefixPath),
    getDivider(),
    getFlexChildSection(prefixPath),
    getDivider(),
    getGridChildSection(prefixPath),
    getDivider(),
    getSizeSection(prefixPath),
    getDivider(),
    getTypographySection(prefixPath),
    getDivider(),
    getBackgroundSection(prefixPath),
    getDivider(),
    getBorderSection({ prefixPath, systemPrefixPath: prefixPath }),
    getDivider(),
    getCursorSection(prefixPath),
    getDivider(),
    getCSSSection(prefixPath),
];
