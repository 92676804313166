var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "@emotion/react";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef, useState, useEffect, useRef } from 'react';
import { Autocomplete as MuiAutocomplete, TextField, } from '@mui/material';
import { nanoid as uuid } from 'nanoid';
const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
};
export const Autocomplete = forwardRef((_a, ref) => {
    var { disabled, label, className, error, helperText, variant, size, value, open, name, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, id, title, lang, translate } = _a, otherAutocompleteProps = __rest(_a, ["disabled", "label", "className", "error", "helperText", "variant", "size", "value", "open", "name", 'data-test', 'data-node-id', 'data-node-render-path', "id", "title", "lang", "translate"]);
    const previousValue = usePrevious(open);
    const [inputKey, setInputKey] = useState(() => uuid(8));
    useEffect(() => {
        // we check the moment when a component is changing
        // the controlled open state of Autocomplete to be
        // uncontrolled and update inputKey to force remounting on React component.
        if (value === null ||
            value === '' ||
            (previousValue === undefined && open !== undefined) ||
            (open === undefined && previousValue !== undefined)) {
            setInputKey(uuid(8));
        }
    }, [value, open, previousValue]);
    return (_createElement(MuiAutocomplete, Object.assign({}, otherAutocompleteProps, { size: size, key: inputKey, className: className, ref: ref, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, id: id, title: title, lang: lang, translate: translate, value: value, renderInput: params => (_jsx(TextField, Object.assign({}, params, { disabled: disabled, label: label, variant: variant, name: name, error: error, helperText: helperText, inputProps: Object.assign(Object.assign({ autoComplete: 'new-password' }, params.inputProps), { title,
                lang,
                translate }) }))), open: open })));
});
