import { COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps, getCommonSymbolEventProps, getCommonSymbolStyleProps, } from '../../common/commonProps';
import { NavListItemSymbolSettings } from './NavListItemSymbol.settings';
export const NavListItemSymbol = {
    name: COMPONENT_DSL_NAMES.NavListItemSymbol,
    displayName: 'Nav List Item',
    icon: 'navListItem',
    type: COMPONENT_DSL_TYPES.symbol,
    componentListGroupName: MENU_GROUP_NAMES.other,
    schema: {
        mainPropPath: {
            mainProp: 'Text',
        },
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.MaterialList,
                },
            },
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsMenu,
                },
            },
        ],
        presentations: [visibleByCondition],
        supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Text',
        },
        predefineds: {
            props: {
                tooltipPlacement: 'bottom',
                alignItems: 'center',
                listItemText: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialListItemText,
                            props: {
                                children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: 'Nav List Item',
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { path: { type: COMPONENT_DSL_PROP_TYPES.string }, exact: { type: COMPONENT_DSL_PROP_TYPES.boolean }, backgroundColor: { type: COMPONENT_DSL_PROP_TYPES.string }, backgroundImage: { type: COMPONENT_DSL_PROP_TYPES.style }, textColor: { type: COMPONENT_DSL_PROP_TYPES.string }, iconColor: { type: COMPONENT_DSL_PROP_TYPES.string }, selectedBackgroundColor: { type: COMPONENT_DSL_PROP_TYPES.string }, selectedColor: { type: COMPONENT_DSL_PROP_TYPES.string }, selectedIconColor: { type: COMPONENT_DSL_PROP_TYPES.string }, color: { type: COMPONENT_DSL_PROP_TYPES.string }, autoFocus: { type: COMPONENT_DSL_PROP_TYPES.boolean }, selected: { type: COMPONENT_DSL_PROP_TYPES.boolean }, alignItems: { type: COMPONENT_DSL_PROP_TYPES.enum }, dense: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableGutters: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableRipple: { type: COMPONENT_DSL_PROP_TYPES.boolean }, divider: { type: COMPONENT_DSL_PROP_TYPES.boolean }, tooltipTitle: { type: COMPONENT_DSL_PROP_TYPES.string }, tooltipPlacement: { type: COMPONENT_DSL_PROP_TYPES.enum }, startIcon: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, listItemText: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, startIconStyle: { type: COMPONENT_DSL_PROP_TYPES.object } }),
        symbolNodes: {
            name: COMPONENT_DSL_NAMES.Fragment,
            props: {
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialListItemButton,
                            condition: {
                                showIf: '{{ !symbolProps.tooltipTitle }}',
                            },
                            props: Object.assign(Object.assign(Object.assign({}, getCommonSymbolStyleProps()), getCommonSymbolEventProps()), { selected: '{{ symbolProps.selected }}', alignItems: '{{ symbolProps.alignItems }}', autoFocus: '{{ symbolProps.autoFocus }}', dense: '{{ symbolProps.dense }}', disabled: '{{ symbolProps.disabled }}', disableGutters: '{{ symbolProps.disableGutters }}', divider: '{{ symbolProps.divider }}', id: '{{ symbolProps.id }}', title: '{{ symbolProps.title }}', className: '{{ symbolProps.className }}', lang: '{{ symbolProps.lang }}', translate: '{{ symbolProps.translate }}', draggable: '{{ symbolProps.draggable }}', children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Fragment,
                                            condition: {
                                                showIf: '{{ symbolProps.startIcon }}',
                                            },
                                            props: {
                                                children: '{{ [symbolProps.startIcon] }}',
                                            },
                                        },
                                        {
                                            name: COMPONENT_DSL_NAMES.Fragment,
                                            props: {
                                                children: '{{ symbolProps.listItemText }}',
                                            },
                                        },
                                    ],
                                }, style: `{{ {
                  ...symbolProps?.style,
                  backgroundColor: matchPath(location.pathname, { path: symbolProps.path, exact: symbolProps.exact }) ? symbolProps.selectedBackgroundColor : symbolProps.backgroundColor || 'transparent',
                  color: matchPath(location.pathname, { path: symbolProps.path, exact: symbolProps.exact }) ? symbolProps.selectedColor : symbolProps.color,
                } }}`, onClick: {
                                    actionType: 'customCode',
                                    args: {
                                        code: `{{ symbolProps.onClick ? symbolProps.onClick(event) : history.push(symbolProps.path ?? '/') }}`,
                                    },
                                } }),
                        },
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsTooltip,
                            condition: {
                                showIf: '{{ symbolProps.tooltipTitle }}',
                            },
                            props: {
                                placement: '{{ symbolProps.tooltipPlacement }}',
                                title: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: '{{ symbolProps.tooltipTitle }}',
                                            },
                                        },
                                    ],
                                },
                                children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.MaterialListItemButton,
                                            props: Object.assign(Object.assign(Object.assign({}, getCommonSymbolStyleProps()), getCommonSymbolEventProps()), { selected: '{{ symbolProps.selected }}', alignItems: '{{ symbolProps.alignItems }}', autoFocus: '{{ symbolProps.autoFocus }}', dense: '{{ symbolProps.dense }}', disabled: '{{ symbolProps.disabled }}', disableGutters: '{{ symbolProps.disableGutters }}', divider: '{{ symbolProps.divider }}', children: {
                                                    nodes: [
                                                        {
                                                            name: COMPONENT_DSL_NAMES.Fragment,
                                                            condition: {
                                                                showIf: '{{ symbolProps.startIcon }}',
                                                            },
                                                            props: {
                                                                children: '{{ symbolProps.startIcon }}',
                                                            },
                                                        },
                                                        {
                                                            name: COMPONENT_DSL_NAMES.Fragment,
                                                            props: {
                                                                children: '{{ symbolProps.listItemText }}',
                                                            },
                                                        },
                                                    ],
                                                }, style: `{{ {
                          ...symbolProps?.style,
                          backgroundColor: matchPath(location.pathname, { path: symbolProps.path, exact: symbolProps.exact }) ? symbolProps.selectedBackgroundColor : symbolProps.backgroundColor || 'transparent',
                          color: matchPath(location.pathname, { path: symbolProps.path, exact: symbolProps.exact }) ? symbolProps.selectedColor : symbolProps.color
                        } }}`, onClick: {
                                                    actionType: 'customCode',
                                                    args: {
                                                        code: `{{ symbolProps.onClick ? symbolProps.onClick(event) : history.push(symbolProps.path ?? '/') }}`,
                                                    },
                                                } }),
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
            },
        },
    },
    settings: NavListItemSymbolSettings,
};
