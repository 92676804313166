import { useEffect, useState } from 'react';

import { useAuth } from '@8base-react/auth';
import { useHistory } from 'react-router';

import { processEnv, serialize } from '@builder/utils';

import {
  APP_URL,
  AUTH_KEY,
  AUTH_LOGIN_REQUIRED_ERROR_CODE,
  INITIAL_WORKSPACE_ID_KEY,
} from 'src/shared/constants';
import { getWorkspaceIDFromPathname } from 'src/shared/utils/routing';

type AuthSession = {
  email: string;
};

export const useAuthCheckSession = (): { loading: boolean; isAuthorized: boolean } => {
  const history = useHistory();
  const { authClient, isAuthorized } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const workspaceId = getWorkspaceIDFromPathname(history.location.pathname);

      if (workspaceId) {
        localStorage.setItem(INITIAL_WORKSPACE_ID_KEY, workspaceId);
      }

      // Workaround for e2e tests, otherwise cypress throws an error, still no fix
      // https://github.com/cypress-io/cypress/issues/4220
      if (!processEnv.getSkipAuthCheckSession()) {
        try {
          const previousSessionRaw = localStorage.getItem(AUTH_KEY);

          const previousSession = previousSessionRaw
            ? serialize.parse<AuthSession>(previousSessionRaw)
            : undefined;

          const session = await authClient.checkSession();

          if (previousSession && session.email !== previousSession.email) {
            authClient.purgeState();
          }
        } catch (error) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((error as any).code === AUTH_LOGIN_REQUIRED_ERROR_CODE) {
            localStorage.removeItem(AUTH_KEY);
            history.push(APP_URL.public.auth.root);
          }
        }
      }

      setLoading(false);
    })();
  }, [authClient, history]);

  return { loading, isAuthorized };
};
