import { useMemo } from 'react';

import { Key, pathToRegexp } from 'path-to-regexp';
import { useSelector, shallowEqual } from 'react-redux';

import { NodeDSL, nodeListSelectors } from '@builder/schemas';

import { launchNotification } from 'src/providers/NotificationsProvider/components/Snackbar/utils';
import { useAppDispatch, useNodeListDSL } from 'src/providers/ReduxProvider';
import { Router, Store } from 'src/store';

export const useDashboardRouter = (): Router => {
  const routerState = useSelector<Store, Router>(state => state.dashboard.router, shallowEqual);

  return routerState;
};

export const useCurrentPathname = (): string => {
  const dashboardRouterState = useDashboardRouter();

  return dashboardRouterState.currentRoute;
};

export const useDashboardCurrentRouteNodeDSL = (): NodeDSL => {
  const send = useAppDispatch();
  const nodeListDSL = useNodeListDSL();
  const currentPathname = useCurrentPathname();
  const routeContentNode = useDashboardCurrentRouteContentNodeDSL();
  const nodeDSLParent = nodeListDSL[routeContentNode?.parentID as string];

  const validateURL = (url: string, pattern: string): boolean => {
    const keys: Key[] = [];
    const pathInRegexp = pathToRegexp(pattern, keys);

    return pathInRegexp.test(url);
  };

  return useMemo(() => {
    if (
      nodeDSLParent.props.path !== currentPathname &&
      typeof currentPathname === 'string' &&
      typeof nodeDSLParent.props.path === 'string' &&
      !validateURL(currentPathname, nodeDSLParent.props.path)
    ) {
      launchNotification(send, `Route: ${currentPathname}, was removed`, 'warning');
      return nodeListSelectors.getCurrentRouteNodeDSL(nodeListDSL, {
        currentPathname: '/404',
      });
    }

    return nodeListSelectors.getCurrentRouteNodeDSL(nodeListDSL, {
      currentPathname,
    });
  }, [currentPathname, nodeListDSL, nodeDSLParent?.props.path, send]);
};

export const useDashboardCurrentRouteContentNodeDSL = (): NodeDSL | undefined => {
  const nodeListDSL = useNodeListDSL();
  const currentPathname = useCurrentPathname();

  return useMemo(() => {
    try {
      return nodeListSelectors.getCurrentRouteContentNodeDSL(nodeListDSL, {
        currentPathname,
      });
    } catch {
      return undefined;
    }
  }, [currentPathname, nodeListDSL]);
};

export const useDashboardCurrentRoutInfo = (): {
  routeNode: NodeDSL | undefined;
  routeContentNode: NodeDSL | undefined;
} => {
  const routeNode = useDashboardCurrentRouteNodeDSL();
  const routeContentNode = useDashboardCurrentRouteContentNodeDSL();
  return useMemo(() => ({ routeNode, routeContentNode }), [routeContentNode, routeNode]);
};
