import { argTypes } from 'src/dialogs/GlobalDialogs/CodeEditorDialog/CodeEditorDialog';

const SnackBarEvent = ['BuilderComponentsSnackBar'];
const COMPONENTS_ACCEPTS_VALUE = ['BuilderComponentsRating', 'BuilderComponentsFormRating'];

const getNewAcceptedArgs = (WHITE_LIST: string[], componentName: string, valueToAccept: string) =>
  WHITE_LIST.includes(componentName) ? [valueToAccept] : [];

export const eventKeys = (componentName: string): argTypes[] => [
  {
    action: 'onChange',
    context: ['event', ...getNewAcceptedArgs(COMPONENTS_ACCEPTS_VALUE, componentName, 'value')],
  },
  {
    action: 'onClick',
    context: ['event'],
  },
  {
    action: 'onMouseOver',
    context: ['event'],
  },
  {
    action: 'onMouseOut',
    context: ['event'],
  },
  {
    action: 'onMouseDown',
    context: ['event'],
  },
  {
    action: 'onMouseUp',
    context: ['event'],
  },
  {
    action: 'onMouseEnter',
    context: ['event'],
  },
  {
    action: 'onMouseLeave',
    context: ['event'],
  },
  {
    action: 'onWheel',
    context: ['event'],
  },
  {
    action: 'onContextMenu',
    context: ['event'],
  },
  {
    action: 'onAuxClick',
    context: ['event'],
  },
  {
    action: 'onSubmit',
    context: ['event', 'data'],
  },
  {
    action: 'onDragOver',
    context: ['event'],
  },
  {
    action: 'onClose',
    context: ['event', ...getNewAcceptedArgs(SnackBarEvent, componentName, 'reason')],
  },
];
