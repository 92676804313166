var _a;
import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_TYPES, STATE_SCOPES, TYPE_DEFINITION_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialTypography } from '../MaterialTypography';
import { BuilderComponentsSliderSettings } from './BuilderComponentsSlider.settings';
export const BuilderComponentsSlider = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsSlider,
    displayName: 'Slider',
    icon: 'slider',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Slider',
    },
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'Label',
        },
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.number,
                    name: 'slider',
                    defaultValue: 30,
                },
            ],
            props: {
                color: 'primary',
                size: 'medium',
                orientation: 'horizontal',
                value: '{{ __STATES__.slider.value }}',
                scale: '',
                step: '10',
                defaultValue: '',
                getAriaLabel: '',
                getAriaValueText: '',
                min: 0,
                max: 100,
                arialabel: '',
                arialabelledby: '',
                ariavaluetext: '',
                classes: '{{{}}}',
                componentes: '',
                onChange: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.slider.setValue(event.target.value);',
                    },
                },
                labelPlacement: 'end',
                track: 'false',
                marks: '{{[]}}',
                sx: '',
                valueLabelFormat: '',
                tabIndex: '',
                valueLabelDisplay: 'auto',
                label: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                            alias: 'Slider Label',
                            schemaOverride: {
                                interaction: 'only-editable',
                            },
                            props: Object.assign(Object.assign({}, (_a = MaterialTypography.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: 'Slider',
                                            },
                                        },
                                    ],
                                } }),
                        },
                    ],
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { name: { type: COMPONENT_DSL_PROP_TYPES.string }, icon: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.BuilderComponentsIcon],
            }, color: { type: COMPONENT_DSL_PROP_TYPES.enum }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableSwap: { type: COMPONENT_DSL_PROP_TYPES.boolean }, inputProps: { type: COMPONENT_DSL_PROP_TYPES.object }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                        schema: {
                            type: COMPONENT_DSL_PROP_TYPES.object,
                            typeDefinition: {
                                type: TYPE_DEFINITION_TYPES.import,
                                definition: 'ChangeEvent<HTMLInputElement>',
                                imports: [
                                    {
                                        varName: 'ChangeEvent',
                                        importName: 'react',
                                    },
                                ],
                            },
                        },
                    },
                ],
            }, required: { type: COMPONENT_DSL_PROP_TYPES.boolean }, size: { type: COMPONENT_DSL_PROP_TYPES.enum }, orientation: { type: COMPONENT_DSL_PROP_TYPES.enum }, arialabel: { type: COMPONENT_DSL_PROP_TYPES.string }, arialabelledby: { type: COMPONENT_DSL_PROP_TYPES.string }, ariavaluetext: { type: COMPONENT_DSL_PROP_TYPES.string }, classes: { type: COMPONENT_DSL_PROP_TYPES.object }, marks: {
                type: COMPONENT_DSL_PROP_TYPES.array,
                item: {
                    type: COMPONENT_DSL_PROP_TYPES.any,
                },
            }, sx: { type: COMPONENT_DSL_PROP_TYPES.string }, valueLabelFormat: { type: COMPONENT_DSL_PROP_TYPES.string }, tabIndex: { type: COMPONENT_DSL_PROP_TYPES.string }, value: { type: COMPONENT_DSL_PROP_TYPES.number }, step: { type: COMPONENT_DSL_PROP_TYPES.string }, scale: { type: COMPONENT_DSL_PROP_TYPES.string }, defaultValue: { type: COMPONENT_DSL_PROP_TYPES.string }, getAriaLabel: { type: COMPONENT_DSL_PROP_TYPES.string }, getAriaValueText: { type: COMPONENT_DSL_PROP_TYPES.string }, min: { type: COMPONENT_DSL_PROP_TYPES.number }, max: { type: COMPONENT_DSL_PROP_TYPES.number }, label: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, labelPlacement: { type: COMPONENT_DSL_PROP_TYPES.enum }, track: { type: COMPONENT_DSL_PROP_TYPES.enum }, components: { type: COMPONENT_DSL_PROP_TYPES.string }, valueLabelDisplay: { type: COMPONENT_DSL_PROP_TYPES.enum } }),
    },
    settings: BuilderComponentsSliderSettings,
};
