var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-empty-function */
import { forwardRef, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import _ from 'lodash';
import { getIsInvalid, RESET_STATUS } from '../common';
import { TimePicker } from '../TimePicker';
export const FormTimePicker = forwardRef((_a, ref) => {
    var { fieldProps, timePickerProps, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, id, title, lang, className, translate, onAccept = () => { } } = _a, rest = __rest(_a, ["fieldProps", "timePickerProps", 'data-test', 'data-node-id', 'data-node-render-path', "id", "title", "lang", "className", "translate", "onAccept"]);
    const { setFieldValue, initialValues, status, setStatus, values } = useFormikContext();
    const initialValue = initialValues[fieldProps.name] || '';
    useEffect(() => {
        var _a;
        setFieldValue(fieldProps.name, initialValue);
        (_a = timePickerProps === null || timePickerProps === void 0 ? void 0 : timePickerProps.onChange) === null || _a === void 0 ? void 0 : _a.call(timePickerProps, _, String(initialValue));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldProps.name, initialValue]);
    useEffect(() => {
        var _a;
        if (status === RESET_STATUS) {
            setStatus(undefined);
            (_a = timePickerProps === null || timePickerProps === void 0 ? void 0 : timePickerProps.onChange) === null || _a === void 0 ? void 0 : _a.call(timePickerProps, _, String(initialValue));
        }
    }, [status, setStatus, timePickerProps, initialValue]);
    useEffect(() => {
        if ((timePickerProps === null || timePickerProps === void 0 ? void 0 : timePickerProps.value) !== values[fieldProps.name]) {
            setFieldValue(fieldProps.name, timePickerProps === null || timePickerProps === void 0 ? void 0 : timePickerProps.value);
        }
    }, [fieldProps.name, setFieldValue, timePickerProps === null || timePickerProps === void 0 ? void 0 : timePickerProps.value, values]);
    return (_jsx(Field, Object.assign({ name: fieldProps.name, validate: fieldProps.validate }, { children: ({ field, form, meta }) => {
            const isInvalid = getIsInvalid({ meta, form });
            const errorText = isInvalid ? meta.error : undefined;
            return (_jsx(TimePicker, Object.assign({}, rest, { id: id, title: title, lang: lang, className: className, translate: translate, ref: ref }, timePickerProps, { value: field.value || (timePickerProps === null || timePickerProps === void 0 ? void 0 : timePickerProps.value), onChange: (time, value) => {
                    var _a;
                    form.setFieldValue(fieldProps.name, value);
                    (_a = timePickerProps === null || timePickerProps === void 0 ? void 0 : timePickerProps.onChange) === null || _a === void 0 ? void 0 : _a.call(timePickerProps, time, value);
                    field.onChange(value);
                    setFieldValue(fieldProps.name, value);
                }, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, error: isInvalid, helperText: errorText })));
        } })));
});
