import React from 'react';

import { Code, DataObject, FormatQuoteSharp } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

import { PAGE_LABEL } from 'src/features/node-settings/types';
import { ACTIVEPROP, FxButtonProps, StyledButton } from 'src/shared/constants/FxButton';

export const FxButton: React.FC<FxButtonProps> = ({
  onClick,
  activeProp = ACTIVEPROP.literal,
  'data-test': dataTest,
  label,
  disabled,
  skipCode,
}) => {
  const isPageProp = label === PAGE_LABEL;

  const propToComponentMap = {
    [ACTIVEPROP.code]: (
      <Tooltip title="State Format">
        <DataObject color="disabled" />
      </Tooltip>
    ),
    [ACTIVEPROP.state]: (
      <Tooltip title="Literal Format">
        <FormatQuoteSharp color="disabled" />
      </Tooltip>
    ),
    [ACTIVEPROP.literal]:
      !isPageProp && skipCode ? (
        <Tooltip title="State Format">
          <DataObject color="disabled" />
        </Tooltip>
      ) : (
        <Tooltip title="Code Format">
          <Code color="disabled" />
        </Tooltip>
      ),
    [ACTIVEPROP.page]: (
      <Tooltip title="Code Format">
        <Code color="disabled" />
      </Tooltip>
    ),
  };

  return (
    <StyledButton
      activeProp={activeProp}
      onClick={onClick}
      data-test={dataTest}
      disabled={disabled}
    >
      {propToComponentMap[activeProp]}
    </StyledButton>
  );
};
