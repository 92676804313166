import { COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_KINDS, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, PRESENTATIONS, } from '../../../constants';
import { RouteSettings } from './BuilderComponentsRouteForLayout.settings';
export const BuilderComponentsRouteForLayout = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsRouteForLayout,
    displayName: 'Route',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    kind: COMPONENT_DSL_KINDS.route,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Route',
    },
    memoization: {
        disable: true,
    },
    schema: {
        interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.RouterSwitch,
                },
            },
        ],
        dndTargetPropName: 'children',
        presentations: [
            {
                name: PRESENTATIONS.visible,
                condition: {
                    showIf: true,
                },
                props: {
                    authAccess: 'any',
                },
            },
        ],
        props: {
            __defaultPathParams: {
                type: COMPONENT_DSL_PROP_TYPES.array,
                item: {
                    type: COMPONENT_DSL_PROP_TYPES.object,
                    props: {
                        name: { type: COMPONENT_DSL_PROP_TYPES.string },
                        value: { type: COMPONENT_DSL_PROP_TYPES.string },
                    },
                },
                system: true,
            },
            children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout],
            },
            path: {
                type: COMPONENT_DSL_PROP_TYPES.enum,
                required: true,
            },
            exact: {
                type: COMPONENT_DSL_PROP_TYPES.boolean,
            },
            location: { type: COMPONENT_DSL_PROP_TYPES.object },
            title: { type: COMPONENT_DSL_PROP_TYPES.string, allowJS: false },
            authAccess: { type: COMPONENT_DSL_PROP_TYPES.string, allowJS: false, required: true },
        },
    },
    settings: RouteSettings,
};
