/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateMap, BroadcastStatePayload } from '@8base-private/event-handler';
import { SnackbarMessage, SnackbarKey, OptionsObject } from 'notistack';

import { AppAuditNotification, ShallowAppRuntimeStateList } from '@builder/app-engine';
import {
  SettingsDSL,
  ComponentListDSL,
  ComponentDSLNameTypes,
  NodeID,
  StateID,
  StateDSL,
  JSInjection,
  IteratorDSL,
  NodeStateConnectionDSL,
  MediaQueryDataDSL,
  MediaQueryDSL,
  PresentationTypes,
  NodeTemplateDSL,
  StateTemplateDSL,
  NodeElementData,
  NodeElementDataRecords,
  UserComponentDSL,
  AppConfiguration,
  NodeListDSL,
  StateRequestDSL,
  SchemaOverrideDSL,
  LayoutModeTypes,
  AssetID,
  AssetDSL,
  AssetDataDSL,
  ResourceID,
  LibraryDSL,
  ResourceDSL,
  RouteHooksDSL,
  NodeRouteDSL,
  CSS,
  CustomFontDSL,
  FontID,
  GoogleFontDSL,
  GoogleFontDataDSL,
  AssetFontDataDSL,
  StateDataDSL,
  MetaTag,
  PredefinedStateDataDSL,
  AppDSL,
  ThemeFrameworkSettingsDSL,
  StateScopeTypes,
  AppVersion,
  NodeDSL,
  StateListDSL,
} from '@builder/schemas';
import { ValueOf } from '@builder/utils';

import { AppSettingsNavigationTabsType } from 'src/features/app-settings/AppSettings';
import { TeamMemberFragment } from 'src/shared/graphql/__generated__';

import {
  DASHBOARD_ANIM_EVENTS,
  DASHBOARD_EVENTS,
  UI_BUILDER_EVENTS,
  DNDTargetTypes,
  UIBuilderModeTypes,
  ComponentSelectActorTypes,
  LeftPanelTabsType,
  RightPanelTabTypes,
  NodeSettingsTabTypes,
  AssetViewModeTypes,
} from './constants';

export type StatePayloadWithColor = BroadcastStatePayload & { color: string };
export type TeamMemberWithColor = TeamMemberFragment & { color: string };

export type BasicTargetInfo = {
  nodeID: string;
  propName: Array<string | number>;
};

export type IntoTarget = BasicTargetInfo & {
  type: Extract<DNDTargetTypes, 'into'>;
  target?: any;
};

export type RelativeTarget = BasicTargetInfo & {
  type: Extract<DNDTargetTypes, 'relative'>;
};

export const ROUTER_UI_LIST_STATES = {
  selected: 'selected',
  selectedSettings: 'selected-settings',
  create: 'create',
  edit: 'edit',
  layoutEdit: 'layout-edit',
  layoutCreate: 'layout-create',
  layoutChange: 'layout-change',
} as const;

export type PageListStatesType = ValueOf<typeof ROUTER_UI_LIST_STATES>;

export const MOVE_VARIANT = {
  before: 'before',
  after: 'after',
  into: 'into',
} as const;

export type MoveType = ValueOf<typeof MOVE_VARIANT>;

export type functionDataTypes = Record<string, string>;

export type ComponentCreateIntoMeta = {
  type: typeof MOVE_VARIANT.into;
  target: IntoTarget;
};

export type ComponentCreateRelativeMeta = {
  type: typeof MOVE_VARIANT.before | typeof MOVE_VARIANT.after;
  target: RelativeTarget;
};

export type Notification = {
  message: SnackbarMessage;
  key?: SnackbarKey;
  title?: string;
  functionData?: functionDataTypes;
  dismissed?: boolean;
  options?: OptionsObject;
  actionTitle?: string;
  actionCallback?: () => void;

  action?: () => void;
};

export type Notifications = Array<Notification>;

export type ComponentCreateMeta = ComponentCreateIntoMeta | ComponentCreateRelativeMeta;

export type ComponentMoveMeta = { sourceID: string; kind: 'valid' | 'invalid' } & (
  | {
      type: typeof MOVE_VARIANT.before | typeof MOVE_VARIANT.after;
      target: RelativeTarget;
      targetComponent?: BasicTargetInfo;
    }
  | {
      type: typeof MOVE_VARIANT.into;
      target: IntoTarget;
      targetComponent?: BasicTargetInfo;
    }
);

export type DashboardContextUpdateEvents =
  | {
      type: typeof DASHBOARD_EVENTS.appConfigurationInit;
      appConfiguration: AppConfiguration;
    }
  | {
      type: typeof DASHBOARD_EVENTS.appConfigurationUpdate;
      appConfiguration: AppConfiguration;
      route?: string;
    }
  | {
      type: typeof DASHBOARD_EVENTS.appConfigurationUpdateDslNodes;
      nodes: NodeListDSL;
    }
  | {
      type: typeof DASHBOARD_EVENTS.appConfigurationUpdateVersion;
      appVersion: AppVersion;
    }

  // dashboard
  | { type: typeof DASHBOARD_EVENTS.dashboardClear }
  | { type: typeof DASHBOARD_EVENTS.dashboardClearSelection }
  | { type: typeof DASHBOARD_EVENTS.dashboardUndo; completely?: true }
  | { type: typeof DASHBOARD_EVENTS.dashboardRedo }
  | { type: typeof DASHBOARD_EVENTS.dashboardIFrameRefresh }
  | { type: typeof DASHBOARD_EVENTS.dashboardSetLoadingConfiguration; loading: boolean }
  | { type: typeof DASHBOARD_EVENTS.appConfigurationDSLCleanUp }

  // open as root
  | { type: typeof DASHBOARD_EVENTS.openAsRoot; nodeID: string }

  // size
  | { type: typeof DASHBOARD_EVENTS.userAppResize; size?: { width: number } }

  // history
  | { type: typeof DASHBOARD_EVENTS.historyPush }
  | { type: typeof DASHBOARD_EVENTS.historyFlush }

  // routes
  | {
      type: typeof DASHBOARD_EVENTS.routeCreate;
      layoutID: NodeID;
      path: string;
      title?: string;
      metaTags?: MetaTag[];
      nodeAlias: string;
      defaultPathParams: { name: string; value: string }[];
      routerHooks?: Record<string, string>;
      meta: Record<string, string>;
    }
  | {
      type: typeof DASHBOARD_EVENTS.routeLayoutUpdate;
      routeIDs: NodeID[];
      layoutID: NodeID;
    }
  | {
      activeRoute: any;
      type: typeof DASHBOARD_EVENTS.layoutCreate;
      layoutID: NodeID;
      path: string;
      nodeAlias: string;
      defaultPathParams: { name: string; value: string }[];
    }
  | {
      type: typeof DASHBOARD_EVENTS.layoutUpdate;
      nodeAlias: string;
    }
  | {
      type: typeof DASHBOARD_EVENTS.layoutChange;
      layoutSelected: Record<string, unknown>;
    }
  | {
      type: typeof DASHBOARD_EVENTS.routeCurrentUpdate;
      routeID: NodeID;
      layoutID: NodeID;
      path: string;
      title?: string;
      metaTags?: MetaTag[];
      authAccess?: string;
      nodeAlias: string;
      states: NodeStateConnectionDSL[];
      routerHooks?: Record<string, string>;
      defaultPathParams: { name: string; value: string }[];
      meta: Record<string, string>;
    }

  // nodes
  | {
      type: typeof DASHBOARD_EVENTS.componentPresentationAdd;
      nodeID: NodeID;
      presentation: PresentationTypes;
    }
  | {
      type: typeof DASHBOARD_EVENTS.componentPresentationRemove;
      nodeID: NodeID;
      presentation: PresentationTypes;
    }
  | {
      type: typeof DASHBOARD_EVENTS.componentPresentationVisibleToggle;
      nodeID: NodeID;
    }
  | {
      type: typeof DASHBOARD_EVENTS.componentConditionRuleUpdate;
      id: NodeID[];
      value: JSInjection;
    }
  | {
      type: typeof DASHBOARD_EVENTS.componentAllowedRolesUpdate;
      id: NodeID;
      value: string[];
    }
  | {
      type: typeof DASHBOARD_EVENTS.componentIteratorDataUpdate;
      id: NodeID[];
      value: IteratorDSL;
    }
  | {
      type: typeof DASHBOARD_EVENTS.componentSchemaOverrideUpdate;
      id: NodeID;
      schemaOverrideData: {
        keyValue: unknown;
        keyPath: Array<string | number>;
      };
    }
  | {
      type: typeof DASHBOARD_EVENTS.componentPropUpdate;
      id: NodeID;
      value: {
        keyValue: unknown;
        keyPath: Array<string | number>;
      };
    }
  | {
      type: typeof DASHBOARD_EVENTS.componentPropUpdateMany;
      id: NodeID;
      values: {
        keyValue: unknown;
        keyPath: Array<string | number>;
      }[];
    }
  | {
      type: typeof DASHBOARD_EVENTS.componentLocalStatesUpdate;
      id: NodeID;
      value: NodeStateConnectionDSL[];
    }
  | {
      type: typeof DASHBOARD_EVENTS.componentStatesRemove;
      id: NodeID;
      value: StateID[];
    }
  | {
      type: typeof DASHBOARD_EVENTS.componentReactNodeTextUpdate;
      id: NodeID[];
      value: { keyPath?: Array<string | number>; text: string };
    }
  | {
      type: typeof DASHBOARD_EVENTS.componentHover;
      id: NodeID;
    }
  | { type: typeof DASHBOARD_EVENTS.componentHoverRemove }
  | { type: typeof DASHBOARD_EVENTS.componentToggleFocus; renderID: string | null }
  | {
      type: typeof DASHBOARD_EVENTS.componentToggleSelect;
      id: string;
      actor: ComponentSelectActorTypes;
    }
  | {
      type: typeof DASHBOARD_EVENTS.componentSelect;
      id: string;
    }
  | {
      type: typeof DASHBOARD_EVENTS.componentSelectMultiple;
      id: string;
    }
  | {
      type: typeof DASHBOARD_EVENTS.componentToggleSelectByID;
      id: string;
    }
  | {
      type: typeof DASHBOARD_EVENTS.componentDeselect;
      id: string;
      rest?: string[];
    }
  | { type: typeof DASHBOARD_EVENTS.componentSelectedRemove }
  | {
      type: typeof DASHBOARD_EVENTS.componentCreate;
      place: ComponentCreateMeta;
      value: {
        name: ComponentDSLNameTypes;
        props?: NodeTemplateDSL['props'];
        states?: StateTemplateDSL[];
        schemaOverride?: SchemaOverrideDSL;
        selectNodeOnCreate?: boolean;
      };
    }
  | { type: typeof DASHBOARD_EVENTS.componentMove; place: ComponentMoveMeta }
  | {
      type: typeof DASHBOARD_EVENTS.componentRemove;
      id: string;
      currentPathName: string;
      connectedStates?: StateDSL[];
    }
  | {
      type: typeof DASHBOARD_EVENTS.routeClone;
      id: string;
      currentPathName: string;
      appConfiguration: AppConfiguration;
      newPageId: string;
    }
  | { type: typeof DASHBOARD_EVENTS.componentUpdateAlias; nodeID: string; alias: string }
  | { type: typeof DASHBOARD_EVENTS.componentUpdateLayoutAlias; nodeID: string; alias: string }
  | { type: typeof DASHBOARD_EVENTS.componentCopy; id: string; currentPathName?: string }
  | {
      type: typeof DASHBOARD_EVENTS.componentCopyStyle;
      componentName: string;
      style: CopyStyle;
    }
  | {
      type: typeof DASHBOARD_EVENTS.componentPasteStyle;
      id: string | string[];
      style: { [key: string]: any };
    }
  | { type: typeof DASHBOARD_EVENTS.componentUpdateMany; nodes: NodeListDSL }
  | { type: typeof DASHBOARD_EVENTS.componentSaveAsGlobalCSS; nodeID: NodeID; className: string }
  | {
      type: typeof DASHBOARD_EVENTS.componentUpdateMany;
      nodes: NodeListDSL;
      isFromRouteupdate?: NodeDSL | undefined;
    }
  | {
      type: typeof DASHBOARD_EVENTS.componentMoveDirection;
      nodeID: string;
      parentID: string;
      direction: number;
    }

  // states
  | {
      type: typeof DASHBOARD_EVENTS.stateCreate;
      stateDSL: StateDataDSL;
      connectedNodeID?: NodeID;
      nodeForConnectionName?: string;
      connectedNodeParentID?: string;
    }
  | {
      type: typeof DASHBOARD_EVENTS.stateConvert;
      oldStateArgs: {
        id: StateID;
        oldConnectedNodeID?: NodeID;
        oldConnectedNodeParentID?: NodeID;
        state: StateDSL;
      };
      newStateArgs: {
        stateDSL: StateDataDSL;
        newConnectedNodeID?: NodeID;
        nodeForConnectionName?: string;
        newConnectedNodeParentID?: string;
      };
    }
  | {
      type: typeof DASHBOARD_EVENTS.stateQueryCreate;
      stateDSL: StateRequestDSL;
      connectedNodeID?: NodeID;
      connectedNodeParentID?: NodeID;
    }
  | {
      type: typeof DASHBOARD_EVENTS.stateUpdate;
      stateDSL: StateDSL;
      previousStateName: StateDSL['name'];
      connectedNodeID?: NodeID;
      connectedNodeParentID?: NodeID;
    }
  | {
      type: typeof DASHBOARD_EVENTS.stateUpdateType;
      previousStateName: StateDSL['name'];
      stateDSL: StateDSL;
      connectedNodeID?: NodeID;
      connectedNodeParentID?: NodeID;
      nodeForConnectionName?: string;
      /** @deprecated Field is deprecated */
      nodeForConnectionParentID?: NodeID;
      id: StateID;
    }
  | {
      type: typeof DASHBOARD_EVENTS.stateUpdateTypeDialog;
      previousStateName: StateDSL['name'];
      stateDSL: StateDSL;
      connectedNodeID?: NodeID;
      connectedNodeParentID?: NodeID;
      nodeForConnectionName?: string;
      nodeForConnectionParentID?: NodeID;
      id: StateID;
    }
  | {
      type: typeof DASHBOARD_EVENTS.stateRemove;
      id: StateID;
      connectedNodeID?: NodeID;
      connectedNodeParentID?: NodeID;
      state: StateDSL;
    }

  // assets
  | {
      type: typeof DASHBOARD_EVENTS.createAsset;
      assetDSL: AssetDataDSL;
    }
  | {
      type: typeof DASHBOARD_EVENTS.updateAsset;
      assetDSL: AssetDSL;
    }
  | { type: typeof DASHBOARD_EVENTS.deleteAsset; assetIDs: AssetID[] }

  // fonts
  | {
      type: typeof DASHBOARD_EVENTS.createCustomFont;
      fontDSL: Omit<CustomFontDSL, 'id' | 'type' | 'assetID'>;
      asset: AssetFontDataDSL;
    }
  | {
      type: typeof DASHBOARD_EVENTS.deleteCustomFont;
      fontDSL: CustomFontDSL;
    }
  | {
      type: typeof DASHBOARD_EVENTS.googleFontCreate;
      googleFontDSL: GoogleFontDataDSL;
    }
  | {
      type: typeof DASHBOARD_EVENTS.googleFontUpdate;
      googleFontDSL: GoogleFontDSL;
    }
  | {
      type: typeof DASHBOARD_EVENTS.googleFontDelete;
      id: FontID;
    }

  // resources
  | {
      type: typeof DASHBOARD_EVENTS.createResource;
      resourceDSL: ResourceDSL;
    }
  | {
      type: typeof DASHBOARD_EVENTS.updateResource;
      resourceDSL: ResourceDSL;
    }
  | { type: typeof DASHBOARD_EVENTS.deleteResource; id: ResourceID }

  // hooks
  | {
      type: typeof DASHBOARD_EVENTS.updateHooks;
      routeHooksDSL: RouteHooksDSL;
    }

  // libraries
  | {
      type: typeof DASHBOARD_EVENTS.createLibrary;
      library: LibraryDSL;
    }
  | {
      type: typeof DASHBOARD_EVENTS.updateLibrary;
      library: LibraryDSL;
    }
  | { type: typeof DASHBOARD_EVENTS.removeLibrary; name: string }

  // media query
  | {
      type: typeof DASHBOARD_EVENTS.mediaQueryCreate;
      mediaQuery: MediaQueryDataDSL;
    }
  | {
      type: typeof DASHBOARD_EVENTS.mediaQueryUpdate;
      mediaQuery: MediaQueryDSL;
    }
  | { type: typeof DASHBOARD_EVENTS.mediaQueryDelete; mediaQuery: MediaQueryDSL }

  // css
  | {
      type: typeof DASHBOARD_EVENTS.cssMediaQueriesSet;
      mediaQueriesCSS: CSS;
    }
  | {
      type: typeof DASHBOARD_EVENTS.cssComponentSet;
      name: ComponentDSLNameTypes;
      css: CSS;
    }
  | {
      type: typeof DASHBOARD_EVENTS.cssTypographySet;
      cssBody: CSS;
    }

  // settings
  | { type: typeof DASHBOARD_EVENTS.settingsUpdate; settings: Partial<SettingsDSL> }

  // authentication
  | {
      type: typeof DASHBOARD_EVENTS.authenticationUpdate;
      apiAuthToken?: string;
      authProfileID?: string;
      currentAuthID?: string;
    }
  | { type: typeof DASHBOARD_EVENTS.authResourceIDUpdate; resourceID: string }

  // theme
  | {
      type: typeof DASHBOARD_EVENTS.themeCSSGlobalUpdate;
      cssBody: CSS;
    }
  | {
      type: typeof DASHBOARD_EVENTS.themeCSSGlobalSelectorsUpdate;
      selectors: string[];
      cssBody: CSS;
    }
  | {
      type: typeof DASHBOARD_EVENTS.themeCSSCustomUpdate;
      cssBody: CSS | undefined;
    }
  | {
      type: typeof DASHBOARD_EVENTS.themeFrameworkSettingsUpdate;
      value: ThemeFrameworkSettingsDSL | undefined;
    }

  // theme manager
  | {
      type: typeof DASHBOARD_EVENTS.setThemeManagerVisibility;
      value: boolean;
    }

  // router
  | { type: typeof DASHBOARD_EVENTS.routerPathUpdate; route: Route }
  | { type: typeof DASHBOARD_EVENTS.routerPathUpdateByUserApp; route: Route }

  // navigator
  | {
      type: typeof DASHBOARD_EVENTS.navigatorCollapseNodeList;
      nodeIDs: NodeID[];
    }
  | {
      type: typeof DASHBOARD_EVENTS.navigatorExpandNodeList;
      nodeIDs: NodeID[];
    }
  | {
      type: typeof DASHBOARD_EVENTS.navigatorExpandNode;
      nodeID: NodeID;
    }
  | {
      type: typeof DASHBOARD_EVENTS.navigatorCollapseNode;
      nodeID: NodeID;
      recursive?: boolean;
    }

  // layouts
  | {
      type: typeof DASHBOARD_EVENTS.layoutModeUpdate;
      layoutMode: LayoutModeTypes;
    }

  // runtime
  | {
      type: typeof DASHBOARD_EVENTS.stateReport;
      report: { states: Store['userAppRuntimeState'] };
    }
  | {
      type: typeof DASHBOARD_EVENTS.updateNodeMeasures;
      elements: NodeElementDataRecords;
    }

  // user components
  | {
      type: typeof DASHBOARD_EVENTS.userComponentCreate;
      userComponentDSL: UserComponentDSL;
    }
  | {
      type: typeof DASHBOARD_EVENTS.userComponentUpdate;
      previousName: string;
      userComponentDSL: UserComponentDSL;
    }
  | {
      type: typeof DASHBOARD_EVENTS.userComponentDelete;
      name: string;
    }

  // copy buffer
  | {
      type: typeof DASHBOARD_EVENTS.copyBufferSet;
      nodeID: NodeID[];
      currentPathName: string;
    }
  | {
      type: typeof DASHBOARD_EVENTS.copyBufferClear;
    }
  | {
      type: typeof DASHBOARD_EVENTS.copyBufferApply;
      nodeID: NodeID;
      currentPathName: string;
    };

export type DashboardAnimationState = {
  dndPreview: ComponentMoveMeta | null;
  dndInProgress: { sourceID: string | string[]; nodeName: string } | null;
  dndOverNavigation: boolean;
};

export type DashboardAnimationEvents =
  | { type: typeof DASHBOARD_ANIM_EVENTS.moveStart; sourceID: string | string[]; nodeName: string }
  | {
      type: typeof DASHBOARD_ANIM_EVENTS.move;
      spec: ComponentMoveMeta;
    }
  | {
      type: typeof DASHBOARD_ANIM_EVENTS.moveName;
      nodeName: string;
    }
  | { type: typeof DASHBOARD_ANIM_EVENTS.moveEnd }
  | { type: typeof DASHBOARD_ANIM_EVENTS.clear }
  | { type: typeof DASHBOARD_ANIM_EVENTS.navigationEnter }
  | { type: typeof DASHBOARD_ANIM_EVENTS.navigationLeave };

export type UIBuilderEvents =
  | { type: typeof UI_BUILDER_EVENTS.isSaveButtonDisabledUpdate; isSaveButtonDisabled: boolean }
  | { type: typeof UI_BUILDER_EVENTS.saveState; havePendingChanges: boolean }
  | { type: typeof UI_BUILDER_EVENTS.toggleDevMode }
  | { type: typeof UI_BUILDER_EVENTS.modeRequestPreview }
  | { type: typeof UI_BUILDER_EVENTS.modeRequestConstruction }
  | { type: typeof UI_BUILDER_EVENTS.toggleAssetViewMode }
  | { type: typeof UI_BUILDER_EVENTS.notificationSend; notification: Notification }
  | {
      type: typeof UI_BUILDER_EVENTS.errorAppCrashedNotify;
      errorMessage?: string;
      key?: SnackbarKey;
    }
  | { type: typeof UI_BUILDER_EVENTS.errorAppNotify; errorMessage?: string; key?: SnackbarKey }
  | { type: typeof UI_BUILDER_EVENTS.appEngineAuditNotify; notifications: AppAuditNotification[] }
  | {
      type: typeof UI_BUILDER_EVENTS.successAppNotify;
      successMessage: string;
      key?: SnackbarKey;
      functionData?: functionDataTypes;
      action?: () => void;
    }
  | { type: typeof UI_BUILDER_EVENTS.saveProcessStarts }
  | { type: typeof UI_BUILDER_EVENTS.successDeploy; notification: Notification }
  | { type: typeof UI_BUILDER_EVENTS.failDeploy; notification: Notification }
  | { type: typeof UI_BUILDER_EVENTS.removeNotification; key: SnackbarKey }
  | { type: typeof UI_BUILDER_EVENTS.closeSnackbar; key?: SnackbarKey }
  | { type: typeof UI_BUILDER_EVENTS.updateLeftPanel; leftPanel: LeftPanelType }
  | { type: typeof UI_BUILDER_EVENTS.updateRightPanel; rightPanel: RightPanelType }
  | { type: typeof UI_BUILDER_EVENTS.setEditModeToLayout }
  | { type: typeof UI_BUILDER_EVENTS.setEditModeToPage }
  | { type: typeof UI_BUILDER_EVENTS.setSelectedRoute; leftPanel: LeftPanelType }
  | { type: typeof UI_BUILDER_EVENTS.setCurrentLayoutNodesIds; layoutNodesIds: Array<string> }
  | { type: typeof UI_BUILDER_EVENTS.designScopeInit; states: StateMap }
  | {
      type: typeof UI_BUILDER_EVENTS.designScopeUpdateSession;
      payload: BroadcastStatePayload;
    }
  | {
      type: typeof UI_BUILDER_EVENTS.setVisiblePresentation;
      payload: { nodeID: NodeID; isVisible: boolean };
    }
  | {
      type: typeof UI_BUILDER_EVENTS.setIssueManagerVisibility;
      payload: { isVisible: boolean };
    };

export type createAppStateType = {
  event: {
    stateDSL: PredefinedStateDataDSL | StateDSL;
    connectedNodeID?: string | undefined;
    nodeForConnectionName?: string | undefined;
    /** @deprecated */
    nodeForConnectionParentID?: string | undefined;
    connectedNodeParentID?: string | undefined;
  };
  appDSL: AppDSL;
  newStateId: StateID;
  context?: NodeStateConnectionDSL | undefined;
};

export type ErrorNotificationsEvents = {
  type: '@builder/error/push' | '@builder/error/notifications';
  payload: ItemErrorContext | ItemErrorContext[];
};

export type AppEvents =
  | DashboardContextUpdateEvents
  | DashboardAnimationEvents
  | UIBuilderEvents
  | ErrorNotificationsEvents;

export type Route = string;

export type Router = { currentRoute: Route; history: Route[] };

export type CopyStyle = { componentName: string; styles: Record<string, string> };

export type CopyBuffer = Partial<Pick<AppConfiguration['appDSL'], 'nodes' | 'states'>> & {
  nestedStates?: NodeStateConnectionDSL[];
  currentPathName?: string;
  statesCopyToBUffer?: StateListDSL;
};

export type openAsRootNode = { nodeID: NodeID };

export type LeftPanelType = {
  currentTab: LeftPanelTabsType;
  currentRoute?: {
    id?: string;
    type: PageListStatesType;
    route: NodeRouteDSL;
    changeRouteTimeoutID?: number;
    dialog?: NodeDSL;
  };
  targetID?: string;
  scope?: StateScopeTypes;
  args?: LeftPanelArgs;
  viewPanel?: string;
};

export type LeftPanelArgs = {
  appSettingsNavigationTab?: AppSettingsNavigationTabsType;
  appSettingsInnerTab?: string;
  isAffectOnDashboardLayout?: boolean;
  scrollDown?: boolean;
  width?: number;
};

export type RightPanelType = {
  currentTab: RightPanelTabTypes;
  nodeSettingsArgs?: {
    currentTab: NodeSettingsTabTypes;
    focusLabelForCurrentNode?: {
      inputLabelToFocus: string;
      focused: boolean;
      nodeId: string;
    };
  };
  forceReload?: number;
};

export type ThemeManager = { isOpen: boolean };
/**
 * @uiBuilder is an app we are building user apps with
 * @userApp is a produced application
 * @dashboard is constructor segment of a `uiBuilder`
 */

export type ItemErrorContext = {
  type: string;
  context: {
    scope: string;
    type: string;
    targetID: string;
    propPath: string[];
    message: string;
    title: string;
    fullPath: string[];
    nodeID: string;
    stateID?: string;
    isComponent?: boolean;
    isPage?: boolean;
    isLayout?: boolean;
    isState?: boolean;
    isComponentGroup?: boolean;
    isDupeStateName?: boolean;
    panelSection?: string;
    layoutID: string;
    pageID: string;
    stateScope?: string;
    locators: {
      highlight: { type: string; attribute: string }[];
      location: { action: string; attribute: string }[];
    };
  };
  key: string;
  targetID: string;
};

export type errorContext = {
  errors: ItemErrorContext[];
  lintErrors: ItemErrorContext[];
  lintErrorsPrev: ItemErrorContext[];
  runtimeErrors: ItemErrorContext[];
  warnings: ItemErrorContext[];
  hasError: number;
  hasWarning: number;
};

export type Store = {
  uiBuilder: {
    isSaveButtonDisabled: boolean;
    devModeEnabled: boolean;
    mode: UIBuilderModeTypes;
    notifications: Notifications;
    leftPanel: LeftPanelType;
    rightPanel: RightPanelType;
    audit: AppAuditNotification[];
    assetViewMode: AssetViewModeTypes;
    editMode: 'page' | 'layout';
    isOpenIssueManager: boolean;
    layoutNodesIds: Array<string>;
    usersSessions: Record<string, StatePayloadWithColor>;
    visibleNodePresentation: Record<string, boolean>;
    hasUpdates: boolean;
  };
  dashboard: {
    iFrameRefreshed: boolean;
    copyBuffer: CopyBuffer;
    openAsRootNode: openAsRootNode;
    copyStyle: CopyStyle;
    loadingConfiguration: boolean;
    appConfiguration: AppConfiguration;
    components: ComponentListDSL;
    operations: DashboardAnimationState & {
      selectedID: string | string[] | null;
      lastCreatedID: string | null;
      hoveredID: NodeID | null;
      focusedNodeRenderID: string | null;
    };
    history: {
      future: BaseDashboardState[];
      past: BaseDashboardState[];
    };
    router: Router;
    themeManager: ThemeManager;
    navigator: {
      collapsedNodes: Record<NodeID, boolean>;
    };
    layout: {
      layoutMode: LayoutModeTypes;
      layoutSelected: Record<string, unknown> | null;
    };
  };
  // read-only stuff that does not need to be persistent
  userAppRuntimeState: ShallowAppRuntimeStateList;
  userAppNodeElements: {
    elements: NodeElementDataRecords;
    elementsByNodeID: Record<NodeID, NodeElementData>;
  };
  userAppError: errorContext;
};

export type BaseDashboardState = Omit<Store['dashboard'], 'history'>;

export type DashboardState = Store['dashboard'];

export type EventNavigationType =
  | Extract<DashboardContextUpdateEvents, { type: typeof DASHBOARD_EVENTS.stateUpdate }>
  | (Extract<
      DashboardContextUpdateEvents,
      { type: typeof DASHBOARD_EVENTS.stateUpdateTypeDialog }
    > & { previusPath?: string });
