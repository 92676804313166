import { useMemo } from 'react';

import { isArray } from '@builder/utils';

import { useLayoutNodesIds, useSelectedNodeID } from 'src/providers';

export const useIsSelectedNodeBelongToLayout = (): boolean => {
  const selectedID = useSelectedNodeID();
  const layoutNodesIds = useLayoutNodesIds();

  return useMemo(() => {
    if (isArray(selectedID)) {
      let isSelectedNodeBelongToLayout = false;
      selectedID.forEach(id => {
        const hasLayoutID = layoutNodesIds.some(item => item === id);
        if (hasLayoutID) {
          isSelectedNodeBelongToLayout = true;
        }
      });
      return isSelectedNodeBelongToLayout;
    }

    return layoutNodesIds.some(item => item === selectedID);
  }, [layoutNodesIds, selectedID]);
};
